
export default function FiltersFieldContainer(props) {
  const { children,label,className } = props;

  return (
    <div style={{minWidth:"200px"}} className={className}>
      <label className="custom-label">
        {label}
      </label>
      {children}
    </div>
  );
}
