import React, { useEffect, useState } from 'react';
import './Support.css';
import { useSnackbar } from '../../store/context/SneckBarProvider';
import { useTranslation } from 'react-i18next';
import { useSendMessageMutation } from '../../features/support/supportSlice';
import PageLoader from '../PageLoader/PageLoader';

const Support = () => {
  const showSnackbar = useSnackbar();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState({ message: '', isError: false });
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState({ message: '', isError: false });
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState({
    message: '',
    isError: false,
  });

  const { t, i18n } = useTranslation();
  const [languageState, setLanguageState] = React.useState('srpski');

  const [sendMessage, { isLoading }] = useSendMessageMutation();

  const changeEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value.trim() === '')
      setEmailError({
        message: t('support.emailEmpty'),
        isError: true,
      });
    else setEmailError({ message: '', isError: false });
  };

  const changeName = (e) => {
    setName(e.target.value);
    if (e.target.value.trim() === '')
      setNameError({
        message: t('support.nameEmpty'),
        isError: true,
      });
    else setNameError({ message: '', isError: false });
  };

  const changeMessage = (e) => {
    setMessage(e.target.value);
    if (e.target.value.trim() === '')
      setMessageError({
        message: t('support.messageEmpty'),
        isError: true,
      });
    else setMessageError({ message: '', isError: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(email)) {
        setEmailError({
          message: t('support.validEmail'),
          isError: true,
        });
        return;
      }
      if (name === '' || email === '' || message === '') return;
      await sendMessage({ email, name, message }).unwrap();
      setEmail('');
      setName('');
      setMessage('');
      showSnackbar(t('support.success'), 'success');
    } catch (error) {
      showSnackbar(error?.data?.Message, 'error');
    }
  };

  useEffect(() => {
    document.body.classList.add('login-page');
    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  const hangleChangeLanguage = () => {
    const currentLanguage = i18n.language;
    console.log(currentLanguage);

    if (languageState === 'srpski') {
      setLanguageState('english');
    } else {
      setLanguageState('srpski');
    }

    const newLanguage = i18n.language === 'en' ? 'rs' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const content = isLoading ? (
    <PageLoader />
  ) : (
    <div className="my-container">
      <div className="my-right">
        <div className="my-header-language">
          {' '}
          <span onClick={hangleChangeLanguage}>
            {i18n.language === 'rs' ? 'SRB' : 'ENG'}
          </span>
        </div>
        <div className="my-login-form">
          <img
            src="/assets/terra-logo.jpeg"
            alt="logo"
            width="40"
            height="40"
          />
          <h2>{t('support.supportTitle')}</h2>
          <form className="my-form-style">
            <p style={{ marginBottom: '40px' }}>
              {t('support.supportMessage')}
            </p>

            <label className="my-text-label" htmlFor="username">
              {t('support.email')}:
            </label>
            <input
              className={`my-input-text ${emailError.isError ? 'my-input-error' : ''}`}
              autoComplete="false"
              onChange={(e) => changeEmail(e)}
              value={email}
              placeholder={t('support.enterEmail')}
              type="text"
              id="username"
              name="username"
              required
            />
            {emailError.isError && (
              <div className="my-helper-text">{emailError.message}</div>
            )}
            <label className="my-text-label" htmlFor="name">
              {t('support.name')}:
            </label>
            <input
              className={`my-input-text ${nameError.isError ? 'my-input-error' : ''}`}
              autoComplete="false"
              onChange={(e) => changeName(e)}
              value={name}
              placeholder={t('support.enterName')}
              type="text"
              id="name"
              name="name"
              required
            />
            {nameError.isError && (
              <div className="my-helper-text">{nameError.message}</div>
            )}
            <label className="my-text-label" htmlFor="message">
              {t('support.message')}:
            </label>
            <textarea
              className={`textarea-input ${messageError.isError ? 'my-input-error' : ''} `}
              autoComplete="false"
              onChange={(e) => changeMessage(e)}
              value={message}
              placeholder={t('support.enterMessage')}
              type="text"
              id="message"
              name="message"
              rows={8}
            />
            {messageError.isError && (
              <div className="my-helper-text">{messageError.message}</div>
            )}
            <button
              style={{ marginTop: 40 }}
              className={`my-log-button ${name === '' || email === '' || message === '' ? 'btn-disabled' : ''}`}
              onClick={handleSubmit}
              type="submit"
              disabled={name === '' || email === '' || message === ''}
            >
              {t('support.send')}
            </button>
          </form>
        </div>
        <footer className="my-footer-text">
          <p>www.terratravel.rs</p>
        </footer>
      </div>
    </div>
  );
  return content;
};

export default Support;
