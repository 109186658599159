import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChangePasswordMutation } from '../../../features/auth/authApiSlice';
import FilledButton from '../../Button/FilledButton';
import WhiteButton from '../../Button/WhiteButton';
import PasswordTextField from '../../CustomTextFields/PasswordTextField';
import ConfirmationDialog from '../../Dialog/ConfirmationDialog';
import { useSnackbar } from '../../../store/context/SneckBarProvider';

export default function ChangePasswordForm(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const showSnackbar = useSnackbar();
  const { id } = props;
  const [changePassword] = useChangePasswordMutation();

  const [formData, setFormData] = useState({
    id: id,
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [fieldValidities, setFieldValidities] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [reset,setReset]=useState(false)
  const handleChangePassword = async () => {
    try {
      const result = await changePassword(formData).unwrap();
      showSnackbar(result.message, 'success');
      setOpen(false);

      // onClose();
    } catch (err) {
      setOpen(false);

      showSnackbar(err.data.Message, 'error');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFieldValidityChange = (fieldName, isValid) => {
    setFieldValidities((prevValidities) => ({
      ...prevValidities,
      [fieldName]: isValid,
    }));
    console.log(fieldValidities);
  };
  const handleOpenDialg = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleYesClick = () => {
    handleChangePassword();
  };
  const handleReset=()=>{
    setFormData({
      id: id,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
    setFieldValidities({
      currentPassword: false,
    newPassword: false,
    confirmPassword: false,
    })
    setReset(!reset)
  }
  return (
    <>
    <div style={{width:"100%"}}>
      <div
        className="change-password-main-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          // width: '300px',
        }}
      >
        <PasswordTextField
          name="currentPassword"
          label="Current password"
          onPasswordChange={handleChange}
          onValidityChange={handleFieldValidityChange}
          password={formData.currentPassword}
          reset={reset}
          // comperePasswords={formData.password}
        />
        <PasswordTextField
          name="newPassword"
          label="New password"
          onPasswordChange={handleChange}
          onValidityChange={handleFieldValidityChange}
          password={formData.newPassword}
          // comperePasswords={formData.password}
          reset={reset}

        />
        <PasswordTextField
          name="confirmPassword"
          label="Confirm password"
          onPasswordChange={handleChange}
          onValidityChange={handleFieldValidityChange}
          password={formData.confirmPassword}
          comperePasswords={formData.newPassword}
          reset={reset}

        />
        {/* </div> */}
   
        {/* <ButtonValidate
          onClick={handleOpenDialg}
          fieldValidities={fieldValidities}
        >
          <SaveOutlinedIcon /> {t("main.save")}
        </ButtonValidate> */}
        <ConfirmationDialog
          // sx={{ zIndex: "1000000" }}
          // style={{zIndex:1500}}
          open={open}
          onClose={handleCloseDialog}
          dialogContent={'Are you sure you want to change your password?'}
          onNo={handleCloseDialog}
          onYes={handleYesClick}
        />
      </div>
      <div  style={{width:"100%", display:"flex",gap:"16px",justifyContent:"flex-end",paddingTop:"24px", paddingBottom:"32px"}} className="change-password-buttons-container">
          <WhiteButton onClick={handleReset}>{t("main.cancel")}</WhiteButton>
          <FilledButton
            onClick={handleOpenDialg}
            isValid={
              fieldValidities.confirmPassword &&
              fieldValidities.currentPassword &&
              fieldValidities.newPassword
            }
          >
            {t("main.saveChanges")}
          </FilledButton>
        </div>
        </div>
    </>
  );
}
