import React, { useEffect, useState } from 'react';
import './RegisterForm.css';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRegisterInvitationMutation } from '../../../features/auth/authApiSlice';
import FilledButton from '../../Button/FilledButton';
import NameTextField from '../../CustomTextFields/NameTextField';
import PasswordTextField from '../../CustomTextFields/PasswordTextField';
import PhoneTextField from '../../CustomTextFields/PhoneTextField';
import UserNameTextField from '../../CustomTextFields/UserNameTextField';
import { useSnackbar } from '../../../store/context/SneckBarProvider';
import LoginRegisterContainer from './LoginRegisterContainer';

const RegistrationInvitation = (props) => {

  const { token } = props;
  const [register] = useRegisterInvitationMutation();
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();
  const { t, i18n } = useTranslation();
  const [languageState, setLanguageState] = React.useState('srpski');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    email: token.email,
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    role: token.role,
  });
  const [fieldValidities, setFieldValidities] = useState({
    // firstName: false,
    // lastName: false,
    userName: false,
    password: false,
    confirmPassword: false,
    phoneNumber:true
  });

  const handleChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(formData);
  };


  const handlePhoneNumberChange = (phone) => {
    console.log(phone);
    console.log(formData);
    setFormData((prevData) => ({
      ...prevData,
      phoneNumber: phone, // Update the phone number directly
    }));
  };
  const handleFieldValidityChange = (fieldName, isValid) => {
    setFieldValidities((prevValidities) => ({
      ...prevValidities,
      [fieldName]: isValid,
    }));
    // console.log(fieldValidities)
  };

  const handleAddUser = async () => {
    console.log('Fsdafasdfasdf');
    try {
      let body = {
        token: token.token,
        registrationData: formData,
      };
      const response = await register(body).unwrap();
      showSnackbar(response?.message, 'success');
      navigate('/');
    } catch (err) {
      showSnackbar(err?.data?.Message, 'error');
    }
  };

  
  const hangleChangeLanguage = () => {
    if (languageState === 'srpski') {
      setLanguageState('english');
    } else {
      setLanguageState('srpski');
    }

    const newLanguage = i18n.language === 'en' ? 'rs' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const handlePhoneValidationChange=(isValid)=>{
    if(formData.role==="Driver" && !isValid){
      handleFieldValidityChange("phoneNumber",false)
      return
    }
    handleFieldValidityChange("phoneNumber",true)
    return
  } 

    useEffect(()=>{
    },[fieldValidities.phoneNumber])


  return ( 
    <>
      <LoginRegisterContainer title={t("main.singUp")}>
        {/* <div> */}
        <div className="header-language"> <span  onClick={hangleChangeLanguage}>
          {i18n.language === 'rs' ? 'SRB' : 'ENG'}
        </span>
        </div>
        <NameTextField
          label="First Name"
          name="firstName"
          nameValue={formData.firstName}
          onNameChange={handleChange}
          // onValidityChange={handleFieldValidityChange}
        />
        <NameTextField
          label="Last Name"
          name="lastName"
          nameValue={formData.lastName}
          onNameChange={handleChange}
          // onValidityChange={handleFieldValidityChange}
        />
        <PhoneTextField
          name="phoneNumber"
          value={formData.phoneNumber}
          onPhoneChange={handlePhoneNumberChange}
          useValidationProp={formData.role==="Driver"}
          onValidPhoneNumberChange={handlePhoneValidationChange}

        />
        <div>
          <UserNameTextField
            label="User Name"
            name="userName"
            userName={formData.username}
            onNameChange={handleChange}
            onValidityChange={handleFieldValidityChange}
          />
        </div>

        <div>
          <PasswordTextField
            name="password"
            label={'Password'}
            onPasswordChange={handleChange}
            onValidityChange={handleFieldValidityChange}
            password={formData.password}
          />
        </div>
        <div>
          <PasswordTextField
            name="confirmPassword"
            label={'Confirm password'}
            onPasswordChange={handleChange}
            onValidityChange={handleFieldValidityChange}
            password={formData.confirmPassword}
            comperePasswords={formData.password}
          />
        </div>

        <FilledButton
          onClick={handleAddUser}
          type="greenButton"
          isValid={
            fieldValidities.userName &&
            fieldValidities.password &&
            fieldValidities.confirmPassword &&
            fieldValidities.phoneNumber
          }
        >
          {t(`main.getStarted`)}
        </FilledButton>
        {/* </div> */}
      </LoginRegisterContainer>
    </>
  );
};

export default RegistrationInvitation;
