// import './ConfirmationDialog.css';
import { ClearIcon } from '@mui/x-date-pickers';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import CustomDialog from '../Dialog/CustomDialog';
import ManageDrivers from './ManageDrivers';

export default function ManageDriversDialog(props) {
  const { onClose, open, rideId, drivers,departure } = props;
  const [openComf, setOpenComf] = useState(false);
  const { t } = useTranslation();
  const childRef = useRef(null);
  const handleCheckUnasvedChanges = () => {
    if (childRef.current) {
      var pom = childRef.current.check();
      if (pom) handleCloseDialog();
      else setOpenComf(true);
    }
  };
  const handleCloseDialog = () => {
    handleCloseComfDialog();
    onClose();
  };
  const handleCloseComfDialog = () => {
    setOpenComf(false);
  };

  return (
    <>
      <CustomDialog
        onOpen={open}
        onClose={handleCheckUnasvedChanges}
        customMargin="0px"
      >
        <div className="restrit-date-header-container">
          {t('rides.manageDrivers')}
          <ClearIcon style={{ cursor: 'pointer' }} onClick={onClose} />
        </div>
        <ManageDrivers
          onClose={handleCloseDialog}
          ref={childRef}
          drivers={drivers}
          rideId={rideId}
          departure={departure}
        />
      </CustomDialog>
      {openComf && (
        <ConfirmationDialog
          open={openComf}
          onClose={handleCloseComfDialog}
          onNo={handleCloseComfDialog}
          onYes={handleCloseDialog}
          dialogContent={'closeUnsavedChanges'}
        />
      )}
    </>
  );
}
