import React, { useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SearchableSelect = ({ items, setSelected }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const { t } = useTranslation();

  const handleSelectChange = (event, newValue) => {
    setSelectedItem(newValue);
    setSelected(newValue);
  };

  return (
    <Autocomplete
      options={items}
      getOptionLabel={(option) => option.registrationPlates}
      value={selectedItem}
      onChange={handleSelectChange}
      renderInput={(params) => (
        <TextField {...params} label={t('customDialog.searchVehicle')} />
      )}
    />
  );
};

export default SearchableSelect;
