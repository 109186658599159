import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useGetReservationByIdQuery,
} from '../../../features/reservation/reservationSlice';
import PageLoader from '../../PageLoader/PageLoader';
import CreateBookingComponent from '../AddBooking/CreateBookingComponent';

const EditBookingComponent = () => {
  //const { lang, reservationId, passengerId } = useParams();
  const { lang, reservationId } = useParams();

  const { t, i18n } = useTranslation();
  //const { data, isLoading, error } = useGetSpecificPassengerReservationByIdQuery({ reservationId, passengerId });

  const { data, isLoading, error } = useGetReservationByIdQuery({
    id: reservationId,
  });

  const [reservationData, setReservationData] = useState(null);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  useEffect(() => {
    if (!isLoading && data) {
      setReservationData(data);
      console.log('RESERVATION FOR EDT', data);
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <PageLoader />;
  }

  // if (!isLoading && !reservationData) {
  //     return <p>Error: Unable to fetch reservation data</p>;
  // }

  return (
    <>
      {/* {console.log(data)} */}
      {reservationData && (
        <CreateBookingComponent
          openForEdit={true}
          reservationForEdit={data}
        ></CreateBookingComponent>
      )}
    </>
  );
};
export default EditBookingComponent;
