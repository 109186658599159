import { forwardRef, useImperativeHandle, useState } from 'react';
import ConfirmationDialog from './ConfirmationDialog';
import './ConfirmationDialog.css';
import WarningDialog from './WarningDialog';

const ActionDialog=forwardRef((props,ref) => {
  const { warrningDialogContent, confirmationDialogContent, condition, onYes } =
    props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openDialog: handleOpen,
    closeDialog: handleClose,

  }));


  return (
    <>
      {open && (
        <>
          {condition ? (
            <WarningDialog
              open={open}
              onClose={handleClose}
              onNo={handleClose}
              onYes={onYes}
              dialogContent={warrningDialogContent}
            />
          ) : (
            <ConfirmationDialog
              open={open}
              onClose={handleClose}
              onNo={handleClose}
              onYes={onYes}
              dialogContent={confirmationDialogContent}
            />
          )}
        </>
      )}
    </>
  );
})

export default ActionDialog;
