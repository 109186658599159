import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { newTextFieldStyle } from '../../customStyles/customStyles';
import { useAddCustomLocationMutation } from '../../features/customlocations/customLocationSlice';
import { checkGeocodedAddres, geocodeAddress } from '../../utils/googleMapsHelper/geocode';
import FilledButton from '../Button/FilledButton';
import WhiteButton from '../Button/WhiteButton';
import GoogleMapComponent from '../GoogleMap/GoogleMap';
import InputAutoComplete from '../GoogleMap/InputAutocomplete';
import { useSnackbar } from '../../store/context/SneckBarProvider';
import './AddCustomLocation.css';


export const CustomLocationsEditor = (props) => {
  const {
    onUpdateCustomLocationTable,
    customLocation,
    onLocationChange,
    onNameChange,
    editMarkerPosition,
    onResetClcik,
    onSubmitClick,
    onClose
  } = props;

  const [resetChange, setResetChange] = useState(true);

  const [map, setMap] = useState(null);
  const [geocoder, setGeocoder] = useState(null);
  const [open, setOpen] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [markerReset, setMarkerRest] = useState(true);
  const [locationFromMapClick, setMapClickLocation] = useState(null);

  const showSnackbar = useSnackbar();
  const { t } = useTranslation();
  const [addCustomLocation] = useAddCustomLocationMutation();
  const [isValid, setValid] = useState(false);

  console.log(markerPosition);

  useEffect(() => {
    if (map) {
      setGeocoder(new window.google.maps.Geocoder());
    }
  }, [map]);

  const onLeftClick = () => {
    console.log(map);
  };

  // const handleClcik=()=>{
  //   onSubmitClcik();
  // }
  const handleRightClick = (cords) => {
    console.log(cords);
    setMarkerPosition({ lat: cords.latLng.lat(), lng: cords.latLng.lng() });
    // resetAutocomplete();
    geocodeAddress(cords.latLng, geocoder)
      .then((data) => {
        handleCheckGeocodedAddres(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCheckGeocodedAddres = (location) => {
    console.log(location);
    const checkedAdr = checkGeocodedAddres(location);
    if (checkedAdr === true) {
      setMapClickLocation(location);
      locationChange(location);
    } else {
      showSnackbar(checkedAdr, 'error');

      resetLocation();
    }
  };

  const handleNewLocation = (location) => {
    if (location != null) {
      console.log(location);
      locationChange(location);
      setMarkerPosition({ lat: location.lat, lng: location.long });
    }
  };

  const locationChange = (newLocation) => {
    console.log(newLocation);
    onLocationChange(newLocation);
  };

  const handleLocationNameChange = (event) => {
    onNameChange(event);
  };

  const checkIsDataValid = () => {
    if (
      customLocation.locationName != '' &&
      Object.values(customLocation.location).every((value) => value !== '')
    ) {
      setValid(true);
    } else {
      if (isValid == true) {
        setValid(false);
      }
    }
  };

  useEffect(() => {
    console.log(customLocation);
    checkIsDataValid();
  }, [customLocation]);

  const resetAutocomplete = () => {
    setResetChange(!resetChange);
  };
  const handleMarkerReset = () => {
    setMarkerRest(!markerReset);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetLocation = () => {
    handleMarkerReset();
    setMarkerPosition(null);
    resetAutocomplete();
    onResetClcik();
  };

  return (
    <>
      <div className="custom-location-top-container">
        <span className="custom-location-header-container">
          {editMarkerPosition
            ? // Content to render when editMarkerPosition is true
              t('custom-location.editCustomLocation')
            : // Content to render when editMarkerPosition is false
              t('custom-location.Add Custom Location')}
        </span>
        <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
        
      </div>
      <div className="add-custom-location-main-container">
        {/* <div> */}

        <div className="add-custom-form-buuton-container">
          <div className="add-custom-location-fields-container">
            <div>
              <InputAutoComplete
                labelText={t('custom-location.Unesi')}
                // name="endCityName"
                // namee="endCountryName"

                label="lokacija"
                placeholder={t('route-filters.nprBgd')}
                reesetChange={resetChange}
                onLocationChange={handleNewLocation}
                useForFiltering={true}
                customStyle={newTextFieldStyle}
                locationForUpdate={customLocation.location}
                fillAutoCompleteFromMap={locationFromMapClick}
                // customStyle={textFieldStyles}
              />
            </div>
            <div>
              <label className="custom-label">
                {t('custom-location.locationName')}
              </label>
              <input
                value={customLocation.locationName || ''}
                autoComplete="false"
                style={{ ...newTextFieldStyle }}
                name="locationName"
                label={t('route-filters.routeTitle')}
                placeholder={t('custom-location.zmajPumpa')}
                type="text"
                onChange={handleLocationNameChange}
              />
            </div>
          </div>
          <div className="add-custom-locations-button-container">
            <WhiteButton onClick={resetLocation}>
              {t('reservationsFilters.filterButtonReset')}
            </WhiteButton>
            <FilledButton isValid={isValid} onClick={onSubmitClick}>
              {t('main.save')}
            </FilledButton>
          </div>
        </div>
        <div className="add-custom-location-map-cotaniner">
          <GoogleMapComponent
            onLeftClick={onLeftClick}
            onRightClick={handleRightClick}
            setMap={setMap}
            showMarker={true}
            autoCompleteMarker={markerPosition}
            markerReset={markerReset}
            editMarker={editMarkerPosition}
          />
        </div>
      </div>

      {/* </div> */}
    </>
  );
};
