// import './ConfirmationDialog.css';
import { useTranslation } from 'react-i18next';

import CustomDialog from '../Dialog/CustomDialog';

import { ClearIcon } from '@mui/x-date-pickers';
import ChangeVehicle from './ChangeVehicle';
import "./style/Rides.css";

export default function ChangeVehicleDialog(props) {
  const { onClose, open, rideId, rideDirection,routeId,departure } = props;
  const { t } = useTranslation();

  return (
    <>
      <CustomDialog
        onOpen={open}
        onClose={onClose}
        customMargin="0px"
      >
        <div className="restrit-date-header-container">
          {t('rides.changeVehicle')}
          <ClearIcon style={{ cursor: 'pointer' }} onClick={onClose} />
        </div>
        <ChangeVehicle 
            rideId={rideId}
            rideDirection={rideDirection}
            routeId={routeId}
            departure={departure}
            onClose={onClose}
        />
       
      </CustomDialog>
    </>
  );
}
