import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import LoginPage from './pages/LoginPage';
import RoutesPage from './pages/RoutesPage';
// import RequireAuth from "../src/features/auth/RequireAuth";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Suspense, useState } from 'react';
import Layout from '../src/components/Layout';
import CreateBookingComponent from './components/Booking/AddBooking/CreateBookingComponent.js';
import BookingDetails from './components/Booking/BookingDetails/BookingDetails.js';
import EditBookingComponent from './components/Booking/EditBooking/EditBookingComponent.js';
import { CustomLocations } from './components/CustomLocations/CustomLocations.jsx';
import InputAutoComplete from './components/GoogleMap/InputAutocomplete.jsx';
import TextAreaWithAutocomplete from './components/GoogleMap/TextAreaWithAutoComplete.jsx';
import PassangersDetails from './components/PassangersDetails/PassangersDetails.js';
import { SnackbarProvider } from './store/context/SneckBarProvider.jsx';
import UserProfile from './components/User/UserProfile/UserProfile.jsx';
import ShowAllUsersComponent from './components/User/UsersTable/ShowAllUsersComponent.js';
import RequireAuth from './features/auth/ReguireAuth.js';
import Unauthorized from './features/auth/Unauthorized.js';
import AddCustomLocationPage from './pages/AddCustomLocationPage.js';
import AllReservationsPage from './pages/AllReservationsPage.js';
import DeleteConfirmPage from './pages/DeleteConfirmPage.js';
import DeleteProfilePage from './pages/DeleteProfilePage.js';
import Eror404 from './pages/Eroro404.js';
import ResetPassword from './pages/ForgetPassword.js';
import PassengerRegistrationConfirmPage from './pages/PassengerRegistrationConfirmPage.js';
import PaymentMethodPage from './pages/PaymentMethod.js';
import RegisterInvitationPage from './pages/RegisterInvitationPage.js';
import ResetForgottenPasswordSuccess from './pages/ResetForgottenPasswordSuccess.js';
import RidesPage from './pages/RidesPage.js';
import SMSStatusPage from './pages/SMSStautsPage.js';
import SubagentPage from './pages/SubagentsPage.js';
import SupportPage from './pages/SupportPage.js';
import ThankYouPage from './pages/ThankYouPage.js';
import { SignalRProvider } from './store/context/SignalRContext.js';
import { RecordsProvider } from './store/context/SMSRecordContext.js';
import { ValidationProvider } from './store/context/ValidationProvider.js';

function App() {
  const [key, setKey] = useState(0);

  const remountComponent = () => {
    setKey((prevKey) => prevKey + 1);
  };
  return (
    <RecordsProvider>
      <SnackbarProvider>
        <SignalRProvider>
          <Suspense fallback="...loading">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ValidationProvider>
                <Router>
                  <Routes>
                    <Route path="/" element={<Layout />}>
                      {/* Public routes */}

                      <Route index element={<LoginPage />} />
                      <Route
                        path="/delete-intent"
                        element={<DeleteProfilePage />}
                      />
                      <Route
                        path="/delete-confirm"
                        element={<DeleteConfirmPage />}
                      />
                      <Route path="/support" element={<SupportPage />} />
                      <Route
                        path="/InputAuto"
                        element={<InputAutoComplete />}
                      />

                      <Route path="/unauthorized" element={<Unauthorized />} />
                      <Route
                        path="/RegisterInvitation"
                        element={<RegisterInvitationPage />}
                      />
                      <Route
                        path="/ConfirmRegistration"
                        element={<PassengerRegistrationConfirmPage />}
                      />

                      <Route
                        path="/ReservationConfirmation/:paramName"
                        element={<ThankYouPage />}
                      />
                      <Route path="/SMSStatus" element={<SMSStatusPage />} />

                      <Route
                        path="/account/delete-confirm/:paramName"
                        element={<DeleteProfilePage />}
                      />

                      {/* Protected routes */}

                      <Route
                        element={
                          <RequireAuth
                            allowedRoles={['Admin', 'Operater', 'Subagent']}
                          />
                        }
                      >
                        <Route path="/Rides" element={<RidesPage />} />

                        <Route
                          path="/CreateBooking"
                          element={
                            <CreateBookingComponent
                              key={key}
                              onRemount={remountComponent}
                            />
                          }
                        />


                        <Route
                          path="/Reservations"
                          element={<AllReservationsPage />}
                        />
                      </Route>

                      <Route
                        element={
                          <RequireAuth
                            allowedRoles={['Admin', 'Operater', 'Subagent']}
                          />
                        }
                      >
                        <Route
                          path="/BookingDetails/:reservationId"
                          element={<PassangersDetails />}
                        />
                        <Route
                          path="/ReservationDetails/:reservationId/:routeId"
                          element={<BookingDetails />}
                        />


                        <Route
                          path="/UserProfile/:userId"
                          element={<UserProfile />}
                        />

                        <Route
                          path="/:lang/EditBooking/:reservationId/"
                          element={<EditBookingComponent />}
                        ></Route>
                      </Route>

                      <Route element={<RequireAuth allowedRoles={['Admin']} />}>
                        {/* <Route path="/AddRoute" element={<AddRouteByAutoCompleteForm/>}/> */}
                        <Route
                          path="/AddRoute"
                          element={<TextAreaWithAutocomplete />}
                        />
                        <Route path="/Subagents" element={<SubagentPage />} />
                        <Route
                          path="/CustomLocations"
                          element={<CustomLocations />}
                        />
                        <Route
                          path="/AddCustomLocation"
                          element={<AddCustomLocationPage />}
                        ></Route>
                        <Route path="/RoutesPage" element={<RoutesPage />} />
                        <Route
                          path="/Reservations"
                          element={<AllReservationsPage />}
                        />
                        <Route
                          path="/Users"
                          element={<ShowAllUsersComponent />}
                        />

                        <Route
                          path="/PaymentMethods"
                          element={<PaymentMethodPage />}
                        />
                      </Route>
                      <Route path="*" element={<Eror404 />} />
                    </Route>

                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route
                      path="/reset-password-success"
                      element={<ResetForgottenPasswordSuccess />}
                    />
                  </Routes>
                </Router>
              </ValidationProvider>
            </LocalizationProvider>
          </Suspense>
        </SignalRProvider>
      </SnackbarProvider>
    </RecordsProvider>
  );
}

export default App;
