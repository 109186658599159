import { Box, Button, TextField } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateSubagentMutation } from '../../../features/subagents/SubagentSlice';
import './Subagent.css';

export default function CreateSubagentForm({ onSuccess, getSubagents }) {
    const [subagentName, setSubagentName] = useState('');
    const [errors, setErrors] = useState({});
    const { t } = useTranslation();
    const [createSubagent, { isLoading, isError, error }] =
        useCreateSubagentMutation();

    const handleInputChange = (event) => {
        setSubagentName(event.target.value);
    };

    const validate = () => {
        const errors = {};
        if(!subagentName.trim()){
            errors.subagentName = t('subagent.requiredSubagent');
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validate()) return;
        try {
            const newSubagent = { title: subagentName };
            await createSubagent(newSubagent).unwrap();
            if (onSuccess) {
                onSuccess();
            }
            if (getSubagents) {
                getSubagents();
            }
            
        } catch (err) {
            console.error('Failed to create subagent: ', err);
        }
        setSubagentName('');
    };

    return (
        <Box component="form" onSubmit={handleSubmit}>
            <TextField
                id="subagent-name"
                label={t('subagent.name')}
                variant="outlined"
                value={subagentName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={!!errors.subagentName}
                helperText={errors.subagentName}
            />
            <Button type="submit" color="primary" className='create-button'>
                {isLoading
                    ? t('subagent.creating')
                    : t('subagent.createSubagent')}
            </Button>
            {isError && (
                <p>
                    {t('subagent.errorCreating')}: {error.toString()}
                </p>
            )}
         
        </Box>
    );
}
