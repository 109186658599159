import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
// import "react-phone-input-2/lib/style.css";

import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/material.css';
import { useValidation } from '../../store/context/ValidationProvider';
import ValidationErrors from '../Error/ValidationErrors';

const PhoneTextField = (props) => {
  const {
    name,
    value,
    onPhoneChange,
    useValidationProp,
    onValidPhoneNumberChange,
  } = props;
  const { t } = useTranslation();
  const { validePhoneNumberField } = useValidation();
  const [usePhoneNUmberErrors, setPhoneNumberErrors] = useState(null);

  const onPhoneNumberChange = (phonenumber) => {
    console.log("+"+phonenumber);
    if (useValidationProp == true) {
      const validation = validePhoneNumberField(phonenumber);
      setPhoneNumberErrors(validation.errors);
      onValidPhoneNumberChange(validation.isValid);
    }
    onPhoneChange("+"+phonenumber);
  };

  const checkValidation = () => {
    const validation = validePhoneNumberField(value);
    setPhoneNumberErrors(validation.errors);
    onValidPhoneNumberChange(validation.isValid);
  };

  useEffect(() => {
    if (useValidationProp == false && onValidPhoneNumberChange) {
      setPhoneNumberErrors(null);
      onValidPhoneNumberChange(true);
    } else {
      if (onValidPhoneNumberChange) checkValidation();
    }
  }, [useValidationProp]);

  return (
    <div>
      <label className="custom-label">{t('main.phone')}</label>
      <PhoneInput
        enableSearch={true}
        country={'rs'}
        name={name}
        value={value}
        className="phone-s"
        // specialLabel={t("main.phone")}
        onChange={onPhoneNumberChange}
        inputStyle={{
          width: '100%',
          // borderColor: "#D0D5DD",
          color: '#667085',
        }}
      />
      {useValidationProp && (
        <div style={{ position: 'absolute', lineHeight: '0.5rem' }}>
          <ValidationErrors errors={usePhoneNUmberErrors} />
        </div>
      )}
    </div>
  );
};

export default PhoneTextField;
