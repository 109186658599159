import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import PendingIcon from '@mui/icons-material/Pending';
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../../../customStyles/globalDesignStyles.css';
import {
  useCreateReservationPdfExportMutation
} from '../../../features/export/exportApiSlice';
import {
  useChangeReservationStatusMutation,
  useGetReservationsQuery
} from '../../../features/reservation/reservationSlice';
import { useGetUserByIdQuery } from '../../../features/users/UsersApiSlice';
import { ReactComponent as ExportIcon } from '../../../Icons/exportIcon.svg';
import {
  translateUnammedNumber,
  translateUnammedStreet,
} from '../../../utils/translateUnammedLocation';
import SMSButton from '../../Button/SMSButton';
import './AllReservationsComponent.css';
import ReservationsFilters from './ReservationsFilters';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from '@mui/material/IconButton';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment/moment';
import { ReactComponent as EditIcon } from '../../../Icons/edit-01.svg';
import FilledButton from '../../Button/FilledButton';
import CustomDialog from '../../Dialog/CustomDialog';
import PageLoader from '../../PageLoader/PageLoader';
import { useSnackbar } from '../../../store/context/SneckBarProvider';
import AddVehicleForm from './AddVehicleForm';
import EditDepartueTime from './EditDepartureTime';

const AllReservationsComponent = () => {
  const [isCheckAllChecked, setIsCheckAllChecked] = useState(false);
  const [isFiltersClear, setIsFiltersClear] = useState(true);
  const [checkedReservations, setCheckedReservations] = useState(new Set());
  const [checkedReservationsList, setCheckedReservationsList] = useState([]);
  const [rideProperties, setRideProperties] = useState({});

  const [
    isCheckAllVisibleReservationUnavailable,
    setIsCheckAllVisibleReservationUnavailable,
  ] = useState(true);

  const [changeReservationStatus] = useChangeReservationStatusMutation();
  const { t, i18n } = useTranslation();
  const [expandedRows, setExpandedRows] = useState([]);
  const [addOpen, setAddOpen] = useState(false);
  const [openEditTimeDialog, setOpenEditTimeDialog] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [timeType, setTimeType] = useState(false);

  const [downloadPdfFile] = useCreateReservationPdfExportMutation();
  const randomColors = ['rgba(254, 240, 199, 0.6)', 'rgba(92, 118, 112, 0.6)'];
  const role = localStorage.getItem('roles');
  const id = localStorage.getItem('id');
  const { data: userForId } = useGetUserByIdQuery(id);
  const showSnackbar = useSnackbar();
  const [hideTooltip, setHideTooltip] = useState(true);
  const [sortOrder, setSortOrder] = useState('asc');
  const [isAddVehicleButtonDisabled, setIsAddVehicleButtonDisabled] =
    useState(true);

  const handleAddOpen = () => {
    setAddOpen(true);
  };
  const handleAddClose = () => {
    setAddOpen(false);
  };

  const headers = [
    t('excel.num'),
    t('excel.fullName'),
    t('excel.phone'),
    t('excel.address'),
    t('excel.city'),
    t('excel.departureTime'),
    t('excel.arrival'),
    t('excel.arrivalCity'),
    t('excel.arrivalTime'),
    t('excel.note'),
    t('excel.payment'),
    t('excel.subagent'),
    t('excel.status'),
  ];

  const extractReservationData = (reservations) => {
    return reservations.flatMap((reservation) => {
      if (!reservation.routeId || reservation.passengers.length < 1) {
        return [];
      }

      return reservation.passengers.map((passenger) => ({
        [t('excel.num')]: passenger.reservationId,
        [t('excel.fullName')]: `${passenger.name} ${passenger.lastName || ''}`,
        [t('excel.phone')]: passenger.phoneNumber || '/',
        [t('excel.address')]: showStartAddress(reservation, true),
        [t('excel.city')]: showStartLocation(reservation, true),
        [t('excel.departureTime')]: showTime(reservation, true),
        [t('excel.arrival')]: showEndAddress(reservation, true),
        [t('excel.arrivalCity')]: showEndLocation(reservation, true),
        [t('excel.arrivalTime')]: showArrivalTime(reservation, true),
        [t('excel.note')]: showNote(reservation, true),
        [t('excel.payment')]: `${reservation.isReturnTicket ? 'RT' : 'OW'} ${showPaymentMethod(reservation, true) == undefined ? '' : showPaymentMethod(reservation, true)} `,
        [t('excel.subagent')]: reservation.subagentTitle || '',
        [t('excel.status')]: getPassengerStatus(reservation, passenger, true, i18n.language)
      })).filter(Boolean);
    }).filter(Boolean);
  };

  const exportToExcel = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Reservations');

    // Add header row
    const headerRow = worksheet.addRow(headers);

    // Add filters (for sorting arrows)
    worksheet.autoFilter = {
      from: { row: 1, column: 1 },
      to: { row: 1, column: headers.length },
    };

    // Style the headers
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4F81BD' },
      };
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    data.forEach((passenger) => {
      const row = worksheet.addRow(Object.values(passenger));

      let color;
      const status = passenger[t('excel.status')]; // Use the key for status

      if (status == 'Plaćeno' || status == 'Paid') {
        color = 'FF88E788';
      } else if (status == 'Otkazano' || status == 'Cancelled') {
        color = 'FFFF5B61';
      } else {
        color = 'FFFFFFFF';
      }

      row.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color },
        };
      });
    });

    worksheet.columns.forEach((column) => {
      column.width = 20; // Set default column width
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const fileName =
      filters.date != null
        ? `Reservations_${dayjs(filters.date).format('DD-MM-YYYY')}.xlsx`
        : 'Reservations.xlsx';

    saveAs(blob, fileName);
  };
  const handleOnExcelDownload = () => {
    const reservationsData = extractReservationData(sortedReservations);
    exportToExcel(reservationsData);
  };

  const navigate = useNavigate();
  const initialStateFilters = {
    date: null,
    bookingId: undefined,
    routeId: undefined,
    //direction: 'all',
    direction: '',
    isReturnTicket: undefined,
    //isNightLine: undefined,
    nightLine: false,
    createdFrom: null,
    createdTo: null,
    creator: undefined,
    passengerName:
      role == 'Subagent'
        ? userForId?.firstName + ' ' + userForId?.lastName
        : '',
    passengerPhoneNumber: undefined,
    reservationId: '',
    reservationType: 'all',
    pageSize: 25,
    pageNumber: 1,
  };
  const [filters, setFilters] = useState(initialStateFilters);

  const { data, isLoading, refetch, isFetching } =
    useGetReservationsQuery(filters);

  const totalPassengersReservations = data
    ? data?.totalPassengersReservations
    : 0;

  const uncheckCheckbox = () => {
    setIsCheckAllChecked(false);
  };

  const handleFilterReservation = (newFilters) => {
    setFilters(newFilters);
    if (
      newFilters.direction === initialStateFilters.direction ||
      newFilters.date === initialStateFilters.date ||
      newFilters.routeId === initialStateFilters.routeId
    ) {
      setIsAddVehicleButtonDisabled(true);
    } else {
      setIsAddVehicleButtonDisabled(false);
    }

    const filtersAreDifferent = areFiltersDifferent(
      newFilters,
      initialStateFilters
    );

    if (filtersAreDifferent) {
      uncheckCheckbox();
      setCheckedReservations(new Set());
      setCheckedReservationsList([]);
    } else {
      uncheckCheckbox();
      setCheckedReservations(new Set());
      setCheckedReservationsList([]);
      console.log('filters are RESETED');
    }
  };

  const handleSortClick = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const handleIdClick = (reservationId) => {
    const languagePrefix = i18n.language === 'en' ? '/en' : '/rs';
    const url = `${languagePrefix}/EditBooking/${reservationId}`;
    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      navigate(url);
    }
  };

  const handleChangeRowsPerPage = () => {
    setFilters({
      ...filters,
      pageSize: filters.pageSize + 25,
    });
  };

  const openEditTimeDialogHandler = (reservation, type) => {
    setOpenEditTimeDialog(true);
    setSelectedReservation(reservation);
    setTimeType(type);
  };

  const closeEditTimeDialog = () => {
    setOpenEditTimeDialog(false);
    setSelectedReservation(null);
  };

  const determineDataForColumn = (start, end, reservation, filters) => {
    let isReturnDepartureTime = isSwapedDirection(reservation);

    let location;

    if (
      filters.nightLine != false &&
      JSON.stringify({
        ...filters,
        pageSize: undefined,
        nightLine: undefined,
      }) ==
        JSON.stringify({
          ...initialStateFilters,
          pageSize: undefined,
          nightLine: undefined,
        })
    ) {
      location = reservation.booking?.isEveningDeparture == true ? start : end;
    } else if (
      (filters.date != null &&
        JSON.stringify({ ...filters, date: undefined, pageSize: undefined }) ==
          JSON.stringify({
            ...initialStateFilters,
            date: undefined,
            pageSize: undefined,
          })) ||
      (filters.routeId != undefined &&
        JSON.stringify({
          ...filters,
          routeId: undefined,
          pageSize: undefined,
        }) ===
          JSON.stringify({
            ...initialStateFilters,
            routeId: undefined,
            pageSize: undefined,
          }))
    ) {
      location = start;
    } else if (
      filters.date != null &&
      JSON.stringify({
        ...filters,
        date: undefined,
        nightLine: undefined,
        pageSize: undefined,
      }) ==
        JSON.stringify({
          ...initialStateFilters,
          date: undefined,
          nightLine: undefined,
          pageSize: undefined,
        }) &&
      filters.nightLine != undefined &&
      JSON.stringify({
        ...filters,
        date: undefined,
        nightLine: undefined,
        pageSize: undefined,
      }) ===
        JSON.stringify({
          ...initialStateFilters,
          date: undefined,
          nightLine: undefined,
          pageSize: undefined,
        })
    ) {
      if (
        reservation?.booking?.isEveningDeparture === true &&
        dayjs(reservation?.booking?.departure).isSame(
          dayjs(filters.date),
          'day'
        )
      ) {
        location = start;
      } else if (
        reservation?.bookingReturn?.isEveningDeparture === true &&
        dayjs(reservation?.bookingReturn?.departure).isSame(
          dayjs(filters.date),
          'day'
        )
      ) {
        location = end;
      }
    } else if (filters.nightLine === true) {
      if (
        reservation.booking?.isEveningDeparture === true &&
        reservation.bookingReturn?.isEveningDeparture === true
      ) {
        location =
          (reservation.booking.direction === true ||
            reservation.booking.direction === false) &&
          filters.direction == 'departure'
            ? reservation.booking?.direction == false
              ? end
              : start
            : reservation.booking?.direction == false
              ? start
              : filters.createdFrom == null || filters.createdTo == null
                ? end
                : start;
      } else if (reservation.bookingReturn?.isEveningDeparture === true) {
        location = end;
      } else if (reservation.booking?.isEveningDeparture === true) {
        location = start;
      } else {
        location = isReturnDepartureTime ? end : start;
      }
    } else {
      if (
        (reservation?.startStopReturn &&
          reservation.bookingReturn?.direction === true &&
          filters.direction === 'departure') ||
        (reservation?.startStopReturn &&
          reservation.bookingReturn?.direction === false &&
          filters.direction === 'return') ||
        // (reservation?.isReturnTicket && reservation.bookingReturn?.direction === false && reservation.booking?.direction === true && isSameDate(reservation.bookingReturn?.departure, filters?.date)) ||
        // (reservation?.isReturnTicket && reservation.booking?.direction === false && reservation.bookingReturn?.direction === true && isSameDate(reservation.bookingReturn?.departure, filters?.date)) ||
        ((filters.createdFrom || filters.createdTo) &&
          reservation.booking?.isEveningDeparture === true) ||
        reservation.booking?.isEveningDeparture === true
      ) {
        location = end;
      } else {
        location = start;
      }
    }

    return location;
  };

  const getPassengerStatus = (reservation, passenger, isForExport, lang) => {
    const start = passenger.reservationStatus;
    const end = passenger.returnReservationStatus;

    // Call the utility function to get the appropriate location
    let status = determineDataForColumn(start, end, reservation, filters);

    if (isForExport && lang === 'rs') {
      switch (status) {
        case 'Paid':
          status = 'Plaćeno';
          break;
        case 'Cancelled':
          status = 'Otkazano';
          break;
        case 'Pending':
          status = 'Čekanje';
          break;
        case 'SMSConfirmed':
          status = 'Potvrđeno SMS-om';
          break;
        default:
          break;
      }
    }

    return isForExport ? (
      status
    ) : (
      <span style={{ alignItems: 'center' }}>{status}</span>
    );
  };

  const showTime = (reservation, isForExport) => {
    let isReturnDepartureTime = isSwapedDirection(reservation);

    const startType = reservation?.booking?.departureTime;
    const endType = reservation?.bookingReturn?.departureTime;

    const time = determineDataForColumn(
      startType,
      endType,
      reservation,
      filters
    );

    let formattedTime = moment(time, 'HH:mm:ss').format('HH:mm');

    const icon = (
      <Button
        className="icon"
        onClick={openEditTimeDialogHandler.bind(
          this,
          reservation,
          isReturnDepartureTime
        )}
        disabled={isPastReservation(reservation, isReturnDepartureTime)}
      >
        <EditIcon style={{ cursor: 'pointer' }} />
      </Button>
    );

    if (formattedTime === 'Invalid date')
      formattedTime = t('reservations.undefined');

    return isForExport ? (
      formattedTime
    ) : (
      <span style={{ alignItems: 'center' }}>
        {formattedTime} <br /> {icon}
      </span>
    );
  };

  const showArrivalTime = (reservation, isForExport) => {
    let isReturnDepartureTime = isSwapedDirection(reservation);

    const startType = reservation?.booking?.arrivalTime;
    const endType = reservation?.bookingReturn?.arrivalTime;

    const time = determineDataForColumn(
      startType,
      endType,
      reservation,
      filters
    );

    let formattedTime = moment(time, 'HH:mm:ss').format('HH:mm');

    const icon = (
      <Button
        className="icon"
        onClick={openEditTimeDialogHandler.bind(
          this,
          reservation,
          isReturnDepartureTime
        )}
        disabled={isPastReservation(reservation, isReturnDepartureTime)}
      >
        <EditIcon style={{ cursor: 'pointer' }} />
      </Button>
    );

    if (formattedTime === 'Invalid date')
      formattedTime = t('reservations.undefined');

    return isForExport ? (
      formattedTime
    ) : (
      <span style={{ alignItems: 'center' }}>
        {formattedTime} <br /> {icon}
      </span>
    );
  };

  const isSwapedDirection = (reservation) => {
    // let sameDate = false;

    return (
      (reservation?.startStopReturn &&
        reservation.bookingReturn.direction === true &&
        filters.direction === 'departure') || // Departure filter
      (reservation?.startStopReturn &&
        reservation?.bookingReturn.direction === false &&
        filters.direction === 'return') || // Return filter
      (reservation?.isReturnTicket &&
        false && //ne znam zasto mora ovamo da stoji ovako
        filters.direction === 'return') // Swapped direction only if it's a return trip
    );
  };

  const showStartLocation = (reservation, isForExport) => {
    const start = reservation?.startStop?.location?.city;
    const end = reservation?.startStopReturn?.location?.city;

    const location = determineDataForColumn(start, end, reservation, filters);

    return isForExport ? (
      location
    ) : (
      <span style={{ alignItems: 'center' }}>{location}</span>
    );
  };

  const showEndLocation = (reservation, isForExport) => {
    const start = reservation?.endStop?.location?.city;
    const end = reservation?.endStopReturn?.location?.city;

    const location = determineDataForColumn(start, end, reservation, filters);

    return isForExport ? (
      location
    ) : (
      <span style={{ alignItems: 'center' }}>{location}</span>
    );
  };

  const showStartAddress = (reservation, isForExport) => {
    const start = getLocationDisplay(
      reservation?.startStop,
      reservation?.startLocationTitle
    );
    const end = getLocationDisplay(
      reservation?.startStopReturn,
      reservation?.startLocationReturnTitle
    );

    const location = determineDataForColumn(start, end, reservation, filters);

    return isForExport ? (
      location
    ) : (
      <span style={{ alignItems: 'center' }}>{location}</span>
    );
  };

  const showEndAddress = (reservation, isForExport) => {
    const start = getLocationDisplay(
      reservation?.endStop,
      reservation?.endLocationTitle
    );
    const end = getLocationDisplay(
      reservation?.endStopReturn,
      reservation?.endLocationReturnTitle
    );

    const location = determineDataForColumn(start, end, reservation, filters);

    return isForExport ? (
      location
    ) : (
      <span style={{ alignItems: 'center' }}>{location}</span>
    );
  };

  const isPastReservation = (reservation, isReturnDepartureTime) => {
    if (
      isReturnDepartureTime &&
      dayjs(reservation.bookingReturn.departure)
        .startOf('day')
        .isBefore(dayjs().startOf('day'))
    )
      return true;
    else if (
      !isReturnDepartureTime &&
      dayjs(reservation.booking.departure)
        .startOf('day')
        .isBefore(dayjs().startOf('day'))
    )
      return true;
    else return false;
  };

  const getDate = (date) => {
    var isUndefined = new Date(date).toLocaleDateString() === "1/1/1"
    if (isUndefined) {
      return t("reservations.undefined");
    }
    else {
      return dayjs(date).format('DD/MM/YYYY');
    }
  };

  const showDate = (reservation, isForExport) => {
    // const start = dayjs(reservation.booking?.departure).format('DD/MM/YYYY');
    // const end = dayjs(reservation.bookingReturn?.departure).format('DD/MM/YYYY');
    const start = getDate(reservation.booking?.departure);
    const end = getDate(reservation.bookingReturn?.departure);

    // Call the utility function to get the appropriate location
    const location = determineDataForColumn(start, end, reservation, filters);

    return isForExport ? (
      location
    ) : (
      <span style={{ alignItems: 'center' }}>{location}</span>
    );
  };

  const showPaymentMethod = (reservation, isForExport) => {
    const start = reservation.paymentMethod?.title
    const end = reservation.paymentMethodReturn?.title

    const payment = determineDataForColumn(start, end, reservation, filters);

    return isForExport ? (
      payment
    ) : (
      <span style={{ alignItems: 'center' }}>{payment}</span>
    );
  };

  const showNote = (reservation, isForExport) => {
    const start = reservation.note;
    const end = reservation.noteReturn;

    const payment = determineDataForColumn(start, end, reservation, filters);

    return isForExport ? (
      payment
    ) : (
      <span style={{ alignItems: 'center' }}>{payment}</span>
    );
  };

  const getSortableTime = (reservation) => {
    const time = determineDataForColumn(
      reservation?.booking?.departureTime,
      reservation?.bookingReturn?.departureTime,
      reservation,
      filters
    );

    const formattedTime = moment(time, 'HH:mm:ss').isValid()
      ? moment(time, 'HH:mm:ss').toDate().getTime()
      : Number.MAX_SAFE_INTEGER; // if undefined set to max safe int value

    return formattedTime;
  };

  const sortedReservations = [...(data?.reservations || [])].sort((a, b) => {
    const timeA = getSortableTime(a);
    const timeB = getSortableTime(b);

    if (sortOrder === 'asc') {
      return timeA - timeB;
    } else {
      return timeB - timeA;
    }
  });

  const getReservationsHandler = () => {
    refetch();
    showSnackbar(t('reservations.departureTimeUpdated'), 'success');
  };
  useEffect(() => {
    // Implement any necessary logic in useEffect
    setFilters({
      ...filters,
      /*passengerName:
        role === 'Subagent'
          ? `${userForId?.firstName} ${userForId?.lastName}`
          : filters.passengerName,*/
      creator:
        role === 'Subagent'
          ? `${userForId?.firstName} ${userForId?.lastName}`
          : filters.creator,
    });
    //refetch(); // query havae param from state filters already and dont require any refetch
  }, [data]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (totalPassengersReservations >= filters.pageSize) {
        handleChangeRowsPerPage();
      }
    }
  };

  const navigateToReservations = () => {
    navigate('/UnapprovedReservations');
  };
  useEffect(() => {
    setIsCheckAllChecked(false);
    setIsCheckAllVisibleReservationUnavailable(true);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [filters, isLoading]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Paid':
        return 'green';
      case 'SMSConfirmed':
        return 'green';
      case 'Cancelled':
        return 'red';
      case 'Pending':
        return '#FFBF00'; // Yellow color
      default:
        return 'inherit';
    }
  };

  const getStatusMenuItems = () => {
    return ['Paid', 'Cancelled', 'Pending'].map((status) => (
      <MenuItem
        key={status}
        value={status}
        disabled={status == 'SMSConfirmed' ? true : false}
      >
        {status === 'Paid' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              width: '100%',
            }}
          >
            <CheckIcon style={{ fontSize: '0.875rem', color: '#12B76A' }} />
            <p style={{ fontSize: '13px', margin: '0' }}>
              {t('reservations.statusPaid')}
            </p>
          </div>
        )}

        {status === 'Cancelled' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              width: '100%',
            }}
          >
            <ClearIcon style={{ fontSize: '0.875rem', color: '#F04438' }} />
            <p style={{ fontSize: '13px', margin: '0' }}>
              {t('reservations.statusCancelled')}
            </p>
          </div>
        )}
        {status === 'Pending' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              width: '100%',
            }}
          >
            <PendingIcon style={{ fontSize: '0.875rem', color: '#FFBF00' }} />
            <p style={{ fontSize: '13px', margin: '0', marginRight: '5px' }}>
              {t('reservations.statusPending')}
            </p>
          </div>
        )}
      </MenuItem>
    ));
  };

  const handleStatusChange = async (
    event,
    reservationTicketType,
    passenger
  ) => {
    event.preventDefault();
    setHideTooltip(false);
    try {
      await changeReservationStatus({
        reservationTicketType: reservationTicketType,
        passengerId: passenger.id,
        status: event.target.value,
      });
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  function getLocationDisplay(routeStop, title) {
    if (title === '' || title === null) {
      const street = translateUnammedStreet(routeStop?.location?.street, t);
      const number = translateUnammedNumber(routeStop?.location?.number, t);
      return `${street} ${number}`;
    }

    return title;
  }

  const renderReservationsFilters = () => {
    try {
      if (
        data?.totalPassengersReservations != null ||
        data?.totalPassengersReservations == undefined
      ) {
        return (
          <ReservationsFilters
            filters={filters}
            onReceiveFilteredReservation={handleFilterReservation}
            role={'Admin'} // TO DO: add userRole, not admin
            total={data?.totalPassengersReservations}
            cancelled={data?.totalCanceledReservations}
            uncancelled={data?.totalNoCanceledReservations}
          />
        );
      } else {
        return <Typography>No filters</Typography>;
      }
    } catch (error) {
      console.error('Error rendering ReservationsFilters LINE 347:', error);
      return (
        <Typography>
          Something went wrong while rendering the filters.
        </Typography>
      );
    }
  };

  const checkForDirection = (reservation) => {
    let isReturnBooking = false;

    (reservation?.startStopReturn &&
      reservation.bookingReturn.direction == true &&
      filters.direction == 'departure') ||
    (reservation.startStopReturn &&
      reservation.bookingReturn.direction == false &&
      filters.direction == 'return') // ||
      ? // (reservation?.isReturnTicket && reservation.bookingReturn.direction == false && reservation.booking.direction == true && isSameDate(reservation.bookingReturn?.departure, filters?.date)) ||
        // (reservation?.isReturnTicket && reservation.booking.direction == false && reservation.bookingReturn.direction == true && isSameDate(reservation.bookingReturn?.departure, filters?.date))
        (isReturnBooking = false) // returnBooking
      : (isReturnBooking = true); // booking
    return isReturnBooking == false ? 'returnBooking' : 'booking';
  };

  const renderRegistrationPlates = (reservation) => {
    var reservationDirection =
      checkForDirection(reservation) == 'returnBooking'
        ? reservation.bookingReturn.direction
        : reservation.booking.direction;

    var reservationBooking = reservation.booking;
    var reservationBookingReturn = reservation.bookingReturn;

    if (
      reservationBooking.vehicle !== null &&
      reservationDirection === reservationBooking.direction
    ) {
      return (
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
            variant="caption"
          >
            {reservationBooking.vehicle.registrationPlates}
          </Typography>
        </Box>
      );
    } else if (
      reservationBookingReturn != null &&
      reservationBookingReturn.vehicle !== null &&
      reservationDirection === reservationBookingReturn.direction
    ) {
      return (
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
            variant="caption"
          >
            {reservationBookingReturn.vehicle.registrationPlates}
          </Typography>
        </Box>
      );
    }
    return <></>;
  };

  const renderVehicleIcon = (reservation) => {
    var reservationDirection =
      checkForDirection(reservation) == 'returnBooking'
        ? reservation.bookingReturn.direction
        : reservation.booking.direction;

    var reservationBooking = reservation.booking;
    var reservationBookingReturn = reservation.bookingReturn;

    if (
      reservationBooking.vehicle !== null &&
      reservationDirection === reservationBooking.direction
    ) {
      return (
        <IconButton>
          <DirectionsBusIcon />
        </IconButton>
      );
    } else if (
      reservationBookingReturn != null &&
      reservationBookingReturn.vehicle !== null &&
      reservationDirection === reservationBookingReturn.direction
    ) {
      return (
        <IconButton>
          <DirectionsBusIcon />
        </IconButton>
      );
    }
    return <></>;
  };

  const handleMainCheckboxChange = (reservationId, bookingDirection) => {
    setCheckedReservations((prevSelectedReservations) => {
      const newSelectedReservations = new Set(prevSelectedReservations);
      const newSelectedReservationsList = [...checkedReservationsList];

      if (newSelectedReservations.has(reservationId)) {
        // If the reservation is already selected, deselect it
        newSelectedReservations.delete(reservationId);

        const index = newSelectedReservationsList.findIndex(
          (res) => res.reservationId === reservationId
        );
        if (index !== -1) {
          newSelectedReservationsList.splice(index, 1);
        }
      } else {
        // Otherwise, select the reservation
        newSelectedReservations.add(reservationId);
        newSelectedReservationsList.push({
          reservationId: reservationId,
          direction: bookingDirection,
        });
        setRideProperties((prevState) => {
          return {
            ...prevState,
            rideDirection: filters.direction === 'departure',
            routeId: filters.routeId,
            departure: filters.date,
          };
        });
      }
      console.log(newSelectedReservations);
      console.log(newSelectedReservationsList);

      setCheckedReservationsList(newSelectedReservationsList);
      return newSelectedReservations;
    });
  };

  const isChecked = (reservationId) => checkedReservations.has(reservationId);

  const areFiltersDifferent = (currentFilters, initialFilters) => {
    return Object.keys(initialFilters).some((key) => {
      if (key === 'pageSize') {
        return false; // Exclude pageSize from the comparison beacause scroll increase it
      }
      return currentFilters[key] !== initialFilters[key];
    });
  };

  const handleCheckAllVisible = (event) => {
    setIsCheckAllChecked((prevState) => {
      return !prevState;
    });

    const newSelectedReservationsList = [...checkedReservationsList];

    if (event.target.checked) {
      const allVisibleReservationIds = data?.reservations
        .filter(
          (reservation) =>
            reservation.routeId != null &&
            reservation.passengers.length > 0 &&
            (checkForDirection(reservation) == 'returnBooking'
              ? checkBooking(reservation.bookingReturn)
              : checkBooking(reservation.booking))
        )
        .map((reservation) => {
          const direction =
            checkForDirection(reservation) == 'returnBooking'
              ? reservation.bookingReturn.direction
              : reservation.booking.direction;

          const alreadySelected = newSelectedReservationsList.some(
            (item) => item.reservationId === reservation.id
          );

          if (!alreadySelected) {
            newSelectedReservationsList.push({
              reservationId: reservation.id,
              direction,
            });
            setRideProperties((prevState) => {
              return {
                ...prevState,
                rideDirection: filters.direction === 'departure',
                routeId: filters.routeId,
                departure: filters.date,
              };
            });
          }

          return reservation.id;
        });

      console.log(new Set(allVisibleReservationIds));
      setCheckedReservations(new Set(allVisibleReservationIds));

      setCheckedReservationsList(newSelectedReservationsList);
    } else {
      setCheckedReservations(new Set());
      setCheckedReservationsList([]);
    }
  };

  const notifyHandler = () => {
    showSnackbar('notifyyy', 'success');
  };

  dayjs.extend(isSameOrAfter);

  const checkBooking = (booking) => {
    const hasValidDeparture = dayjs(booking.departure)
      .startOf('day')
      .isSameOrAfter(dayjs().startOf('day'));
    const res = booking.departureTime != null && hasValidDeparture;
    return res;
  };

  const isCheckboxDisabled = (reservation) => {
    return checkForDirection(reservation) == 'returnBooking'
      ? !checkBooking(reservation.bookingReturn)
      : !checkBooking(reservation.booking);
  };

  useEffect(() => {
    const filtersAreDifferent = areFiltersDifferent(
      filters,
      initialStateFilters
    );
    setIsCheckAllVisibleReservationUnavailable(!filtersAreDifferent);

    if (dayjs(filters.date).startOf('day').isBefore(dayjs().startOf('day'))) {
      setIsCheckAllVisibleReservationUnavailable(true);
    }
  }, [filters, initialStateFilters]);
  const renderSelectValue = (selected) => {
    if (selected === 'Paid') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <CheckIcon style={{ fontSize: '0.875rem', color: '#12B76A' }} />
          <p style={{ fontSize: '13px', margin: '0' }}>
            {t('reservations.statusPaid')}
          </p>
        </div>
      );
    } else if (selected === 'SMSConfirmed') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <CheckIcon style={{ fontSize: '0.875rem', color: '#12B76A' }} />
          <p style={{ fontSize: '13px', margin: '0', marginRight: '5px' }}>
            {t('reservations.statusConfirmedSMS')}
          </p>
        </div>
      );
    } else if (selected === 'Pending') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <PendingIcon style={{ fontSize: '0.875rem', color: '#FFBF00' }} />
          <p style={{ fontSize: '13px', margin: '0', marginRight: '5px' }}>
            {t('reservations.statusPending')}
          </p>
        </div>
      );
    } else if (selected === 'Cancelled') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <ClearIcon style={{ fontSize: '0.875rem', color: '#F04438' }} />
          <p style={{ fontSize: '13px', margin: '0' }}>
            {t('reservations.statusCancelled')}
          </p>
        </div>
      );
    }
    return selected;
  };

  return (
    <div className="all-reservations-main-container">
      {addOpen && (
        <div className="custom-dialog-add-vehicle">
          <CustomDialog
            onOpen={addOpen}
            onClose={handleAddClose}
            paperPropsStyle={{ width: '300px' }}
            contentTitle={'addVehicle'}
          >
            <AddVehicleForm
              reservations={checkedReservationsList}
              rideProperties={rideProperties}
              onSuccess={handleAddClose}
            ></AddVehicleForm>
          </CustomDialog>
        </div>
      )}
      {openEditTimeDialog && (
        <CustomDialog
          onOpen={openEditTimeDialog}
          onClose={closeEditTimeDialog}
          contentTitle={t('reservations.editDepartureTime')}
        >
          <EditDepartueTime
            onSuccess={closeEditTimeDialog}
            getReservationAfterEdit={getReservationsHandler}
            selectedReservation={selectedReservation}
            type={timeType}
          ></EditDepartueTime>
        </CustomDialog>
      )}

      <div className="all-reservations-content-container">
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            <h2 className="title-card-booking">{t('booking-list.name')}</h2>

            {renderReservationsFilters()}

            <div className="all-reservations-buttons">
              <div className="all-reservations-add-vehicle-button">
                <FilledButton
                  isValid={
                    checkedReservationsList.length > 0 &&
                    !isAddVehicleButtonDisabled
                  }
                  onClick={handleAddOpen}
                >
                  <DirectionsBusIcon /> &nbsp; {t('customDialog.addVehicle')}
                </FilledButton>
              </div>
              <div className="all-reservations-component-buttons">
                {/* { role === 'Admin' && 
                            <Button onClick={navigateToReservations} className='filters-button'>
                                <CheckCircleIcon /> &nbsp; {t('reservations.confirmList')}
                            </Button> } */}
                {/* <Button onClick={handleOnPdfDownload} className='filters-button'>
                                <PrinterIcon /> &nbsp; {t('reservations.pdfButton')}
                            </Button> */}

                <SMSButton reservations={checkedReservationsList} />

                {/* <Button onClick={notifyHandler} className="button-excel">
                <CircleNotifications /> &nbsp; {t('reservations.notify')}
              </Button> */}
                <Button
                  onClick={handleOnExcelDownload}
                  className="button-excel"
                >
                  <ExportIcon /> &nbsp; {t('reservations.excelButton')}
                </Button>
              </div>
            </div>

            {isCheckAllChecked == true || checkedReservations.size != 0 ? (
              <div className="label-container">
                <p className="check-count-label">
                  {t('reservations.number-of-checked-box-label')}{' '}
                  {checkedReservations.size}
                </p>
              </div>
            ) : (
              <></>
            )}
            <TableContainer component={Paper} className="table-container">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {data?.reservations?.length > 0 && (
                  <TableHead sx={{ backgroundColor: 'ButtonShadow' }}>
                    <TableRow className="table-row-header">
                      <TableCell
                        sx={{
                          borderLeft: '10px solid buttonShadow',
                          width: '100px',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip
                            title={t('reservations.selectAllCheckboxTooltip')}
                          >
                            <Checkbox
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  // color: '#ced9d1',
                                  color: isCheckAllVisibleReservationUnavailable
                                    ? '#ced9d1'
                                    : 'initial',
                                },
                              }}
                              disabled={
                                isCheckAllVisibleReservationUnavailable &&
                                isFiltersClear /*|| dayjs(filters.date).startOf('day').isBefore(dayjs().startOf('day'))*/
                              }
                              color="default"
                              onChange={handleCheckAllVisible}
                              checked={isCheckAllChecked}
                            ></Checkbox>
                          </Tooltip>
                          {t('reservations.bookingId')}
                        </div>
                      </TableCell>
                      <TableCell>{t('reservations.fullName')}</TableCell>
                      <TableCell sx={{ width: '120px' }}>
                        {t('reservations.phone')}
                      </TableCell>
                      <TableCell sx={{ width: '200px' }}>
                        {t('reservations.startStopAddress')}
                      </TableCell>
                      <TableCell sx={{ width: '150px' }}>
                        {t('reservations.startStopTown')}
                      </TableCell>
                      <TableCell sx={{ width: '200px' }}>
                        {t('reservations.endStopAddress')}
                      </TableCell>
                      <TableCell sx={{ width: '150px' }}>
                        {t('reservations.endStopTown')}
                      </TableCell>
                      <TableCell sx={{ width: '150px' }}>
                        {t('reservations.paymentMethod')}
                      </TableCell>
                      <TableCell sx={{ width: '150px' }}>
                        {t('reservations.isReturnTicket')}
                      </TableCell>
                      <TableCell sx={{ width: '120px' }}>
                        {t('reservations.createdAt')}
                      </TableCell>
                      {/* <TableCell sx={{ width: '120px' }}> */}
                      <TableCell
                        sx={{
                          width: '120px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '8px',
                          cursor: 'pointer',
                        }}
                        onClick={handleSortClick}
                      >
                        {t('reservations.departureTime')}
                        {sortOrder == 'asc' ? (
                          <ArrowUpwardIcon
                            fontSize="small"
                            style={{ marginTop: '4px', color: '#555' }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            fontSize="small"
                            style={{ marginTop: '4px', color: '#555' }}
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '200px' }}>
                        {t('reservations.status')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {sortedReservations.map((reservation, index) => (
                    <React.Fragment key={index}>
                      {reservation.routeId == null ||
                      reservation.passengers.length < 1 ? (
                        <></>
                      ) : (
                        <>
                          {/* Main row for the first passenger */}
                          <TableRow
                            key={`main-${index}`}
                            sx={{ padding: '0px' }}
                            className="table-row"
                          >
                            <TableCell
                              sx={{
                                color: 'green',
                                textAlign: 'left !important',
                                borderLeft: `10px solid ${randomColors[index % 2]}`,
                                cursor: 'pointer',
                              }}
                              className="booking-number"
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Checkbox
                                  checked={isChecked(reservation.id)}
                                  onChange={() =>
                                    handleMainCheckboxChange(
                                      reservation.id,
                                      checkForDirection(reservation) ==
                                        'returnBooking'
                                        ? reservation.bookingReturn.direction
                                        : reservation.booking.direction
                                    )
                                  }
                                  sx={{ display: 'inline' }}
                                  color="primary"
                                  disabled={isCheckboxDisabled(reservation)}
                                ></Checkbox>
                                {renderVehicleIcon(reservation)}
                                <Tooltip
                                  title={
                                    reservation.carRental == null
                                      ? ''
                                      : t("reservations.carRentedToolTip")
                                  }
                                >
                                  <a
                                    onClick={() =>
                                      handleIdClick(reservation.id)
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="booking-number"
                                    style={{
                                      textAlign: 'left !important',
                                      color:
                                        reservation.carRental == null
                                          ? ''
                                          : 'red',
                                    }}
                                  >
                                    #{reservation.passengers[0].reservationId}
                                  </a>
                                </Tooltip>
                              </div>
                              {renderRegistrationPlates(reservation)}
                            </TableCell>
                            <TableCell
                              sx={{
                                wordWrap: 'break-word' /* Break long words */,
                                wordBreak: 'break-word',
                                whiteSpace: 'normal',
                                minWidth: '100px !important',
                                // padding:"0px !important",
                                // maxWidth:"100px",
                              }}
                            >{`${reservation.passengers[0].name} ${reservation.passengers[0].lastName == null ? '' : reservation.passengers[0].lastName}`}</TableCell>
                            <TableCell
                              sx={{
                                wordWrap: 'break-word' /* Break long words */,
                                wordBreak: 'break-all',
                                whiteSpace: 'normal',
                                padding: '0px !important',
                                minWidth: '120px',
                              }}
                            >
                              {reservation.passengers[0].phoneNumber || '/'}
                            </TableCell>

                            <TableCell>
                              {showStartAddress(reservation)}
                              {/* {`${translateUnammedStreet(reservation?.startStop?.location?.street, t)}`}
                                  {` ${translateUnammedNumber(reservation?.startStop?.location?.number, t)}`} */}
                            </TableCell>
                            <TableCell>
                              {showStartLocation(reservation)}
                            </TableCell>
                            <TableCell>{showEndAddress(reservation)}</TableCell>

                            <TableCell>
                              {showEndLocation(reservation)}
                            </TableCell>
                            <TableCell>
                              {showPaymentMethod(reservation)}
                            </TableCell>
                            <TableCell>
                              {reservation.isReturnTicket
                                ? t('reservations.withReturnTicket')
                                : t('reservations.withoutReturnTicket')}
                            </TableCell>

                            <TableCell>{showDate(reservation)}</TableCell>

                            <TableCell>{showTime(reservation)}</TableCell>
                            <TableCell>
                              {((filters.direction == 'return' &&
                                reservation.isReturnTicket &&
                                !reservation.booking.direction) ||
                                (filters.direction == 'return' &&
                                  !reservation.booking.direction) ||
                                (filters.direction == 'departure' &&
                                  reservation.booking.direction) ||
                                filters.direction == '' ||
                                filters.direction == undefined) && (
                                <Tooltip
                                  title={t('booking.departureTicket')}
                                  placement="right-start"
                                >
                                  <Select
                                    value={
                                      reservation.passengers[0]
                                        .reservationStatus
                                    }
                                    onChange={(event) =>
                                      handleStatusChange(
                                        event,
                                        'departureTicket',
                                        reservation.passengers[0]
                                      )
                                    }
                                    renderValue={renderSelectValue}
                                    sx={{
                                      color: getStatusColor(
                                        reservation.passengers[0]
                                          .reservationStatus
                                      ),
                                      margin: '5px',
                                      maxWidth: '160px',
                                      minWidth: '160px',
                                      height: '30px',
                                      backgroundColor: 'white',
                                    }}
                                  >
                                    {getStatusMenuItems()}
                                  </Select>
                                </Tooltip>
                              )}
                              {reservation.bookingIdReturn &&
                                ((filters.direction == 'departure' &&
                                  reservation.isReturnTicket &&
                                  !reservation.booking.direction) ||
                                  (filters.direction == 'return' &&
                                    reservation.isReturnTicket &&
                                    reservation.booking.direction) ||
                                  filters.direction == '' ||
                                  filters.direction == undefined) && (
                                  <Tooltip
                                    title={t('booking.returnTicket')}
                                    placement="right-start"
                                    slotProps={{
                                      popper: {
                                        sx: {
                                          [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                            {
                                              marginBottom: '30px',
                                            },
                                        },
                                      },
                                    }}
                                  >
                                    <Select
                                      value={
                                        reservation.passengers[0]
                                          .returnReservationStatus
                                      }
                                      onChange={(event) =>
                                        handleStatusChange(
                                          event,
                                          'returnTicket',
                                          reservation.passengers[0]
                                        )
                                      }
                                      renderValue={renderSelectValue}
                                      sx={{
                                        color: getStatusColor(
                                          reservation.passengers[0]
                                            .returnReservationStatus
                                        ),
                                        margin: '5px',
                                        maxWidth: '160px',
                                        minWidth: '160px',
                                        height: '30px',
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      {getStatusMenuItems()}
                                    </Select>
                                  </Tooltip>
                                )}
                            </TableCell>
                          </TableRow>

                          {/* Additional rows for other passengers */}
                          {reservation.passengers
                            .slice(1)
                            .map((passenger, passengerIndex) => (
                              <React.Fragment
                                key={`${index}-${passengerIndex}`}
                              >
                                <TableRow
                                  sx={{ padding: '0px' }}
                                  className="table-row"
                                >
                                  <TableCell
                                    sx={{
                                      color: 'green',
                                      textAlign: 'center !important',
                                      cursor: 'pointer',
                                      borderLeft: `10px solid ${randomColors[index % 2]}`,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Checkbox
                                        checked={isChecked(reservation.id)}
                                        onChange={() =>
                                          handleMainCheckboxChange(
                                            reservation.id
                                          )
                                        }
                                        style={{ visibility: 'hidden' }}
                                        sx={{ display: 'inline' }}
                                        color="default"
                                      ></Checkbox>
                                      <Tooltip
                                        title={
                                          reservation.carRental == null
                                            ? ''
                                            : t("reservations.carRentedToolTip")

                                        }
                                      >
                                        <a
                                          onClick={() =>
                                            handleIdClick(
                                              passenger.reservationId
                                            )
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            color:
                                              reservation.carRental == null
                                                ? ''
                                                : 'red',
                                          }}
                                        >
                                          &nbsp;&nbsp;&nbsp;#
                                          {
                                            reservation.passengers[0]
                                              .reservationId
                                          }
                                        </a>
                                      </Tooltip>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      wordWrap: 'break-word',
                                      wordBreak: 'break-word',
                                      whiteSpace: 'normal',
                                      minWidth: '100px !important',
                                    }}
                                  >{`${passenger.name}  ${passenger.lastName == null ? '' : passenger.lastName}`}</TableCell>
                                  <TableCell
                                    sx={{
                                      wordWrap: 'break-word',
                                      wordBreak: 'break-all',
                                      whiteSpace: 'normal',
                                      padding: '0px !important',
                                      minWidth: '120px',
                                    }}
                                  >
                                    {passenger.phoneNumber || '/'}
                                  </TableCell>
                                  <TableCell>
                                    {showStartAddress(reservation)}
                                  </TableCell>
                                  <TableCell>
                                    {showStartLocation(reservation)}
                                  </TableCell>

                                  <TableCell>
                                    {showEndAddress(reservation)}
                                  </TableCell>

                                  <TableCell>
                                    {showEndLocation(reservation)}
                                  </TableCell>
                                  <TableCell>
                                    {showPaymentMethod(reservation)}
                                  </TableCell>
                                  <TableCell>
                                    {reservation.isReturnTicket
                                      ? t('reservations.withReturnTicket')
                                      : t('reservations.withoutReturnTicket')}
                                  </TableCell>
                                  <TableCell>{showDate(reservation)}</TableCell>
                                  <TableCell>{showTime(reservation)}</TableCell>
                                  <TableCell>
                                    {((filters.direction == 'return' &&
                                      reservation.isReturnTicket &&
                                      !reservation.booking.direction) ||
                                      (filters.direction == 'return' &&
                                        !reservation.booking.direction) ||
                                      (filters.direction == 'departure' &&
                                        reservation.booking.direction) ||
                                      filters.direction == '' ||
                                      filters.direction == undefined) && (
                                      <Tooltip
                                        title={t('booking.departureTicket')}
                                        placement="right-start"
                                        slotProps={{
                                          popper: {
                                            sx: {
                                              [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                                {
                                                  marginBottom: '30px',
                                                },
                                            },
                                          },
                                        }}
                                      >
                                        <Select
                                          value={passenger.reservationStatus}
                                          onChange={(event) =>
                                            handleStatusChange(
                                              event,
                                              'departureTicket',
                                              passenger
                                            )
                                          }
                                          renderValue={renderSelectValue}
                                          sx={{
                                            color: getStatusColor(
                                              passenger.reservationStatus
                                            ),
                                            margin: '5px',
                                            maxWidth: '160px',
                                            minWidth: '160px',
                                            height: '30px',
                                            backgroundColor: 'white',
                                          }}
                                        >
                                          {getStatusMenuItems()}
                                        </Select>
                                      </Tooltip>
                                    )}
                                    {reservation.bookingIdReturn &&
                                      ((filters.direction == 'departure' &&
                                        reservation.isReturnTicket &&
                                        !reservation.booking.direction) ||
                                        (filters.direction == 'return' &&
                                          reservation.isReturnTicket &&
                                          reservation.booking.direction) ||
                                        filters.direction == '' ||
                                        filters.direction == undefined) && (
                                        <Tooltip
                                          title={t('booking.returnTicket')}
                                          placement="right-start"
                                          slotProps={{
                                            popper: {
                                              sx: {
                                                [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                                  {
                                                    marginBottom: '30px',
                                                  },
                                              },
                                            },
                                          }}
                                        >
                                          <Select
                                            value={
                                              passenger.returnReservationStatus
                                            }
                                            onChange={(event) =>
                                              handleStatusChange(
                                                event,
                                                'returnTicket',
                                                passenger
                                              )
                                            }
                                            renderValue={renderSelectValue}
                                            sx={{
                                              color: getStatusColor(
                                                passenger.returnReservationStatus
                                              ),
                                              margin: '5px',
                                              maxWidth: '160px',
                                              minWidth: '160px',
                                              height: '30px',
                                              backgroundColor: 'white',
                                            }}
                                          >
                                            {getStatusMenuItems()}
                                          </Select>
                                        </Tooltip>
                                      )}
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {data?.reservations.length === 0 && (
              <h1 className="notification">
                {t('reservations.noReservationsWereFound')}
              </h1>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AllReservationsComponent;
