import React, { useState } from "react";
import "./AddUser.css";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {
  useAddUserMutation
} from "../../../features/auth/authApiSlice";
import EmailTextField from "../../CustomTextFields/EmailTextField";
import NameTextField from "../../CustomTextFields/NameTextField";
import PasswordTextField from "../../CustomTextFields/PasswordTextField";
import PhoneTextField from "../../CustomTextFields/PhoneTextField";
import UserNameTextField from "../../CustomTextFields/UserNameTextField";
import { useSnackbar } from "../../../store/context/SneckBarProvider";

import { useTranslation } from "react-i18next";
import { roles } from "../../../config";
import FilledButton from "../../Button/FilledButton";
import CustomDropDown from "../../CustomTextFields/CustomDropDown";

const AddUserForm = (props) => {
  const { t } = useTranslation();

  const { onClose,onAddUser } = props;
  const [register] = useAddUserMutation();

  const showSnackbar = useSnackbar();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    role: roles[0],
  });
  const [fieldValidities, setFieldValidities] = useState({
    // firstName: false,
    // lastName: false,
    userName: false,
    password: false,
    confirmPassword: false,
    email: false,
    phoneNumber:true
  });

  const handleChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    console.log("NAMEE", name);
    console.log("VALUEE", value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(formData);
  };

  const handlePhoneNumberChange = (phone) => {
    console.log(phone);
    setFormData((prevData) => ({
      ...prevData,
      phoneNumber: phone,//"+"+phone,
    }));
  };
  const handleFieldValidityChange = (fieldName, isValid) => {
    setFieldValidities((prevValidities) => ({
      ...prevValidities,
      [fieldName]: isValid,
    }));
  };

  const handleAddUser = async () => {
    try {
      const response = await register(formData).unwrap();
      showSnackbar(response?.message, "success");
      onAddUser();
      onClose();
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };

  const handlePhoneValidationChange=(isValid)=>{
    console.log(isValid)
    if(formData.role==="Driver" && !isValid){
      handleFieldValidityChange("phoneNumber",false)
      return
    }
    handleFieldValidityChange("phoneNumber",true)
    return
  } 

  return (
    <>
      <div className="add-user-form-main-container">
        <div className="add-user-form-twofields-container">
          <div className="add-user-form-onefield-cotaniner">
            <NameTextField
              label="First Name"
              name="firstName"
              nameValue={formData.firstName}
              onNameChange={handleChange}
              // onValidityChange={handleFieldValidityChange}
            />
          </div>
          <div className="add-user-form-onefield-cotaniner">
            <NameTextField
              label="Last Name"
              name="lastName"
              nameValue={formData.lastName}
              onNameChange={handleChange}
              // onValidityChange={handleFieldValidityChange}
            />
          </div>
        </div>
        <UserNameTextField
          label="User Name"
          name="userName"
          userName={formData.username}
          onNameChange={handleChange}
          onValidityChange={handleFieldValidityChange}
        />
        <EmailTextField
          label="Email"
          name="email"
          email={formData.email}
          onEmailChange={handleChange}
          onValidityChange={handleFieldValidityChange}
        />
        <div className="add-user-form-phone-roles-container">
          <PhoneTextField
            name="phoneNumber"
            value={formData.phoneNumber}
            onPhoneChange={handlePhoneNumberChange}
            useValidationProp={formData.role==="Driver"}
            onValidPhoneNumberChange={handlePhoneValidationChange}
          />
        </div>
        <CustomDropDown
            name="role"
            id="role"
            value={formData.role}
            onChange={handleChange}
            label={t('users.filterRole')}
            options={roles}
          />
        <div className="add-user-form-twofields-container">
          <div className="add-user-form-onefield-cotaniner">
            <PasswordTextField
              name="password"
              label="Password"
              onPasswordChange={handleChange}
              onValidityChange={handleFieldValidityChange}
              password={formData.password}
            />
          </div>

          <div className="add-user-form-onefield-cotaniner">
            <PasswordTextField
              name="confirmPassword"
              label={"Confirm password"}
              onPasswordChange={handleChange}
              onValidityChange={handleFieldValidityChange}
              password={formData.confirmPassword}
              comperePasswords={formData.password}
            />
          </div>
        </div>
        <FilledButton
          onClick={handleAddUser}
          isValid={fieldValidities.userName && fieldValidities.confirmPassword  && fieldValidities.password && fieldValidities.phoneNumber}
        >
          <SaveOutlinedIcon /> {t(`main.save`)}
        </FilledButton>
      </div>
    </>
  );
};

export default AddUserForm;
