import React, { createContext, useContext, useEffect, useState } from 'react';
import { useClearAllMutation, useEditDisplayStatusMutation, useGetSmsRecordsQuery } from '../../features/sms/smsSlice';

const SMSRecordContext = createContext();

export const useRecords = () => useContext(SMSRecordContext);

export const RecordsProvider = ({ children }) => {
  const { data, isLoading } = useGetSmsRecordsQuery();

  const [records, setRecords] = useState([]);

  const [editDisplayStatus] = useEditDisplayStatusMutation();

  const [clearAll] = useClearAllMutation();

  const changeDisplayStatus = async (id) => {
    if (id) {
      try {
        const response = await editDisplayStatus(id).unwrap();
        console.log('Status edited?', response);
      } catch (error) {
        console.error('Failed', error);
      }
    }
  };

  const updateRecord = (updatedRecord) => {
    setRecords((prevRecords) => {
      const recordExists = prevRecords.some(
        (record) => record.id === updatedRecord.id
      );

      if (recordExists) {
        // Update the existing record
        return prevRecords.map((record) =>
          record.id === updatedRecord.id ? updatedRecord : record
        );
      } else {
        // Add the new record to the array
        return [updatedRecord, ...prevRecords];
      }
    });
  };

  const getNumberOfNotifications = () => {
   return records.length;
  };

  const removeRecordById =async (id) => {
    await changeDisplayStatus(id);
    setRecords((prevRecords) =>
      prevRecords.filter((record) => record.id !== id)
    );
  };

  const removeAll = async () => {
    try {
      const ids = records.map((record) => record.id);

      if (ids.length > 0) {
        await clearAll(ids).unwrap();

        setRecords([]);
        console.log('All statuses updated and notifications cleared.');
      }
    } catch (error) {
      console.error('Failed to clear all notifications:', error);
    }
  };

  useEffect(() => {
    if (data) {
      setRecords((prevRecords) => [...prevRecords, ...data]);
    }
  }, [data]);

  return (
    <SMSRecordContext.Provider
      value={{
        records,
        updateRecord,
        getNumberOfNotifications,
        removeRecordById,
        removeAll
      }}
    >
      {children}
    </SMSRecordContext.Provider>
  );
};
