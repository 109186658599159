import ClearIcon from '@mui/icons-material/Clear';
import { Fab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecords } from '../../store/context/SMSRecordContext';
import CustomDialog from '../Dialog/CustomDialog';
import SMSRecord from '../Sms/SMSRecord';
import SMSStatuses from '../Sms/SmsStatuses';

//TODO SHOULD CHANGE FILE NAME
const DrawerContent = ({ onClcik }) => {
  const { t, i18n } = useTranslation();

  const { records, removeRecordById } = useRecords();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();

  const [isBottom, setIsBottom] = useState(false);
  const { removeAll } = useRecords();

  const handleNotificationClick = (record) => {
    // console.log(record);
    setData(record);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setData(...records);
  }, [records]);

  return (
    <>
      <div
        style={{
          width: '300px',
          padding: '5px',
          zIndex: '100',
          height: '100vh',
        }}
        role="presentation"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <h2 style={{ textAlign: 'center' }}>{t('sms.notifications')}</h2>
          {
            // records.length !== 0 && (
            //   <Tooltip sx={{cursor:"pointer"}} title={t('sms.clearAll')}>
            //     <ClearAllIcon fontSize='large' onClick={removeAll} />
            //   </Tooltip>
            // )

            records.length === 0 ? (
              <></>
            ) : (
              <Fab
                variant="extended"
                size="small"
                //color="primary"
                sx={{
                  fontSize: '10px',
                }}
                onClick={removeAll}

                //bottom flow button
                /*sx={{
                  position: 'fixed',
                  bottom: isBottom ? 16 : 0,
                  right: '2%',
                  transform: 'translateX(-50%)',
                  transition: 'bottom 0.3s ease',
                  zIndex: 1000,
                }}*/
              >
                <ClearIcon sx={{ mr: 1, width: '15px', height: '15px' }} />
                {t('sms.clearAll')}
              </Fab>
            )
          }
        </div>
        {records.length === 0 ? (
          <div className="drawer-content-no-record-found">
            <p>{t('sms.noNotifications')}</p>
          </div>
        ) : (
          records.map((record) => (
            <>
              {record.smSs.length > 0 && (
                <div key={record.id}>
                  <SMSRecord
                    record={record}
                    onClick={() => handleNotificationClick(record)}
                    onRemoveClick={async () =>
                      await removeRecordById(record.id)
                    }
                  />
                </div>
              )}
            </>
          ))
        )}
        {open && data && (
          <CustomDialog onOpen={open} onClose={handleClose} customMargin="0px">
            <SMSStatuses onCloseDialog={handleClose} responses={data.smSs} />
          </CustomDialog>
        )}
      </div>
    </>
  );
};

export default DrawerContent;
