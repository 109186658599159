import React, { useEffect } from 'react';
import { useConfirmDeleteMutation } from '../../features/users/UsersApiSlice';
import './DeleteProfile.css';
import { useSnackbar } from '../../store/context/SneckBarProvider';
import { useTranslation } from 'react-i18next';
import PageLoader from '../PageLoader/PageLoader';

const DeleteProfileConfirm = (props) => {
  const showSnackbar = useSnackbar();

  const { t, i18n } = useTranslation();
  const [languageState, setLanguageState] = React.useState('srpski');

  const [confirmDelete, { isLoading }] = useConfirmDeleteMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!props.userId || !props.token) return;
      await confirmDelete({
        userId: props.userId,
        token: props.token,
      }).unwrap();
      showSnackbar(t('deleteProfileConfirm.success'), 'success');
    } catch (error) {
      showSnackbar(error?.data?.Message, 'error');
    }
  };

  useEffect(() => {
    document.body.classList.add('login-page');
    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  const hangleChangeLanguage = () => {
    const currentLanguage = i18n.language;
    console.log(currentLanguage);

    if (languageState === 'srpski') {
      setLanguageState('english');
    } else {
      setLanguageState('srpski');
    }

    const newLanguage = i18n.language === 'en' ? 'rs' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const content = isLoading ? (
    <PageLoader />
  ) : (
    <div className="my-container">
      <div className="my-right">
        <div className="my-header-language">
          {' '}
          <span onClick={hangleChangeLanguage}>
            {i18n.language === 'rs' ? 'SRB' : 'ENG'}
          </span>
        </div>
        <div className="my-login-form">
          <img
            src="/assets/terra-logo.jpeg"
            alt="logo"
            width="40"
            height="40"
          />
          <h2>{t('deleteProfileConfirm.deleteTitle')}</h2>
          <div className="my-form-style">
            <p style={{ marginBottom: 40 }}>
              {t('deleteProfileConfirm.deleteMessage')}
            </p>
            <button
              className="my-log-button"
              onClick={handleSubmit}
              type="submit"
            >
              {t('deleteProfileConfirm.send')}
            </button>
          </div>
        </div>
        <footer className="my-footer-text">
          <p>www.terratravel.rs</p>
        </footer>
      </div>
    </div>
  );
  return content;
};

export default DeleteProfileConfirm;
