import * as React from "react";
import CustomDialog from "../../Dialog/CustomDialog";
import AddUserForm from "./AddUserForm";

export default function AddUserDialog(props) {
  const { onClose, open,handleAddedUser } = props;

  return (
    <>
      <CustomDialog
        onOpen={open}
        onClose={onClose}
        contentTitle="ADD USER"
      >
        <AddUserForm onClose={onClose} onAddUser={handleAddedUser}></AddUserForm>
      </CustomDialog>
    </>
  );
}
