import { styled } from "@mui/material/styles";
import usePagination from "@mui/material/usePagination";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from "../../Icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../Icons/arrow-right.svg";
import "./Paginator.css";
// Styled component for the list
const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

// Paginator component
export default function UsePagination(props) {
  const { pathname } = useLocation();
  const { totalPages, onPageChange, pageFromDb,reset } = props;
  const { t } = useTranslation();
  // State to track the current page
  const [page, setPage] = React.useState(1);

  const { items } = usePagination({
    count: totalPages,
    page: page,
    onChange: (event, value) => handlePageChange(value),
  });

  const handlePageChange = (value) => {
    console.log(value);
    setPage(value);
    onPageChange(value);
    window.scrollTo(0, 0);

  };

  React.useEffect(()=>{
    setPage(1);

  },[reset])
  return (
    <nav className="paginator-content-container">
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          // Define different children based on the type
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <button
                type="button"
                style={{
                  fontWeight: selected ? "bold" : undefined,
                }}
                {...item}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button type="button" {...item}>
                {type}
              </button>
            );
          }

          // Add icon to the first button
          if (index === 0) {
            children = (
              <button
                className={`next-button ${page === 1 ? "disabled" : ""}`}
                type="button"
                {...item}
                // disabled={page === 1}
              >
                <ArrowLeft /> {t("main.previous")}
              </button>
            );
          }
          if (index === items.length - 1) {
            children = (
              <button
                className={`previouse-button ${page === 1 ? "disabled" : ""}`}
                type="button"
                {...item}
                // disabled={page === totalPages}
              >
                {t("main.next")}
                <ArrowRight />
              </button>
            );
          }

          return (
            <li
              key={index}
              className={`pagination-item ${
                type === "page" ? "page-button" : type
              } ${index === 0 ? "first-button" : ""} ${
                index === items.length - 1 ? "last-button" : ""
              }`}
            >
              {children}
            </li>
          );
        })}
      </List>
    </nav>
  );
}
