import { useEffect, useRef, useState } from "react";
import "./AddRouteByAutocomplete.css";
// import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { newTextFieldStyle } from "../../../customStyles/customStyles";
import { useAddRouteWithStopsMutation, useLazyCheckExistingRouteTitleQuery, useUpdateRouteMutation } from "../../../features/routes/routesSlice";
import { ReactComponent as BusIcon } from "../../../Icons/busIcon.svg";
import { ReactComponent as CloseIcon } from "../../../Icons/x-close.svg";
import FilledButton from "../../Button/FilledButton";
import WhiteButton from "../../Button/WhiteButton";
import { useValidation } from "../../../store/context/ValidationProvider";
import CustomDropDown from "../../CustomTextFields/CustomDropDown";
import CustomTextField from "../../CustomTextFields/CustomTextField";
import ValidationErrors from "../../Error/ValidationErrors";
import InputAutoComplete from "../../GoogleMap/InputAutocomplete";
import AutocompleteTextarea from "../../GoogleMap/TextAreaWithAutoComplete";
import { useSnackbar } from "../../../store/context/SneckBarProvider";
import DisplayRouteDialog from "../DisplayRouteComponent/DisplayRouteDialog";

const options = ["Active", "Inactive"];

const startFormData = {
  title: "",
  price: "",
  startLocation: null,
  endLocation: null,
  isEnabled: options[0],
  routeStops: {},
};

export default function AddRouteByAutoCompleteForm(props) {
  const { t } = useTranslation();
  const isFormValid = () => titleValid && priceValid && startLocationValid && endLocationValid === true;

  const [updateRoute] = useUpdateRouteMutation();
  const { onAddNewRoute, onClose, route, formTitle, onRouteUpdate } = props;
  const fileName = "add-route-auto-complete";
  const [formData, setFormData] = useState(startFormData);
  const { validateRouteTitleField, validatePriceField,validateAutocompleteField } = useValidation();
  const [addRoute] = useAddRouteWithStopsMutation();
  const showSnackbar = useSnackbar();
  // const showSnacbar = useSnackbar();
  const [titleValid, setValidTitle] = useState(null);
  const [priceValid, setValidPrice] = useState(null);

  const [startLocationValid, setStartLocationValid] = useState(null);
  const [endLocationValid, setEndLocationValid] = useState(null);
  const [resetChange, setResetChange] = useState(true);
  const [open, setOpen] = useState(false);
  const debounceTimeout = useRef(null);

  const [titleErrors, setTitleErrors] = useState(null);
  const [priceErrors, setPriceErrors] = useState(null);
  const [startLocationErrors, setStartLocationErrors] = useState(null);
  const [endLocationErrors, setEndLocationErrors] = useState(null);

  // console.log(route.startLocation)
  const [checkRouteTitle, { error }] = useLazyCheckExistingRouteTitleQuery();

  const handleEditRoute = async (updatedRoute) => {
    try {
      const response = await updateRoute(updatedRoute).unwrap();
      onAddNewRoute();
      showSnackbar(response?.message, "success");
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };

  const getTitleValidation = (title) => {
    var validation;
    validation = validateRouteTitleField(title);
    setValidTitle(validation.isValid);
    setTitleErrors(validation.errors);
  };

  const getPriceValidation = (price) => {
    var validation;
    validation = validatePriceField(price);
    setValidPrice(validation.isValid);
    setPriceErrors(validation.errors);
  };
  const getStartLocationValidation = (location) => {
    console.log("dfdasfasd",location)
    var validation;
    validation = validateAutocompleteField(location);
    setStartLocationValid(validation.isValid);
    setEndLocationErrors(validation.errors);
  };
  const getEndLocationValidation = (location) => {
    var validation;
    validation = validateAutocompleteField(location);
    setEndLocationValid(validation.isValid);
    setStartLocationErrors(validation.errors);
  };

  useEffect(() => {
    if (route) {
      setData("title", route.title);
      setData("price", route.price);
      if (route.isEnabled === true) {
        setData("isEnabled", options[0]);
      } else {
        setData("isEnabled", options[1]);
      }
      getTitleValidation(route.title);
      getPriceValidation(route.price)
    }
  }, []);

  // const setRouteStops = ()=>{

  // }
  const checkValidPrice = (value) => {
    getPriceValidation(value);
    if (value === "") {
      setValidPrice(null);
      setPriceErrors(null);
    }
  };
  const checktValidRouteTitle = (value) => {
    getTitleValidation(value);

    if (value === "") {
      setValidTitle(null);
      setTitleErrors(null);
    }
    getCustomLocationPredictionsOnInputChange(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "title") {
      checktValidRouteTitle(value);
    }
    if (name === "price") {
      checkValidPrice(value);
    }
    setData(name, value);
    console.log(formData);
  };

  const getCustomLocationPredictionsOnInputChange = (value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      if (value.trim().length > 0) {
        // getCustomLocationPredictions(value);
        if (route) {
          if (route.title !== value) {
            checkRouteTitle(value);
          }
        } else {
          checkRouteTitle(value);
        }
      }
    }, 1000);
  };

  useEffect(() => {
    console.log(error);
    if (error != undefined) {
      showSnackbar(error?.data?.Message, "error");
    }
  }, [error]);

  const handleRouteStops = (routeStops) => {
    setData("routeStops", routeStops);
  };
  const handleStartLocationChange = (location) => {
    setData("startLocation", location);
    getStartLocationValidation(location)
    console.log(location);
  };
  const handleEndLocationChange = (location) => {
    setData("endLocation", location);
    getEndLocationValidation(location)
  };

  const setData = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleRouteAdd = async (newRoute) => {
    try {
      const response = await addRoute(newRoute).unwrap();
      onAddNewRoute();
      showSnackbar(response?.message, "success");
      // reset();
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };

  const addRouteObj = () => {
    let enabled;
    if (formData.isEnabled === "Active") enabled = true;
    else enabled = false;
    const routeInfo = {
      title: formData.title,
      price: formData.price,
      isEnabled: enabled,
    };
    const creteRouteDTO = {
      routeInfo: routeInfo,
      startLocation: formData.startLocation,
      endLocation: formData.endLocation,
    };
    const newRoute = {
      creteRouteDTO,
      routeStops: formData.routeStops,
    };
    return newRoute;
  };
  const updateRouteObj = () => {
    var stopovi = [...formData.routeStops];

    var stopovi = formData.routeStops.map((stop) =>
      stop.location === undefined ? { location: stop } : stop
    );
    let enabled;

    if (formData.isEnabled === "Active") enabled = true;
    else enabled = false;
    const updateRouteDTO = {
      id: route.id,
      title: formData.title,
      price: formData.price,
      isEnabled: enabled,
      startRouteLocation: formData.startLocation,
      endRouteLocation: formData.endLocation,
      startStopId: route.startStopId,
      endStopId: route.endStopId,
      routeStops: stopovi,
    };
    // const newRoute = {
    //   updateRouteDTO,
    // };
    return updateRouteDTO;
  };

  console.log(formData.routeStops);

  const handleAddUpdate = async () => {
    var newRoute;
    if (route == undefined) {
      newRoute = addRouteObj();
      await handleRouteAdd(newRoute);
    } else {
      newRoute = updateRouteObj();
      handleEditRoute(newRoute);
      console.log(newRoute);
      console.log(route.newRoute);
    }
  };

  const formReset = () => {
    setValidTitle(null);
    setTitleErrors(null);
    setValidPrice(null);
    setPriceErrors(null);
    setEndLocationErrors(null)
    setEndLocationValid(null)
    setStartLocationErrors(null)
    setEndLocationErrors(null)
    setResetChange(!resetChange);
    if (route == undefined) {
      setFormData(startFormData);
    } else {
      setData("title", route.title);
      setData("price", route.price);
      setData("isEnabled", route.isEnabled);
    }
    console.log(formData);
  };
  const closeDialog = () => {
    setOpen(false);
  };
  const openDialog = () => {
    console.log(formData);
    if (formData.startLocation && formData.endLocation) {
      console.log(formData.routeStops);
      setOpen(true);
    }
  };
  return (
    <>
      <div className={`${fileName}-main-cotaniner`}>
        <div className="add-route-auto-complete-top-cotaniner">
          <div className={startLocationValid && endLocationValid ? `add-route-auto-complete-busicon-container`:`add-route-auto-complete-busicon-container-invalid `}>
            <BusIcon onClick={openDialog} />
          </div>
          <div className="add-route-auto-complete-header-container">
            <p className="add-route-auto-complete-header">
              {t(`add-route.add-route-title.${formTitle}`)}{" "}
              <CloseIcon onClick={onClose} />
            </p>
            <p className="add-route-auto-complete-description">
              {t("add-route.Enter basic route information")}
            </p>
          </div>
        </div>
        <div>
          <div className="add-route-auto-complete-content-container">
            <div>
              <CustomTextField
                name="title"
                onChange={handleChange}
                value={formData.title}
                type="text"
                placeholder={t("add-route.e.g., Belgrade - Slovenia")}
                label={t("add-route.route_name")}
                sx={newTextFieldStyle}
              />
              {titleErrors && (
                <ValidationErrors errors={titleErrors}></ValidationErrors>
              )}
            </div>
            <div className="add-route-auto-complete-autocomplete-fields">
              <InputAutoComplete
                labelText={t("add-route.From")}
                placeholder={t("add-route.Departure place")}
                onLocationChange={handleStartLocationChange}
                // onValidLocatinChange={handleStartLocationValidation}
                reesetChange={resetChange}
                locationForUpdate={route?.startLocation || undefined}
                label="true"
                customStyle={newTextFieldStyle}
                hasIcon={false}
                title={route?.startLocation.title? route.startLocation.title:null}
              />

              <InputAutoComplete
                labelText={t("add-route.To")}
                placeholder={t("add-route.Arrival place")}
                onLocationChange={handleEndLocationChange}
                // onValidLocatinChange={handleEndLocationValidation}
                reesetChange={resetChange}
                locationForUpdate={route?.endLocation || undefined}
                label="true"
                customStyle={newTextFieldStyle}
                hasIcon={false}
                title={route?.endLocation.title? route.endLocation.title:null}

              />

            </div>
            <div>
              <CustomTextField
                name="price"
                onChange={handleChange}
                value={formData.price}
                type="number"
                placeholder={t("add-route.e.g., 230$")}
                label={t("route-list.pricee")}
                sx={newTextFieldStyle}
              />
              {priceErrors && (
                <ValidationErrors errors={priceErrors}></ValidationErrors>
              )}
            </div>
            <CustomDropDown
              name="isEnabled"
              id="isEnabled2"
              value={formData.isEnabled}
              onChange={handleChange}
              label={t("route-filters.routeState")}
              options={options}
            />
            <div>
              <label className="custom-label">
                {t("add-route.Stops (separated by semicolon)")}
              </label>

              <AutocompleteTextarea
                onRouteStopsChange={handleRouteStops}
                reesetChange={resetChange}
                stops={
                  route?.routeStops
                    ?.filter((stop) => stop.isEnabled)
                    .slice(2) || undefined
                }
              />
            </div>
            <div className={`add-route-auto-complete-bottom-cotaniner`}>
              <WhiteButton onClick={formReset}>
                {" "}
                {t("reservationsFilters.filterButtonReset")}
              </WhiteButton>
              <FilledButton isValid={isFormValid()} onClick={handleAddUpdate}>
                {t("main.save")}
              </FilledButton>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <DisplayRouteDialog
          open={open}
          onClose={closeDialog}
          routeStops={formData.routeStops.map((stop) =>
            stop.location
              ? stop
              : {
                  location: {
                    lat: stop.lat,
                    long: stop.long,
                  },
                }
          )}
          origin={formData.startLocation}
          destination={formData.endLocation}
        />
      )}
    </>
  );
}
