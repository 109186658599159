
import "./AddRouteStop.css";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export const NewRouteStops = (props) => {
  const { t } = useTranslation();

  const { newStops, handleRejectRouteStop, handleAcceptRouteStop,handleRejectAllStops,handleAddRouteStops } =
    props;

  return (
    <>
      {newStops && newStops.length > 0 && (
        <>
          <div className="add-route-stops-new-route-stops">
            <div>
              <h3>{t("route-stops.newStops")}</h3>
              <div className="add-new-route-stop-stops">
                {newStops.map((stop, index) => (
                  <div className="add-route-stop-new-stop" key={index}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{stop.location.city}</span>
                      <span>
                        {stop.location.street} {stop.location.number}
                      </span>
                    </div>
                    <div className="add-route-stop-icons-container">
                      <Tooltip title={t("route-stops.acceptNewStop")}>
                        <CheckIcon
                          sx={{ color: "green", cursor: "pointer" }}
                          onClick={() => handleAcceptRouteStop(stop, index)}
                        />
                      </Tooltip>
                      <Tooltip title={t("route-stops.rejectNewStop")}>
                        <ClearIcon
                          sx={{ color: "red", cursor: "pointer" }}
                          onClick={() => handleRejectRouteStop(index)}
                        />
                      </Tooltip>
                    </div>
                    {/* {index} */}
                  </div>

                  // 1
                ))}
              </div>
            </div>
            <div className="add-route-stop-new-route-stops-button-container">
              <Button variant="contained" style={{ flex: "1" }} onClick={handleAddRouteStops}>
                {t("route-stops.acceptAll")}
              </Button>
              <Button variant="contained" style={{ flex: "1" }} onClick={handleRejectAllStops}>
                {t("route-stops.rejectAll")}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
