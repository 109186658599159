import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { RideRow } from './RideRow';
import { useTranslation } from 'react-i18next';

export default function RidesTable(props) {
  const {rides}=props
  const { t } = useTranslation();
  return (
    <div className="route-table-main-container">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t('rides.ride')}</TableCell>
              <TableCell>{t('rides.license_plate')}</TableCell>
              <TableCell>{t('rides.direction')}</TableCell>
              <TableCell>{t('rides.drivers')}</TableCell>
              <TableCell>{t('rides.departure')}</TableCell>
              <TableCell>{t('rides.departureTime')}</TableCell>
              <TableCell>{t('rides.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rides.map((ride) => (
              <RideRow key={ride.id} ride={ride} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
