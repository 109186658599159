import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom'
import PasswordTextField from '../components/CustomTextFields/PasswordTextField';
import { useResetForgottenPasswordMutation, useSendMailForForgottenPasswordMutation, useValidateResetTokenMutation } from '../features/auth/authApiSlice';
import CustomButton from '../components/Button/Button';
import { useSnackbar } from '../store/context/SneckBarProvider';

const ForgetPassword = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validity, setValidity] = useState({ newPassword: false, confirmPassword: false });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isValidToken, setIsValidToken] = useState(null);
  const [sendEmailStatus, setSendEmailStatus] = useState(null);
  const showSnacbar = useSnackbar();

  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const [resetForgottenPassword] = useResetForgottenPasswordMutation();
  const [validateResetToken] = useValidateResetTokenMutation();
  const [sendMailForForgottenPassword] = useSendMailForForgottenPasswordMutation();

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await validateResetToken({
          email,
          token,
        }).unwrap();

        setIsValidToken(true);
      } catch (error) {
        setIsValidToken(false);
      }
    };

    validateToken();
  }, [email, token]);

  const handleChange = (e, type) => {
    const { name, value } = e.target;
    type == 'newPassword' ? setNewPassword(value) : setConfirmPassword(value);
  };

  const handleValidityChange = (fieldName, isValid) => {
    setValidity((prev) => ({ ...prev, [fieldName]: isValid }));
  };

  const isFormValid = validity.newPassword && validity.confirmPassword && newPassword === confirmPassword;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await resetForgottenPassword({
        email,
        token,
        newPassword,
      }).unwrap();

      navigate('/reset-password-success', {
        state: { message: response.message || 'Šifra je uspešno promenjena.' },
        replace: true,
      })

      if (response.success) {
        setSuccessMessage(response.message || 'Šifra je uspešno promenjena.');
      }
    } catch (error) {
      setError(error?.data?.error || 'Neuspešno resetovanje šifre. Probaj ponovo!');
    }
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await sendMailForForgottenPassword(email)//.unwrap();      
      if (response.data) {
        showSnacbar(response.message || 'E-mail sa linkom za resetovanje šifre je uspešno poslat','success')
      }
    } catch (error) {
      showSnacbar(error?.data?.error || 'Neuspešno slanje. Pokušaj opet!','error')
    }
  }

  if (isValidToken == null) {
    return <p>Loading...</p>;
  }

  if (!isValidToken) {
    return (
      <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <p>Token je istekao !</p>
        <Button type='submit' onClick={sendEmail}>
          {t('main.sendAgain')}
        </Button>
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '80vh',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '32px',
        marginTop: '20px',
      }}
    >
      <div
        style={{
          maxWidth: '400px',
          padding: '0 16px',
          boxSizing: 'border-box',
        }}
      >
        <header
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <img
            style={{padding:'20px',width:'100%'}}
            src="../assets/terra-logo.png"
            alt="Logo"
          />
        </header>
        
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            maxWidth: '400px',
            padding: '20px',
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <PasswordTextField
            name="newPassword"
            label={'New password'}
            password={newPassword}
            onPasswordChange={(e) => handleChange(e, 'newPassword')}
            onValidityChange={(fieldName, isValid) => handleValidityChange('newPassword', isValid)}
            toggleMode="toggle"
          />

          <PasswordTextField
            name="confirmPassword"
            label={'Confirm password'}
            password={confirmPassword}
            onPasswordChange={(e) => handleChange(e, 'confirmPassword')}
            comperePasswords={newPassword}
            onValidityChange={(fieldName, isValid) => handleValidityChange('confirmPassword', isValid)}
            toggleMode="toggle"
          />
          
        </div>
      </div>

      <div>
        <CustomButton  onClick={handleSubmit} disabled={!isFormValid}>
          {t('main.save')}
        </CustomButton>
      </div>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {sendEmailStatus && <div style={{ color: 'green' }}>{sendEmailStatus}</div>}
    </div>
  );
}

export default ForgetPassword