import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import React, { useEffect, useState } from 'react';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import TableRow from '@mui/material/TableRow';
import LockResetIcon from '@mui/icons-material/LockReset';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Card } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { enableble, rolesAll as roles } from '../../../config';
import '../../../customStyles/globalDesignStyles.css';
import {
  useDisableUserMutation,
  useEnableUserMutation,
  useLazyGetUsersPerPageQuery,
} from '../../../features/users/UsersApiSlice';
import { ReactComponent as EditIcon } from '../../../Icons/edit-01.svg';
import FadeMenu from '../../Button/FadeMenu';
import ConfirmationDialog from '../../Dialog/ConfirmationDialog';
import PageLoader from '../../PageLoader/PageLoader';
import UsePagination from '../../Pagginator/Paginator';
import { useSnackbar } from '../../../store/context/SneckBarProvider';
import AddUserDialog from '../AddUserComponent/AddUserDialog';
import InviteUser from '../AddUserComponent/InviteUserDialog';
import EditUserDialog from '../UpdateUser/EditUserDialog';
import ResetPasswordDialog from '../UpdateUser/ResetPasswordDialog';
import './ShowAllUsersComponent.css';
import UsersFilter from './UsersFilter';
const ShowAllUsersComponent = () => {
  const { t } = useTranslation();

  const columns = [
    { id: 'firstName', label: t('users.name') },
    {
      id: 'lastName',
      label: t('users.surname'),
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'phoneNumber',
      label: t('users.phoneNumber'),
      format: (value) => value.toLocaleString('en-US'),
    },
    { id: 'userName', label: t('users.userName') },
    { id: 'email', label: t('users.email') },

  ];
  const [
    getUserPerPage,
    { data: users, isLoading, isSuccess, isError, error },
  ] = useLazyGetUsersPerPageQuery();

  const [disableUser] = useDisableUserMutation();
  const [enableUser] = useEnableUserMutation();

  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [resetState, setResetPaginator] = useState(false);

  const [filterUsers, setFilter] = useState({
    pageNumber: 1,
    pageSize: 10,
    isEnabled: enableble[1],
    role: roles[0],
  });

  const showSnackbar = useSnackbar();

  const navigate = useNavigate();


  const handleAddOpen = () => {
    setDialog('add');
    setOpen(true);
  };
  const handleInviteOpen = () => {
    setDialog('invite');
    setOpen(true);
  };
  const handleUpdateOpen = (user) => {
    setDialog('update');
    setOpen(true);
    setSelectedUser(user);
  };
  const handleDisableOpen = (user) => {
    console.log(user);
    setDialog('disable');
    setOpen(true);
    setSelectedUser(user);
  };
  const handleEnableOpen = (user) => {
    setDialog('enable');
    setOpen(true);
    setSelectedUser(user);
  };
  const handleResetPasswordOpen = (user) => {
    setDialog('reset-password');
    setOpen(true);
    setSelectedUser(user);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDisableUser = async () => {
    console.log(selectedUser.id);
    try {
      const response = await disableUser(selectedUser.id).unwrap();
      showSnackbar(response?.message, 'success');
      getUserPerPage(filterUsers);
      handleClose();
    } catch (err) {
      showSnackbar(err?.data?.Message, 'error');
    }
  };
  const handleEnableUser = async () => {
    try {
      const response = await enableUser(selectedUser.id).unwrap();
      showSnackbar(response?.message, 'success');
      getUserPerPage(filterUsers);
      handleClose();
    } catch (err) {
      showSnackbar(err?.data?.Message, 'error');
    }
  };
  async function hendleUpdate() {
    getUserPerPage(filterUsers);
  }

  const handleAddUser = () => {
    setFilter((prevData) => ({
      ...prevData,
      pageNumber: 1,
    }));
    setResetPaginator(!resetState);
    setOpen(false);
  };
  const handlePageChange = (value) => {
    console.log(value);
    setFilter({
      ...filterUsers,
      pageNumber: value,
    });
  };
  const handleFilterClcick = (filterData) => {
    setFilter((prevData) => ({
      ...prevData,
      pageNumber: 1,
      ...filterData,
    }));
    setResetPaginator(!resetState);
  };

  useEffect(() => {
    getUserPerPage(filterUsers);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterUsers]);

  const resetFilters = () => {
    setFilter({
      pageNumber: 1,
      pageSize: 10,
      isEnabled: enableble[1],
      role: roles[0],
    });
    setResetPaginator(!resetState);
  };

  if (isLoading) return <PageLoader />;
  if (isError) return <div>Error: {error?.message || 'An error occurred'}</div>;
  return (
    <>
      {isSuccess && users && (
        <div className="show-all-users-body-container">
          <div className="show-all-users-main-container">
            <div className="show-routes-button-header-container">
              <span className="page-header-container">{t('users.users')}</span>
              <FadeMenu
                onAddUserClick={handleAddOpen}
                onInviteUserClick={handleInviteOpen}
              />
            
            </div>
            <UsersFilter
              onFilterClick={handleFilterClcick}
              onResetClick={resetFilters}
            />
            <div className="show-all-users-tablebutton">
              <Card className="route-table-main-container">
                <TableContainer
                  sx={{
                    border: '5px solid #98A8A4',
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                  }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column, index) => (
                          <TableCell
                            key={index}
                            align={column.align}
                            // style={{ backgroundColor: "#dcedc8" }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell>{t('users.actions')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.users.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {columns.map((column, index) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={index} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                            <TableCell
                              className="user-teble-actions-cell"
                              id="actions"
                            >
                              <Tooltip title={t('main.edit')}>
                                <EditIcon
                                  onClick={() => handleUpdateOpen(row)}
                                  className="edit-icon-color-change pointer"
                                />
                              </Tooltip>
                              {row.isEnabled && (
                                <Tooltip title={t('users.disableUser')}>
                                  <PersonOffOutlinedIcon
                                    onClick={() => handleDisableOpen(row)}
                                    className="pointer"
                                  />
                                </Tooltip>
                              )}

                              {row.isEnabled === false && (
                                <Tooltip title={t('users.enableUser')}>
                                  <PersonAddAlt1OutlinedIcon
                                    onClick={() => handleEnableOpen(row)}
                                    className="pointer"
                                  />
                                </Tooltip>
                              )}
                              <Tooltip title={t('users.showProfile')}>
                                <PersonOutlineIcon
                                  className="pointer"
                                  onClick={() => {
                                    navigate(`/UserProfile/${row.id}`);
                                  }}
                                />
                              </Tooltip>
                              <Tooltip title={t('users.resetPassword')}>
                                <LockResetIcon
                                  className="pointer"
                                  onClick={() => handleResetPasswordOpen(row)}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {dialog === 'update' && (
                        <EditUserDialog
                          open={open}
                          onUpdate={hendleUpdate}
                          onClose={handleClose}
                          formData={selectedUser}
                        />
                      )}
                      {dialog === 'reset-password' && (
                        <ResetPasswordDialog
                          open={open}
                          onClose={handleClose}
                          id={selectedUser.id}
                        />
                      )}
                      {dialog === 'disable' && (
                        <ConfirmationDialog
                          open={open}
                          handleAddedUser={hendleUpdate}
                          onClose={handleClose}
                          dialogContent={t('users.actionsDisableDialog')}
                          onNo={handleClose}
                          onYes={handleDisableUser}
                        />
                      )}
                      {dialog === 'enable' && (
                        <ConfirmationDialog
                          open={open}
                          handleAddedUser={hendleUpdate}
                          onClose={handleClose}
                          dialogContent={t('users.actionsEnableDialog')}
                          onNo={handleClose}
                          onYes={handleEnableUser}
                        />
                      )}
                      {dialog === 'add' && (
                        <AddUserDialog
                          open={open}
                          handleAddedUser={handleAddUser}
                          onClose={handleClose}
                        />
                      )}
                      {dialog === 'invite' && (
                        <InviteUser open={open} onClose={handleClose} />
                      )}
                    </TableBody>
                  </Table>
                  <UsePagination
                    onPageChange={handlePageChange}
                    totalPages={users.totalPages}
                    page={filterUsers.pageNumber}
                    reset={resetState}
                  ></UsePagination>
                </TableContainer>
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ShowAllUsersComponent;
