import { useCallback, useEffect, useRef, useState } from "react";

import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import dayjs from 'dayjs';

import ConfirmationDialog from '../Dialog/ConfirmationDialog';

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth,handleOpenDialog ,...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
    // sx={{top:"50%", left:"50%"}}
    sx={{
      '& .MuiBadge-badge': {
       top:"50%",
       right:"50%"     // Adjust shape (circular in this case)
      },
      cursor:"pointer"
    }}
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? '🔒' : undefined}
      onClick={()=>handleOpenDialog(day)}
      
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
}

export default function RestricDates(props) {
  const {restrictedDates,handleEdit} =props;
  const requestAbortController = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [open,setOpen]=useState(false);
  


const handleDateChange =async (newDate) => {
    console.log('Selected Date:', newDate.format('YYYY-MM-DD')); // Log the new date

    const day = newDate.date();

    setHighlightedDays((prevHighlightedDays) => {
        if (prevHighlightedDays.includes(day)) {
          return prevHighlightedDays.filter((d) => d !== day);
        } else {
          return [...prevHighlightedDays, day];
        }
      });
  };

  const checkDate=()=>{
    if (Array.isArray(highlightedDays) && !highlightedDays.includes(selectedDate.date())) {
      return "prohibitBookingForThisDate"
    }
    else return "allowBookingForThisDate"

  }

  const handleOpenDialog=(newDate)=>{
    if(!newDate.isBefore(new Date(), 'day')){
    
    console.log(newDate);
    setSelectedDate(newDate);
    setOpen(true);
    }
  }
  const handleCloseDialog=()=>{
    setOpen(false)
  }
  const handleConfirmation=async ()=>{
    handleDateChange(selectedDate);
    handleEdit(selectedDate.format('YYYY-MM-DD'))

    handleCloseDialog();
  }

  const onDateChanged = useCallback((newDate) => {
    const currentMonth = dayjs(newDate.$d).month();
    const currentYear = dayjs(newDate.$d).year();
    console.log(restrictedDates);
    const filteredDates = restrictedDates.filter(date => {
      const dateMonth = dayjs(date.date).month();
      const dateYear = dayjs(date.date).year();
      return dateMonth === currentMonth && dateYear === currentYear;
    });
  
    const daysInMonth = filteredDates.map(date => dayjs(date.date).date());
  
    // Only update if the days have changed
    if (JSON.stringify(highlightedDays) !== JSON.stringify(daysInMonth)) {
      setHighlightedDays(daysInMonth);
    }
  }, [restrictedDates, highlightedDays]);

  useEffect(()=>{
    console.log(highlightedDays)
  },[highlightedDays]);

  useEffect(() => {
    if (selectedDate && restrictedDates!=[]) {
      const initialDate = dayjs(selectedDate);
      console.log(restrictedDates)
      onDateChanged(initialDate)
    }
  }, [restrictedDates]);

  return (
    <>
    <div className="restrict-date-picker-container">
      

    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
      className="restrict-date-custom-date-picker"
      sx={{
        backgroundColor: "#f7f7f7",
        border: "1px solid #b1b1b1",
        margin: "8px",
        borderRadius: "8px"
      }}
        disablePast
        value={selectedDate}
        defaultValue={selectedDate}
        loading={isLoading}
        // onChange={handleDateChange}
        // onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
            handleOpenDialog
          },
      
        }}
        onMonthChange={(newDate) =>onDateChanged(newDate)}
        onYearChange={(newDate) =>onDateChanged(newDate)}
      />
    </LocalizationProvider>
    </div>

    <ConfirmationDialog
        open={open}
        onClose={handleCloseDialog}
        dialogContent={checkDate()}
        onNo={handleCloseDialog}
        onYes={handleConfirmation}
      />
    </>
  );
}


  // useEffect(()=>{

  // },[])

//   const handleMonthChange = (date) => {
//     if (requestAbortController.current) {
//       // make sure that you are aborting useless requests
//       // because it is possible to switch between months pretty quickly
//       requestAbortController.current.abort();
//     }

//     setIsLoading(true);
//     setHighlightedDays([]);
//     // fetchHighlightedDays(date);
//   };