import { apiSlice } from '../../app/api/apiSlice';

export const supportSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendMessage: builder.mutation({
      query: (body) => ({
        url: `SMS/SendFeedbackFromUser`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useSendMessageMutation } = supportSlice;
