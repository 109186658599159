import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../Dialog/CustomDialog';
import GoogleMapComponent from './GoogleMap';

export default function GoogleMapDialog(props) {
  const { onClose, open, location,onRightClick, onNo, onYes } = props;
  const { t } = useTranslation();
  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);

  const handleClose = () => {
    onClose();
  };
  const handleRightClick = (event) => {
    onRightClick(event)
  };

  return (
    <>
      <CustomDialog
        onOpen={open}
        onClose={handleClose}
        customMargin="0px"
      >
        {open && location  &&(
          <GoogleMapComponent 
            setMap={setMap}
            showMarker={true}
            autoCompleteMarker={{ lat: location.lat, lng: location.long }}
            onRightClick={handleRightClick}
            // disableActions={true}
        
          />
        )}
      </CustomDialog>
    </>
  );
}
// onLeftClick,
// setMap,
// onRightClick,
// showMarker,
// autoCompleteMarker,
// markerReset,
// editMarker,
// disableActions,