import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { useLazyGetSubagentsPerPageQuery } from '../../../features/subagents/SubagentSlice';
import { ReactComponent as EditIcon } from '../../../Icons/edit-01.svg';
import { ReactComponent as PlusIcon } from '../../../Icons/PlusIcon.svg';
import { ReactComponent as TrashIcon } from '../../../Icons/trash-01.svg';
import FilledButton from '../../Button/FilledButton';
import CustomDialog from '../../Dialog/CustomDialog';
import UsePagination from '../../Pagginator/Paginator';
import { useSnackbar } from '../../../store/context/SneckBarProvider';
import CreateSubagentForm from '../SubagentCreateForm/SubagentCreateForm';
import DeleteSubagentForm from '../SubagentDelete/SubagentDeleteForm';
import EditSubagent from '../SubagentEditForm/SubagentEditForm';
import './SubagentTable.css';

export default function SubagentTable() {
  const { t } = useTranslation();
  const [subagentsState, setSubagentsState] = React.useState({});
  const editSubagentState = false;
  const [editNameState, setEditNameState] = React.useState('');
  const [addOpen, setAddOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [subagentId, setSubagentId] = React.useState(null);
  const [selectedSubagent, setSelectedSubagent] = React.useState('');
  const showSnackbar = useSnackbar();
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize] = React.useState(5);
  const resetState= false;
  const [getSubagentsPerPage] = useLazyGetSubagentsPerPageQuery();

  const handleAddOpen = () => {
    setAddOpen(true);
  };
  const handleAddClose = () => {
    setAddOpen(false);
  };

  const fetchSubagents = async () => {
    const params = {
      currentPage: pageNumber,
      pageSize: pageSize,
    };
    const result = await getSubagentsPerPage(params);
    console.log('Res', result);
    setSubagentsState(result.data);
  };

  React.useEffect(() => {
    fetchSubagents();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);
  const handleEditName = (event) => {
    setEditNameState(event.target.value);
  };

  const getSubagents = async () => {
    await fetchSubagents();
    showSnackbar(t('subagent.createdSuccessfully'), 'success');
  };

  const getSubagentsAfterDelete = async (isSuccess) => {
    await fetchSubagents();
    if (isSuccess) {
      showSnackbar(t('subagent.deletedSuccessfully'), 'success');
    }else{
      showSnackbar(t('subagent.unsuccessfullyDeleted'), 'error');      
    }
  };

  const getSubagentsAfterEdit = async () => {
    await fetchSubagents();
    showSnackbar(t('subagent.updatedSuccessfully'), 'success');
  };
  const handleDeleteClose = (id) => {
    setDeleteOpen(false);
  };
  const handleDeleteOpen = (id) => {
    setSubagentId(id);
    setDeleteOpen(true);
  };
  const handleEditClose = (id) => {
    setEditOpen(false);
  };
  const handleEditOpen = (id, name) => {
    setSubagentId(id);
    setSelectedSubagent(name);
    setEditOpen(true);
  };

  return (
    <>
      <div className="subagents-main-container">
        {addOpen && (
          <CustomDialog
            onOpen={addOpen}
            onClose={handleAddClose}
            contentTitle={t('subagent.addNewSubagent')}
          >
            <CreateSubagentForm
              onSuccess={handleAddClose}
              getSubagents={getSubagents}
            ></CreateSubagentForm>
          </CustomDialog>
        )}
        {deleteOpen && (
          <CustomDialog
            onOpen={deleteOpen}
            onClose={handleDeleteClose}
            contentTitle={t('subagent.deleteSubagent')}
          >
            <DeleteSubagentForm
              onSuccess={handleDeleteClose}
              getSubagentsAfterDelete={getSubagentsAfterDelete}
              subagentId={subagentId}
            ></DeleteSubagentForm>
          </CustomDialog>
        )}
        {editOpen && (
          <CustomDialog
            onOpen={editOpen}
            onClose={handleEditClose}
            contentTitle={t('subagent.edit')}
          >
            <EditSubagent
              onSuccess={handleEditClose}
              getSubagentsAfterEdit={getSubagentsAfterEdit}
              subagentId={subagentId}
              selectedSubagent={selectedSubagent}
            ></EditSubagent>
          </CustomDialog>
        )}
        <div className="subagent-header">
          <p className="title-card">{t('subagent.subagents')}</p>

          <FilledButton onClick={handleAddOpen} isValid={true}>
            <PlusIcon />

            {t('subagent.addNewSubagent')}
          </FilledButton>
        </div>
        <br />
        <div className="table-subagent">
          <TableContainer style={{ borderRadius: '1vh' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: 'ButtonShadow' }}>
                    <strong>{t('subagent.name')}</strong>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: 'ButtonShadow',
                      textAlign: 'end',
                    }}
                  >
                    <strong>{t('subagent.actions')}</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="body">
                {subagentsState
                  ? subagentsState?.subagents?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {editSubagentState === index ? (
                            <TextField
                              variant="standard"
                              value={editNameState}
                              onChange={(event) => handleEditName(event)}
                            />
                          ) : (
                            row.title
                          )}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'end' }}>
                          <Tooltip title={t('main.edit')}>
                            <IconButton
                              onClick={(event) =>
                                handleEditOpen(row.id, row.title)
                              }
                              aria-label="edit"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('main.delete')}>
                            <IconButton
                              onClick={(event) => handleDeleteOpen(row.id)}
                              aria-label="delete"
                            >
                              <TrashIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="pagination-bottom">
            <UsePagination
              onPageChange={setPageNumber}
              totalPages={subagentsState?.totalPages}
              page={pageNumber}
              reset={resetState}
            ></UsePagination>
          </div>
        </div>
      </div>
    </>
  );
}
