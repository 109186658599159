import { Box, Button, TextField } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreatePaymentMethodMutation } from '../../../features/paymentmethod/PaymentMethodSlice';

export default function AddPaymentMethodForm({ onSuccess, getSubagents }) {
    const [paymentMethodName, setPaymentMethodName] = useState('');
    const { t, i18n } = useTranslation();
    const [createPaymentMethod, { isLoading, isSuccess, isError, error }] =
        useCreatePaymentMethodMutation();

    const handleInputChange = (event) => {
        setPaymentMethodName(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const newPaymentMethod = { title: paymentMethodName };
            await createPaymentMethod(newPaymentMethod).unwrap();
            if (onSuccess) {
                onSuccess();
            }
            if (getSubagents) {
                getSubagents();
            }
            
        } catch (err) {
            console.error('Failed to create subagent: ', err);
        }
        setPaymentMethodName('');
    };

    return (
        <Box component="form" onSubmit={handleSubmit}>
            <TextField
                id="payment-method-name"
                label={t('paymentMethod.name')}
                variant="outlined"
                value={paymentMethodName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <Button type="submit" className='create-button' color="primary">
                {isLoading
                    ? t('paymentMethod.creating')
                    : t('paymentMethod.createPaymentMethod')}
            </Button>
            {isError && (
                <p>
                    {t('paymentMethod.errorCreating')}: {error.toString()}
                </p>
            )}
         
        </Box>
    );
}
