const validColor = "green";
const invalidColor = "red";

export const textFieldStyles = {
  // "& .MuiFormControl-root": {height:"100%"},
  "& .MuiInputLabel-root": { color: "#667085" },
  "& .MuiInputLabel-root.Mui-focused": { color: "#667085" , fontWeight: 400,fontSize:"1rem" },
  "& .MuiOutlinedInput-root": {
    // height:"100%",
    "& > fieldset": { borderColor: "#D0D5DD"},
    // Outline color
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    "& > fieldset": { borderColor: "#D0D5DD" }, // Label color
    // Outline color when focused
  },
  "& .MuiOutlinedInput-input": { color: "#667085" ,fontWeight: 400,fontSize:"1rem",padding:"8.5px 14px 8.5px 14px"}, // Text color
};

export const validTextField = {
  "& .MuiInputLabel-root": { color: validColor },
  "& .MuiInputLabel-root.Mui-focused": { color: "darkgreen", fontWeight: 400 },
  "& .MuiOutlinedInput-root": {
    // height:"100%",
    "& > fieldset": { borderColor: validColor},
    // Outline color
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    "& > fieldset": { borderColor: "darkgreen" }, // Label color
    // Outline color when focused
  },
  "& .MuiOutlinedInput-input": { color: validColor,fontWeight: 400,fontSize:"1rem",padding:"8.5px 14px 8.5px 14px" }, // Text color
};

export const invalidTextFieldStyle = {
  "& .MuiInputLabel-root": { color: invalidColor },
  "& .MuiInputLabel-root.Mui-focused": { color: "darkRed", fontWeight: 400 },
  "& .MuiOutlinedInput-root": {
    // height:"100%",
    "& > fieldset": { borderColor: invalidColor },
    // Outline invalidColor
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    "& > fieldset": { borderColor: "darkRed" }, // Label color
    // Outline color when focused
  },
  "& .MuiOutlinedInput-input": { color: invalidColor,fontWeight: 400,fontSize:"1rem",padding:"8.5px 14px 8.5px 14px" }, // Text color
};

export const buttonStyle = {
  color: "#83111D",
  // backgroundColor: "#83111D", // Change this to your desired color
  "&:hover": {
    backgroundColor: "#ffe3e6", // Change this to your desired hover color
  },
};
export const formControlStyles = {
  "& .MuiInputLabel-root": {
    color: "#83111D", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#83111D", // Focused label color
    fontWeight: 600, // Bold on focus
  },
  "& .MuiOutlinedInput-root": {
    // height:"100%",
    width: "100%",
    "& > fieldset": {
      borderColor: "#83111D", // Outline color
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    "& > fieldset": {
      borderColor: "#83111D", // Outline color when focused
    },
  },
  "& .MuiOutlinedInput-input": {
    color: "#83111D", // Text color
  },
  "& .MuiSelect-select": {
    color: "#83111D", // Select text color
  },
  "& .MuiSelect-icon": {
    color: "#83111D", // Dropdown icon color
  },
};
export const autoCompleteZIndex = {
  overflowY: "auto",
  maxWidth: "none",
  zIndex: 2,
  "& .MuiPaper-root": {
    overflowY: "visible",
  },
};

export const newTextFieldStyle = {
  fontFamily: "'IBM Plex Sans', sans-serif",
  color: "#101828",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  borderRadius: "4px",
  border: "1px solid #D0D5DD",
  display: "flex",
  gap: "8px",
  padding: "8px 8px 8px 12px",
  maxHeight: "40px",
  boxSizing:"border-box",
  backgroundColor:"transparent",
  ":placeholder": {
    // border: "1px solid #344054",
    color: "red",
  },
};

export const bookingAddressInput = {
  color: "#101828",
  fontSize: "16px",
  fontWeight: 100,
  borderRadius: "4px",
  border: "1px solid #D0D5DD",
  display: "flex",
  gap: "8px",
  padding: "17.5px 14px",
  height:"100%",
  // width:"95%",
  width:"100%",
  boxSizing:"border-box"

}

export const switchStyle = {
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: 'var(--custom-green)',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: 'var(--custom-green)',
  }
};

export const labelStyle = {

    fontFamily: "IBM Plex Sans !important",
    fontSize: "12px !important",
    fontWeight: "500",
    lineHeight: "20px",
    textAlign: "left",
    color:"#344054",
    width:"70px"

}

export const checkboxStyle = {
  color: '#D0D5DD',
  '&.MuiChecked': {
    color: 'var(--custom-green) !important',  
  },
  width: '16px',
  height: '16px',
  gap: '0px',
  borderRadius: '3px 0px 0px 0px',
  opacity: 1, 
  background: 'var(--Base-White, #FFFFFF)',
  border: '1px solid #D0D5DD !important',
  marginTop: '10px'
};


export const textAreaStyle = {
  
}