export const addConnection = async (userId, conn) => {
  if (userId && conn) {
    try {
      await conn.invoke('AddConnection', userId);
      // console.log(`Joinning groupe: ${groupName}`);
    } catch (err) {
      console.error('Error joining group:', err.toString());
    }
  }
};
export const removeConnection = async (userId, conn) => {
  if (userId && conn) {
    console.log("userId")
    try {
      await conn.invoke('RemoveConnection', userId);
    } catch (err) {
      console.error('Error disconnecting group:', err.toString());
    }
  }
};
