import React from 'react';
import { useSearchParams } from 'react-router-dom';
import DeleteProfileConfirm from '../components/DeleteProfile/DeleteProfileConfirm';

const DeleteConfirmPage = () => {
  const [searchParams] = useSearchParams();

  console.log(searchParams.get('userId'));
  return (
    <DeleteProfileConfirm
      userId={searchParams.get('userId')}
      token={encodeURIComponent(encodeURIComponent(searchParams.get('token')))}
    />
  );
};

export default DeleteConfirmPage;
