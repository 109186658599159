import { useTranslation } from 'react-i18next';
import FilledButton from '../Button/FilledButton';

export default function HeaderButtonComponnent(props) {
  const { onClick, isButtonVisible, header } = props;
  const { t } = useTranslation();

  return (
    <div className="show-routes-button-header-container">
      <span className="page-header-container">{t(`headerButton.${header}`)}</span>
      {isButtonVisible && (
        <FilledButton isValid={true} onClick={onClick} type="submit">
          {t('route-list.newRoute')}
        </FilledButton>
      )}
    </div>
  );
}
