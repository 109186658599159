import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateDepartureTimeMutation } from '../../../features/reservation/reservationSlice';
import '../../Subagents/SubagentEditForm/SubagentEditForm';
export default function EditDepartueTime({
  onSuccess,
  getReservationAfterEdit,
  selectedReservation,
  type,
}) {
  const getDepartureTime = () => {
    if (type && selectedReservation?.bookingReturn?.departureTime)
      return dayjs(
        selectedReservation?.bookingReturn?.departureTime,
        'HH:mm:ss'
      );
    if (!type && selectedReservation?.booking?.departureTime)
      return dayjs(selectedReservation?.booking?.departureTime, 'HH:mm:ss');
    return null;
  };

  const [departureTime, setDepartureTime] = useState(getDepartureTime());
  const [errors, setErrors] = useState(null);
  const [t, i18n] = useTranslation();
  const [updateReservation, { isSuccess, isLoading, isError, error }] =
    useUpdateDepartureTimeMutation();

  const handleInputChange = (value) => {
    setDepartureTime(value);
    validate(value);
  };

  const checkDateAndTime = (value) => {
    const currentDate = dayjs().startOf('day');
    const currentTime = dayjs();
    if (value !== null) {
      const valueTime = dayjs(value, 'HH:mm');
      const reservationDate = dayjs(
        type
          ? selectedReservation.bookingReturn.departure
          : selectedReservation.booking.departure
      ).startOf('day');
      const isToday = reservationDate.isSame(currentDate, 'day');
      const isValueInPast = valueTime.isBefore(currentTime, 'minute');
      console.log(currentDate, reservationDate, 'datesss');
      if (isToday && isValueInPast) {
        console.log('Greška: Datum je danas, a vreme je prošlo.');
        return false;
      } else {
        console.log('Datum i vreme su validni.');
        return true;
      }
    }
  };

  const validate = (value) => {
    const errors = {};
    console.log('depp', value);
    if (!checkDateAndTime(value)) {
      errors.departureTime = t('reservations.departureTimeValid');
      setErrors(errors);
    } else {
      setErrors(null);
    }
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    //if (!validate()) return;
    console.log(departureTime);
    try {
      await updateReservation({
        reservationId: selectedReservation.id,
        departureTime:
          departureTime !== null ? departureTime.format('HH:mm:ss') : null,
        isReturnDepartureTime: type,
      }).unwrap();
      if (onSuccess) {
        onSuccess();
      }
      if (getReservationAfterEdit) {
        getReservationAfterEdit();
      }
    } catch (err) {
      console.error('Failed to create subagent: ', err);
    }
    setDepartureTime(null);
  };
  return (
    <Box component="form" onSubmit={handleSubmit}>
      <div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
        <TimePicker
          ampm={false}
          fullWidth
          value={departureTime}
          onChange={handleInputChange}
          renderInput={(params) => <TextField {...params} />}
          //disabled={pastReservation}
        />
        <IconButton
          //disabled={pastReservation}
          onClick={() => {
            setDepartureTime(null);
            setErrors(null);
          }}
          style={{ marginLeft: 8 }}
        >
          <ClearIcon />
        </IconButton>
      </div>
      {errors && <p className="errorText">{errors.departureTime}</p>}
      <Button
        type="submit"
        className="edit-button"
        style={{
          margin: '10px',
          backgroundColor: errors ? 'gray' : '#883C40',
        }}
        disabled={errors}
      >
        {t('subagent.editSubagent')}
      </Button>
    </Box>
  );
}
