import { TimeField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useState } from 'react';

export default function PredefinedTimePicker({routeId,direction,onCreateNewDate }) {
  const [time, setTime] = useState();
  const handleEnterClick = (event) => {
    if (event.key === 'Enter' && time && time.$d!="Invalid Date") {
      console.log(time.$d);
      createNewDate();
    }
  };
  const handleChange = (newValue) => {
    console.log(newValue)
      setTime(newValue);
  };
  const createNewDate=()=>{
    const newDate={
        id:time.format("HH:mm"),
        time:time.format("HH:mm"),
        routeId:routeId,
        direction:direction
    }
    onCreateNewDate(newDate)
  }

  return (
    <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimeField
            // label="Format without meridiem"
            // value={time}
            // defaultValue={time}
            format="HH:mm"
            onKeyDown={handleEnterClick}
            onChange={handleChange}
          />
        </LocalizationProvider>

    </>
  );
}
