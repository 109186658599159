import * as React from "react";
import CustomDialog from "../../Dialog/CustomDialog";
import { DisplayRouteComponent } from "./DisplayRoute";

export default function DisplayRouteDialog(props) {
  const { onClose, open, routeStops, origin, destination } = props;

  return (
    <>
      <CustomDialog onOpen={open} onClose={onClose} customMargin="0px">
        <DisplayRouteComponent
          onClose={onClose}
          routeStops={routeStops}
          origin={origin}
          destination={destination}
        />
      </CustomDialog>
    </>
  );
}
