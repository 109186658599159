import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
import "./CustomTextFields.css";

export default function CustomRoutesAutoComplete(props) {
  const {
    value,
    onChange,
    name,
    onChangeAutoComplete,
    options,
    sx,
    placeholder,
    label,
    noOptionsText
  } = props;

  return (
    <div className='custom-route-title-autocomplete'>
      <label className="custom-label">{label}</label>

      <Autocomplete
          freeSolo
          // disableClearable
        onChange={onChangeAutoComplete}
        onInputChange={onChange}
        inputValue={value}
        id="custom-input-demo"
        options={options || []}
        // value={options?.find(option => option === value) || null}
        isOptionEqualToValue={(option, value) => option === value}
        noOptionsText={null}
        sx={{
          '& .MuiAutocomplete-noOptions': {
            display: 'none',
          },
        }}

        // isOptionEqualToValue={(option, value) => option === value}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input
              style={{ ...sx }}
              name={name}
              type="text"
              placeholder={placeholder}
              {...params.inputProps}
            />
          </div>
        )}
      />
    </div>
  );
}
