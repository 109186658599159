import React from "react";
import { textFieldStyles } from "../../customStyles/customStyles";

import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../Route/AddRouteComponent/AddRouteByAutocomplete.css";

const NameTextField = (props) => {
  const { onNameChange, nameValue, name, label, onValidityChange } = props;
  // const { validateNameField } = useValidation();

  // // const [password,setPassword]=useState("");
  // const [nameValid, setNameValid] = useState(null);
  // const [nameErrors, setNameErrors] = useState(null);
  const { t } = useTranslation();

  let translatedLabel;
  if (label === "First Name") {
    translatedLabel = t("main.firstName");
  } else if (label === "Last Name") {
    translatedLabel = t("main.lastName");
  } else {
    translatedLabel = label;
  }

  // useEffect(() => {
  //   if (nameValue !== "" && nameValue !== undefined) {
  //     getNameValidation(nameValue);
  //     console.log("ovamo beeee");
  //   }
  // }, []);

  // const getNameValidation = (personalName) => {
  //   var validation;
  //   validation = validateNameField(personalName);
  //   setNameValid(validation.isValid);
  //   setNameErrors(validation.errors);
  //   onValidityChange(name, validation.isValid);

  //   console.log(validation);
  // };
  // const handleNameChange = (e) => {
  //   if (e.target.value !== "") {
  //     getNameValidation(e.target.value);
  //   } else {
  //     resetField();
  //   }
  //   onNameChange(e);
  // };
  // const resetField = () => {
  //   setNameErrors(null);
  //   setNameValid(null);
  // };

  return (
    <>
    <div>
      <label className="custom-label">{translatedLabel}</label>
      <TextField
        sx={{
          ...textFieldStyles, 
          // ...(nameValid && validTextField),
          // ...(nameValid === false && invalidTextFieldStyle), 
        }}
        required
        fullWidth
        name={name}
        placeholder={translatedLabel}
        type="text"
        id={name}
        value={nameValue}
        onChange={onNameChange}
      />
      {/* <ValidationErrors errors={nameErrors} /> */}
      </div>
    </>
  );
};

export default NameTextField;
