import * as React from 'react';
import { useEffect, useState,useCallback } from 'react';
import { Box } from '@mui/material';
import SearchableSelect from '../components/Search/SearchableSelect';
import { useLazyGetVehiclesQuery } from '../features/vehicle/VehicleSlice';

export default function VehicleDropwDown({ onChange }) {
  const [getVehicles] = useLazyGetVehiclesQuery();
  const [vehicles, setVehicles] = useState([]);

  const fetchVehicles = useCallback(async () => {
    const response = await getVehicles();
    if (response.data) {
    console.log(response)

      setVehicles(response.data);
    }
  }, [getVehicles]);

  const onVehicleChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    fetchVehicles();
  }, [fetchVehicles]);

  return (
    <Box>
      <SearchableSelect
        setSelected={onVehicleChange}
        items={vehicles}
        itemName={'Vehicle'}
      ></SearchableSelect>
    </Box>
  );
}
