import React from "react";
import { useTranslation } from "react-i18next";
import "./validationErrors.css";

const ValidationErrors = (props) => {
  const { t } = useTranslation();
  const { errors } = props;

  return (
    <span className="validation-error-span">
      {errors && (
        <>
          {errors[0] != undefined && <span>{t(`errors.${errors[0]}`)}</span>}
        </>
      )}
    </span>
  );
};

export default ValidationErrors;
