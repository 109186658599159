import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PassengerRow from './PassengerRow';

export const PassengersTable = (props) => {
  const { passengers,rideId } = props;
  const modifiedPassengers = [...passengers];
  const { t, i18n } = useTranslation();

  const checkIfPassengersAreOnSameReservation = () => {
    modifiedPassengers[0] = {
      ...modifiedPassengers[0],
      fleg: true,
      tip: 'main',
    };
    for (let i = 1; i < modifiedPassengers.length; i++) {
      if (
        modifiedPassengers[i].reservationId ===
        modifiedPassengers[i - 1].reservationId
      ) {
        modifiedPassengers[i] = {
          ...modifiedPassengers[i],
          fleg: modifiedPassengers[i - 1].fleg,
          tip: 'secondary',
        };
      } else {
        modifiedPassengers[i] = {
          ...modifiedPassengers[i],
          fleg: !modifiedPassengers[i - 1].fleg,
          tip: 'main',
        };
      }
    }
  };
// if(passengers)
  checkIfPassengersAreOnSameReservation();

  return (
    <div className="passengers-table-content-container">
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell>{t('rides.booking')}</TableCell>
            <TableCell>{t('rides.passengerName')}</TableCell>
            <TableCell>{t('rides.phoneNumber')}</TableCell>
            <TableCell align="right">{t('rides.departureLocation')}</TableCell>
            <TableCell align="right">{t('rides.arrivalLocation')}</TableCell>
            <TableCell align="right">{t('rides.actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {modifiedPassengers.map((passenger) => (
            <PassengerRow passenger={passenger} rideId={rideId} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
