import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { newTextFieldStyle } from '../../../customStyles/customStyles';
import { useLazyGetRoutesByTitleQuery } from '../../../features/routes/routesSlice';
import CustomDropDown from '../../CustomTextFields/CustomDropDown';
import CustomRoutesAutoComplete from '../../CustomTextFields/CustomRouteTitleAutocomplete';
import CustomTextField from '../../CustomTextFields/CustomTextField';
import InputAutoComplete from '../../GoogleMap/InputAutocomplete';
import './FilterComponent.css';
import FiltersContainer from './FilterContainer';

function debounce(func, wait) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

export default function RouteFillters(props) {
  const { filterRoutes, enableble, resetFilters } = props;
  const { t } = useTranslation();
  const [filterData, setFilterData] = useState({
    startCityName: '',
    endCityName: '',
    startCountryName: '',
    endCountryName: '',
    routeTitle: '',
    isEnabled: enableble[1],
    lowerPrice: 0,
    upperPrice: 0,
  });
  const [resetChange, setResetChange] = useState(true);
  const [getRouteTitles, { data }] = useLazyGetRoutesByTitleQuery();

  const handleInputChange = (e) => {
    console.log(e.target.name);
    const { name, value } = e.target;
    setFilterData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // const filtersReset = () => {
  //   console.log('fasfdsf');
  //   // setResetChange(!resetChange);
  //   resetFilters();
  // };

  const debouncedGetRouteTitles = React.useCallback(
    debounce((newInputValue) => {
      getRouteTitles(newInputValue);
    }, 500),
    []
  );
  // Function to handle new start location
  const handleNewStartLocation = (stop) => {
    console.log(stop);
    setFilterData((prevData) => ({
      ...prevData,
      startCityName: stop.city,
      startCountryName: stop.country,
    }));
  };

  const handleNewEndLocation = (stop) => {
    console.log(stop);
    setFilterData((prevData) => ({
      ...prevData,
      endCityName: stop.city,
      endCountryName: stop.country,
    }));
  };

  const handleChangeTitle = (event, newValue, reson) => {
    if (event !== null) {
      if (reson === 'input') {
        debouncedGetRouteTitles(newValue);
        handleInputChange(event);
      }
      if (reson === 'selectOption') {
        setFilterData((prevData) => ({
          ...prevData,
          ['routeTitle']: newValue,
        }));
      }
    }
  };
  const handleStartAutocompleteChange = (event) => {
    console.log(event.target.value);
    if (event.target.value === '') {
      setFilterData((prevData) => ({
        ...prevData,
        startCityName: '',
        startCountryName: '',
      }));
    }
  };
  const handleEndAutoCompleteChange = (event) => {
    console.log(event.target.value);
    if (event.target.value === '') {
      setFilterData((prevData) => ({
        ...prevData,
        endCityName: '',
        endCountryName: '',
      }));
    }
  };

  const handleResetFilters = () => {
    setFilterData({
      startCityName: '',
      endCityName: '',
      startCountryName: '',
      endCountryName: '',
      routeTitle: '',
      isEnabled: enableble[1],
      lowerPrice: 0,
      upperPrice: 0,
    });
    setResetChange(!resetChange);
    resetFilters();
  };

  return (
    <>
      <FiltersContainer
        onResetClick={handleResetFilters}
        onFilterClcik={() => filterRoutes(filterData)}
        opened={true}

      >
        <div className="route-filters-three-fields-container">
          <InputAutoComplete
            labelText={t('route-list.startLocation')}
            label={true}
            placeholder={t('route-filters.enterLocation')}
            onLocationChange={handleNewStartLocation}
            useForFiltering={true}
            customStyle={newTextFieldStyle}
            reesetChange={resetChange}
            onChange={handleStartAutocompleteChange}

            // name="startCityName"
            // name1="startCountryName"
          />
          <InputAutoComplete
            label={true}
            labelText={t('route-list.endLocation')}
            // name="endCityName"
            // namee="endCountryName"
            onChange={handleEndAutoCompleteChange}
            placeholder={t('route-filters.enterSecondLocation')}
            reesetChange={resetChange}
            onLocationChange={handleNewEndLocation}
            useForFiltering={true}
            customStyle={newTextFieldStyle}
          />
          <CustomRoutesAutoComplete
            name="routeTitle"
            options={data}
            placeholder={t('route-filters.routeTitle')}
            label={t('route-filters.routeTitlePlaceholder')}
            value={filterData.routeTitle}
            onChange={handleChangeTitle}
            onChangeAutoComplete={handleChangeTitle}
            sx={newTextFieldStyle}
          />
        </div>
        <div className="route-filters-three-fields-container">
          <CustomDropDown
            name="isEnabled"
            id="isEnabled"
            value={filterData.isEnabled}
            onChange={handleInputChange}
            label={t('route-filters.routeState')}
            options={enableble}
          />

          <CustomTextField
            name="lowerPrice"
            label={t('route-filters.lowerPrice')}
            type="number"
            placeholder={t('route-filters.lowerPriceValue')}
            sx={newTextFieldStyle}
            value={filterData.lowerPrice === 0 ? '' : filterData.lowerPrice }
            onChange={handleInputChange}
          />
          <CustomTextField
            name="upperPrice"
            label={t('route-filters.upperPrice')}
            type="number"
            placeholder={t('route-filters.upperPriceValue')}
            sx={newTextFieldStyle}
            value={filterData.upperPrice === 0 ? '' : filterData.upperPrice }
            onChange={handleInputChange}
          />
        </div>
      </FiltersContainer>
    </>
  );
}
