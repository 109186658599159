import { Autocomplete, Paper, TextField } from '@mui/material';
import "./RouteAutocomplete.css";
import { useTranslation } from 'react-i18next';

export const RoutesAutocomplete = (props) => {
  const { routeIdState, routes, handleRouteChange, disabled } = props;
  const { t } = useTranslation();

  return (
    <div className='route-autocomplete-cotainer'>
    <Autocomplete
      disablePortal
      defaultValue={''}
      id="route-autocomplete"
      fullWidth
      options={routes.filter((r) => r.isEnabled)}
      value={routes.find((rs) => rs.id === routeIdState) || null}
      getOptionLabel={(option) => option.title}
      getOptionKey={(option) => option.id}
      renderInput={(params) => <TextField {...params} error={!routeIdState} />}
      onChange={(event, value) => handleRouteChange(value)}
      disabled={disabled}
      noOptionsText={t('route-list.noOptionsText')}
      PaperComponent={({ children }) => {
        return <Paper className="cities-dropdown">{children}</Paper>;
      }}
    ></Autocomplete>
    </div>
  );
};
