import { useTranslation } from 'react-i18next';
import AssignedDriver from './AssignedDriver';

export default function AssignedDrivers(props) {
  const { assignedDrivers,onUnassingeDriver } = props;
  const {t}=useTranslation();

  console.log(assignedDrivers)
  return (
    <>
      <div className="assigned-drivers-main-container">
        {assignedDrivers.length > 0 ? (
          assignedDrivers.map((driver) => (
            <AssignedDriver onUnassingeDriver={onUnassingeDriver} key={driver.id} driver={driver} />
          ))
        ) : (
          <p className='no-assigned-drivers-message'>{t("rides.noAssingedDrivers")}</p>
        )}
      </div>
    </>
  );
}
