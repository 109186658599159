import HistoryIcon from '@mui/icons-material/History';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Switch,
  TextField
} from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  checkboxStyle,
  newTextFieldStyle,
  switchStyle,
} from '../../../customStyles/customStyles';
import '../../../customStyles/globalDesignStyles.css';
import { useDeletePassengerMutation } from '../../../features/passangers/passangerApiSlice';
import {
  useAddPassengerMutation,
  useAddReservationWithPassengersMutation,
  useCancelReservationMutation,
  useGetBookingMatchIdMutation,
  useUpdatePassengerMutation,
  useUpdateReservationWithoutPassengersMutation,
} from '../../../features/reservation/reservationSlice';
import {
  useLazyGetDirectionsAndRestrictedDatesQuery,
  useLazyGetRoutesForDropDownQuery
} from '../../../features/routes/routesSlice';
import { useGetRouteStopsPerRouteIdQuery } from '../../../features/routestops/routeStopSlice';
import {
  useLazyGetSubagentsQuery
} from '../../../features/subagents/SubagentSlice';
import { useGetUserByIdQuery } from '../../../features/users/UsersApiSlice';
import { useSnackbar } from '../../../store/context/SneckBarProvider';
import { noAction } from '../../../utils/helperActions/helperActions';
import SwitchIcon from '../../../utils/icons/SwitchIcon';
import {
  emptyBookingState,
  emptyReservationState,
} from '../../../utils/objectStates/emptyObjects';
import ConfirmationDialog from '../../Dialog/ConfirmationDialog';
import PageLoader from '../../PageLoader/PageLoader';
import FiltersContainer from '../../Route/FilterRouteComponent/FilterContainer';
import { RoutesAutocomplete } from '../../Route/RoutesAutocomplete';
import './CreateBookingComponent.css';
import DirectionComponent from './DirectionComponent';
import PassengersComponent from './PassengersComponent';

export function swapRouteName(origString) {
  const index = origString?.indexOf('-');
  if (index === -1) return origString;

  const firstPart = origString?.slice(0, index).trim();
  const lastPart = origString?.slice(index + 1).trim();

  const hasSpaceBefore = origString[index - 1] === ' ';
  const hasSpaceAfter = origString[index + 1] === ' ';
  const labelId = 'route-subagent-label';

  const separator =
    (hasSpaceBefore ? ' ' : '') + '-' + (hasSpaceAfter ? ' ' : '');
  return lastPart + separator + firstPart;
}

export default function CreateBookingComponent({
  openForEdit = false,
  reservationForEdit,
  onRemount
}) {
  const [isSecondPhoneVisible, setIsSecondPhoneVisible] = useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();
  var utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Europe/Berlin');
  const userRole = localStorage.getItem('roles');
  const userId = localStorage.getItem('id');
  const [timeValidations, setTimeValidations] = useState({
    message: '',
    valid: true,
  });
  const [restrictedDates, setRestrictedDates] = useState(undefined);
  const [predefinedTimes, setPredefinedTimes] = useState(undefined);

  const [dateValidation, setDateValidation] = useState('');
  const { data: userForId } = useGetUserByIdQuery(userId);

  const [subagentField, setSubagentField] = useState(false);

  const directionRef = useRef(null);
  const carRentalRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  const [getRoutes, { isLoading }] = useLazyGetRoutesForDropDownQuery();
  const [routes, setRoutes] = useState([]);
  const [getSubagents, { isLoadingSubagents }] = useLazyGetSubagentsQuery();
  const [subagents, setSubagents] = useState([]);
  const [timeError, setTimeError] = useState({ message: '', error: false });

  const [passengersState, setPassengersState] = useState(
    openForEdit ? reservationForEdit.passengers : []
  );
  const [reservationState, setReservationState] = useState(
    emptyReservationState
  );

  const [departureValid, setDepartureValid] = useState(
    openForEdit ? true : false
  );
  const [arrivalValid, setArrivalValid] = useState(openForEdit ? true : false);
  const [carRentalState, setCarRentalState] = useState(
    openForEdit ? reservationForEdit.carRental : null
  );

  const [bookingState, setBookingState] = useState(
    openForEdit ? reservationForEdit.booking : emptyBookingState
  );
  const [validCities, setValidCities] = useState(false);
  const [validDepartureCities, setValidDepartureCities] = useState(false);
  const [getDirections, { data }] =
    useLazyGetDirectionsAndRestrictedDatesQuery();

  const [bookingReturnState, setBookingReturnState] = useState(
    openForEdit
      ? reservationForEdit.bookingReturn
      : {
          ...emptyBookingState,
          direction: false,
        }
  );

  const [routeIdState, setRouteIdState] = useState(
    openForEdit ? reservationForEdit.booking.routeId : ''
  );

  const [routeTitleState, setRouteTitleState] = useState(
    openForEdit
      ? routes.find((r) => {
          return r.id === reservationForEdit.booking.routeId;
        })?.title
      : ''
  );
  const [routeStopsState, setRouteStopsState] = useState([]);
  const [directionResetState, setDirectionResetState] = useState(false);
  const [carRentalBoxState, setCarRentalBoxState] = useState(
    openForEdit && reservationForEdit.carRental !== null ? true : false
  );
  const [title, setTitle] = useState('');
  const [changeRoute, setChangeRoute] = useState(false);
  const [deletePassenger] = useDeletePassengerMutation();

  const [pastReservation, setPastReservation] = useState({
    firstTicket: false,
    secondTicket: false,
  });

  const [expandedFrame, setExpandedFrame] = useState(true);
  const [expandedDirectionFrame, setExpandedDirectionFrame] = useState(true);
  const [expandedDirectionFrameRental, setExpandedDirectionFrameRental] =
    useState(true);
  const [isRoutesLoading, setIsRoutesLoading] = useState(true);
  console.log(reservationForEdit);
  const updateReservationState = useCallback(() => {
    if (reservationForEdit) {
      setReservationState((prev) => ({
        ...reservationForEdit,
        //startStop: reservationForEdit.startStop,
        //endStop: reservationForEdit.endStop,
        endStopId: reservationForEdit.endStop.id,
        startStopId: reservationForEdit.startStop.id,
        paymentMethodId: reservationForEdit.paymentMethodId,
        note: reservationForEdit.note,
        startAddressNote: reservationForEdit.startAddressNote,
        endAddressNote: reservationForEdit.endAddressNote,
        bookingIdReturn: reservationForEdit.bookingIdReturn,
        isReturnTicket: reservationForEdit.isReturnTicket,
        startStopIdReturn: reservationForEdit.startStopIdReturn,
        startStopReturn: reservationForEdit.startStopReturn,
        endStopIdReturn: reservationForEdit.endStopIdReturn,
        endStopReturn: reservationForEdit.endStopReturn,
        paymentMethodIdReturn: reservationForEdit.paymentMethodIdReturn,
      }));
      const departureDate = dayjs(
        reservationForEdit?.booking?.departure
      ).startOf('day');
      const departureDateReturn = dayjs(
        reservationForEdit?.bookingReturn?.departure
      ).startOf('day');
      const currentDate = dayjs().startOf('day');
      setPastReservation({
        firstTicket: departureDate.isBefore(currentDate),
        secondTicket: departureDateReturn.isBefore(currentDate),
      });
    }
  }, [reservationForEdit]);

  useEffect(() => {
    updateReservationState();
    console.log('RESERVATION FOR UPDATE', reservationForEdit);
  }, [updateReservationState]);

  useEffect(() => {
    const fetchSubagents = async () => {
      const response = await getSubagents();
      if (response.data) {
        setSubagents(response.data);
      }
    };
    const fetchRoutes = async () => {
      const response = await getRoutes();
      if (response.data) setRoutes(response.data);
    };

    const fetchData = async () => {
      setIsRoutesLoading(true);
      await Promise.all([fetchSubagents(), fetchRoutes()]);
      setIsRoutesLoading(false);
    };

    fetchData();
  }, []);

  const {
    data: routeStops,
    isFetching: isFetchingStops,
    refetch: refetchStops,
  } = useGetRouteStopsPerRouteIdQuery(routeIdState, {
    skip: !routeIdState,
  });

  useEffect(() => {
    if (routeStops && !isFetchingStops) {
      let locationsWithDistance = routeStops
        .map((rs, index) => {
          const distance = Math.sqrt(
            Math.pow(
              rs.googleLocation.lat - routeStops[0].googleLocation.lat,
              2
            ) +
              Math.pow(
                rs.googleLocation.long - routeStops[0].googleLocation.long,
                2
              )
          );
          return { rs, distance };
        })
        .sort((a, b) => a.distance - b.distance);

      locationsWithDistance.sort((a, b) => a.distance - b.distance);

      setRouteStopsState(
        locationsWithDistance.map((s, id) => ({ ...s.rs, mapId: id }))
      );

      if (openForEdit) {
        if (!changeRoute) {
          setTitle(
            routes.find((r) => {
              return r.id === reservationForEdit.booking.routeId;
            })?.title
          );
          // setRouteTitleState(title);
        }
        // if (!reservationForEdit?.booking?.direction && title != undefined)
        //   setRouteTitleState(swapRouteName(title));
      }
    }

    if (directionRef.current) {
      const offsetTop = directionRef.current.offsetTop;
      window.scrollTo({
        top: offsetTop - 200,
      });
    }
  }, [routeStops, routeIdState, isFetchingStops, title, routes]);

  useEffect(() => {
    if (routes && openForEdit) {
      getDirections(reservationForEdit?.booking?.routeId);
    }
  }, [openForEdit]);

  useEffect(() => {
    setDateError(!areValidDates());
    areValidTimes();
  }, [
    bookingState?.arrivalTime,
    bookingState?.departureTime,
    bookingState?.departure,
    bookingReturnState?.departure,
    bookingReturnState?.arrivalTime,
    bookingReturnState?.departureTime,
  ]);

  const setCarRentalValue = (newValue) => {
    setReservationState((prevState) => ({
      ...prevState,
      carRental: newValue,
    }));
  };

  useEffect(() => {
    if (carRentalBoxState) {
      carRentalRef.current?.scrollIntoView();
      if(openForEdit){
        setCarRentalValue(reservationForEdit.carRental)
      }
    }
    else{
      setCarRentalValue(null);

    }
    console.log(reservationState.carRental);
  }, [carRentalBoxState]);

  const showSnackbar = useSnackbar();
  const handleRouteChange = (event) => {
    setChangeRoute(true);
    const now = dayjs();

    const updatedDepartureWithDate = now
      .hour(now.hour())
      .minute(now.minute())
      .second(now.second());

    const updatedArrivalWithDate = now
      .hour(now.hour())
      .minute(now.minute())
      .second(now.second())
      .add(1, 'hour');
    setBookingState({
      ...bookingState,
      departure: updatedDepartureWithDate,
      arrival: updatedArrivalWithDate,
    });

    if (event !== null) {
      setRouteIdState(event.id);
      getDirections(event.id);
      // setRouteTitleState(event.title);
      setBookingState((prevState) => ({
        ...prevState,
        routeId: event.id,
      }));
      setReservationState((prevState) => ({
        ...prevState,
        bookingId: event.id,
      }));

      setBookingReturnState((prevState) => ({
        ...prevState,
        routeId: event.id,
      }));
    }
  };

  const mapDates = (datesArr) => {
    const dates = datesArr.map((item) => item.date);
    return dates;
  };
  const restrictDates = useMemo(() => {
    if (restrictedDates !== undefined) {
      const departureDates = mapDates(restrictedDates.restrictedDeparture);
      const returnDates = mapDates(restrictedDates.restrictedReturn);

      return {
        restrictOneWay:
          bookingState.direction === true ? departureDates : returnDates,
        restrictReturn:
          bookingState.direction === true ? returnDates : departureDates,
      };
    }
    return { restrictOneWay: [], restrictReturn: [] }; // Fallback if restrictedDates is undefined
  }, [bookingState.direction, restrictedDates]);

  const getPredefinedTimes = useMemo(() => {
    if (predefinedTimes !== undefined) {
      const departureTimes = predefinedTimes.predefinedDeparture;
      const returnTimes = predefinedTimes.predefinedReturn;

      return {
        predefinedOneWay:
          bookingState.direction === true ? departureTimes : returnTimes,
        predefinedReturn:
          bookingState.direction === true ? returnTimes : departureTimes,
      };
    }
    return { predefinedOneWay: [], predefinedReturn: [] }; // Fallback if restrictedDates is undefined
  }, [bookingState.direction, restrictedDates]);

  useEffect(() => {
    if (data) {
      // setRestrictedDates(data.restrictedDates)
      // const dates = data.restrictedDates.map((item) => item.date);
      setRouteTitleState(data.directions.departure.name);
      setRestrictedDates(data.restrictedDates);
      setPredefinedTimes(data.predefinedTimes);
      if (reservationForEdit) {
        if (!reservationForEdit?.booking?.direction) {
          setRouteTitleState(swapRouteName(data.directions.departure.name));
        }
      }
    }
  }, [data]);

  const handleRoundTripChange = async (event) => {
    setReservationState((prevState) => ({
      ...prevState,
      isReturnTicket: !reservationState.isReturnTicket,
    }));
  };

  const handleSubagentChange = (event) => {
    const subagentId =
      event.target.value !== 'No Selection' ? event.target.value : null;

    setSubagentField(true);
    setBookingReturnState({
      ...bookingReturnState,
      subagentId: subagentId,
    });
    setBookingState({
      ...bookingState,
      subagentId: subagentId,
    });
  };
  const clearSubagent = () => {
    const event = {
      target: {
        value: 'No Selection',
      },
    };

    handleSubagentChange(event);
    setSubagentField(false);
  };

  const [addPassenger] = useAddPassengerMutation();
  const handleAddPassenger = async (newPassenger) => {
    if (openForEdit) {
      const response = await addPassenger({
        ...newPassenger,
        reservationId: reservationState.id,
      }).unwrap();
      const newId = response.id;
      let newPassengersArray = passengersState.concat({
        ...newPassenger,
        id: newId,
        reservationId: reservationState.id,
      });
      setPassengersState(newPassengersArray);
    } else {
      let newPassengersArray = passengersState.concat(newPassenger);
      setPassengersState(newPassengersArray);
    }
  };

  const areValidDates = () => {
    let departure = openForEdit
      ? new Date(bookingState.departure)
      : new Date(bookingState.departure);
    let arrival = openForEdit
      ? new Date(bookingReturnState?.departure)
      : new Date(bookingReturnState?.departure);
    let departureDate = new Date(departure);
    let arrivalDate = new Date(arrival);
    departureDate.setHours(0, 0, 0, 0);
    arrivalDate.setHours(0, 0, 0, 0);

    if (areDatesSame(1)) {
      setDateValidation('booking.validationForDate');
      return false;
    } else {
      setDateValidation('');
      return true;
    }
  };

  const areDatesSame = (check) => {
    let departure = openForEdit
      ? new Date(bookingState.departure)
      : new Date(bookingState.departure);
    let arrival = openForEdit
      ? new Date(bookingReturnState?.departure)
      : new Date(bookingReturnState?.departure);
    let departureDate = new Date(departure);
    let arrivalDate = new Date(arrival);
    departureDate.setHours(0, 0, 0, 0);
    arrivalDate.setHours(0, 0, 0, 0);
    if (
      check == 1 &&
      departureDate > arrivalDate &&
      reservationState.isReturnTicket
    ) {
      return true;
    }
    if (
      check == 2 &&
      departureDate.getTime() == arrivalDate.getTime() &&
      reservationState.isReturnTicket
    ) {
      return true;
    }
    return false;
  };

  const areValidTimes = () => {
    let departureTime = bookingState?.departureTime;
    let arrivalTime = bookingState?.arrivalTime;
    let departureTimeReturn = bookingReturnState?.departureTime || null;
    let arrivalTimeReturn = bookingReturnState?.arrivalTime || null;

    const compareTimes = (time1, time2) => {
      if (
        time1 == null ||
        time2 === null ||
        !reservationState.isReturnTicket ||
        !areDatesSame(2)
      )
        return false;

      let [hours1, minutes1] = time1.split(':').map(Number);
      let [hours2, minutes2] = time2.split(':').map(Number);
      if (isNaN(hours1) || isNaN(minutes1) || isNaN(hours2) || isNaN(minutes2))
        return false;
      return hours1 > hours2 || (hours1 === hours2 && minutes1 >= minutes2);
    };
    if (compareTimes(departureTime, arrivalTimeReturn))
      setTimeValidations({
        message: 'booking.timesValidation',
        valid: false,
        position: 'arrivalTimeReturn',
      });
    else if (compareTimes(departureTime, departureTimeReturn))
      setTimeValidations({
        message: 'booking.timesValidation',
        valid: false,
        position: 'departureTimeReturn',
      });
    else if (compareTimes(arrivalTime, departureTimeReturn))
      setTimeValidations({
        message: 'booking.timesValidation',
        valid: false,
        position: 'departureTimeReturn',
      });
    else setTimeValidations({ message: '', valid: true, position: '' });
  };

  const [dateError, setDateError] = useState(
    reservationState.isReturnTicket ? true : false
  );

  useEffect(() => {
    areValidTimes();
    setDateError(!areValidDates());
  }, [
    bookingState?.departureTime,
    bookingState?.departure,
    bookingState?.arrivalTime,
    bookingReturnState?.departure,
    bookingReturnState?.departureTime,
    bookingReturnState?.arrivalTime,
    passengersState,
    // subagent,
  ]);

  useEffect(() => {
  }, [reservationState]);
  const [cancelReservation] = useCancelReservationMutation();
  const handleDeletePassenger = async (index) => {
    const newPassengers = passengersState.filter((_, id) => id !== index);
    showSnackbar(t('passenger_details.deletePass'), 'success');

    setPassengersState(newPassengers);
    if (openForEdit) {
      await deletePassenger(passengersState[index].id).unwrap();
    }
  };

  const [editPassenger] = useUpdatePassengerMutation();
  const handleEditPassenger = (index, editedPassenger, isEditing) => {
    let newPassengers = [...passengersState];
    newPassengers[index] = editedPassenger;
    setPassengersState(newPassengers);

    if (openForEdit && isEditing) {
      editPassenger(editedPassenger);
    }
  };

  const handleSwapDirection = (event) => {
    event.stopPropagation();
    const title = swapRouteName(routeTitleState);
    setRouteTitleState(title);

    setBookingState((prevState) => ({
      ...prevState,
      direction: !prevState?.direction,
    }));

    setBookingReturnState({
      ...bookingReturnState,
      direction: !bookingReturnState?.direction,
    });
  };

  const handleCarRental = (event) => {
    console.log(event.target.value);
    setCarRentalState(event.target.value);
    setReservationState((prevState) => ({
      ...prevState,
      carRental: event.target.value===""?null:event.target.value,
    }));
  };

  const [updateReservation] = useUpdateReservationWithoutPassengersMutation();
  const handleUpdateReservation = async (event) => {
    try {
      var response = await updateReservation({
        ...reservationState,
        booking: {
          ...bookingState,
          departure: dayjs(bookingState.departure),
          arrival: dayjs(bookingState.arrival),
        },
        bookingReturn: {
          ...bookingReturnState, //!= null ?
          departure: dayjs(bookingReturnState?.departure),
          arrival: dayjs(bookingReturnState?.arrival),
          //departure: dayjs(bookingReturnState.departure).add(5, 'day'),//.toISOString(), // Adjust return departure time
          //arrival: dayjs(bookingReturnState.departure).add(5, 'day')//.toISOString(), // Adjust return departure time
        },
        passengers: passengersState,
        bookingId: bookingState?.id,
        bookingIdReturn: bookingReturnState?.id,
      }).unwrap();

      window.location.reload();
      showSnackbar(t('booking.updateSnackbar'), 'success');
    } catch (error) {
      showSnackbar(error?.data?.Message, 'error');
    }
  };

  const [matchOrCreateBooking] = useGetBookingMatchIdMutation();
  const handleFindBookingMatch = async (isDeparture) => {
    try {
      const booking = isDeparture ? bookingState : bookingReturnState;
      const matchingId = await matchOrCreateBooking(booking).unwrap();

      if (isDeparture) {
        setBookingState((prevState) => ({
          ...prevState,
          id: matchingId.data,
        }));
      } else {
        setBookingReturnState((prevState) => ({
          ...prevState,
          id: matchingId.data, // Set the ID for the return trip
        }));
      }
      return matchingId;
    } catch (ex) {
      showSnackbar(t('booking.matchingIdFail'), 'error');
    }
  };

  const [addReservationWithPassengers] =
    useAddReservationWithPassengersMutation();

  const addReservation = async (updatedReservationState) => {
    try {
      const response = await addReservationWithPassengers({
        reservation: updatedReservationState,
        passengers: passengersState,
      }).unwrap();
      resetReservation();
      onRemount();
      showSnackbar(response.message, 'success');
    } catch (ex) {
      showSnackbar(ex.data.Message, 'error');
    }
  };

  const resetReservation = () => {
    setReservationState(emptyReservationState);
    setRouteIdState('');
    setBookingState(emptyBookingState);
    setBookingReturnState({
      ...emptyBookingState,
      direction: false,
    });
    setPassengersState([]);
    setDirectionResetState(true);

    clearSubagent();
  };

  const checkIsBookingCreated = (matchingId, matchingReturnId) => {
    if (!matchingId) {
      return false;
    }
    if (reservationState.isReturnTicket) {
      if (!matchingReturnId) {
        return false;
      }
    }
    return true;
  };
  const handleAddReservation = async (event) => {
    let matchingReturnId;
    let matchingId = await handleFindBookingMatch(true);
    let updatedReservationState = {
      ...reservationState,
      bookingId: matchingId,
      startStop: {
        ...reservationState.startStop,
        routeId: routeIdState,
      },
      endStop: {
        ...reservationState.endStop,
        routeId: routeIdState,
      },
    };

    if (reservationState.isReturnTicket) {
      matchingReturnId = await handleFindBookingMatch(false);

      updatedReservationState = {
        ...updatedReservationState,
        bookingIdReturn: matchingReturnId,
        startStopReturn: {
          ...reservationState.startStopReturn,
          routeId: routeIdState,
        },
        endStopReturn: {
          ...reservationState.endStopReturn,
          routeId: routeIdState,
        },
      };
    }
    if (checkIsBookingCreated(matchingId, matchingReturnId)) {
      await addReservation(updatedReservationState);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCarRentalBoxClick = () => {
    setCarRentalBoxState(!carRentalBoxState);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmation = () => {
    setIsSecondPhoneVisible(false);
    openForEdit ? handleUpdateReservation() : handleAddReservation();
    setOpenDialog(false);
  };

  const navigateToHistory = () => {
    navigate(`/BookingDetails/${reservationForEdit.id}`);
  };

  const handleValidCityChange = (validCity) => {
    setValidCities(validCity);
  };
  const handleValidDepartureCityChange = (validCity) => {
    setValidDepartureCities(validCity);
  };

  const handleSetBookingState = (data) => {
    setBookingState(data);
  };

  return (
    <>
      {(isLoading || isRoutesLoading) && !openForEdit ? (
        <PageLoader />
      ) : (
        <div className="main-create-booking-container">
          <div className="create-booking-content-container">
            <span className="route-heading">
              {openForEdit && t('main.edit')} {t('main.booking')} &nbsp;
              {openForEdit && (
                <Button onClick={navigateToHistory} className="button-history">
                  {' '}
                  <HistoryIcon
                    aria-label="delete"
                    onClick={navigateToHistory}
                    style={{ width: '20px', height: '20px', color: '#883C40' }}
                  />
                  {t('reservations.seeHistory')}
                </Button>
              )}
            </span>
            <PassengersComponent
              passengersState={passengersState}
              setPassengersState={setPassengersState}
              handleDeletePassenger={handleDeletePassenger}
              handleEditPassenger={handleEditPassenger}
              handleAddPassenger={handleAddPassenger}
              //disabled={pastReservation.firstTicket}
              isSecondPhoneVisible={isSecondPhoneVisible}
              setIsSecondPhoneVisible={setIsSecondPhoneVisible}
            ></PassengersComponent>
            <FiltersContainer
              hiddenButtons={true}
              containerName={t('booking.routeData')}
              opened={true}
            >
              <div className="create-reservation-travel-info">
                <div className="create-reservation-routes-autocomplete-container">
                  <label className="custom-label">{t('main.route')}</label>
                  <RoutesAutocomplete
                    routeIdState={routeIdState}
                    routes={routes}
                    handleRouteChange={handleRouteChange}
                    disabled={
                      openForEdit
                        ? true
                        : pastReservation.firstTicket ||
                          pastReservation.secondTicket
                    }
                  ></RoutesAutocomplete>
                  {!routeIdState ? (
                    <FormHelperText error>
                      {t('booking.routeValidation')}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="create-reservation-switch-container">
                  <label
                    className="custom-label"
                    style={{ textAlign: 'center' }}
                  >
                    {t('booking.type')}
                  </label>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          sx={switchStyle}
                          value={reservationState.isReturnTicket}
                          checked={reservationState.isReturnTicket}
                          onChange={handleRoundTripChange}
                        />
                      }
                      disabled={
                        openForEdit && reservationForEdit.isReturnTicket
                      }
                    />
                    <label
                      className="custom-label"
                      style={{ fontSize: '14px' }}
                    >
                      {reservationState.isReturnTicket
                        ? t('main.RT')
                        : t('main.OW')}
                    </label>
                  </div>
                </div>

                <div className="subagent-dropdown-field">
                  <label className="custom-label">Subagent</label>
                  <select
                    id="route-subagent"
                    value={bookingState.subagentId || 'No Selection'}
                    onChange={handleSubagentChange}
                    style={{ ...newTextFieldStyle }}
                    //disabled={pastReservation.firstTicket}
                    disabled={openForEdit}
                  >
                    <option key="No Selection" value="No Selection"></option>
                    {subagents.map((option) => (
                      <option
                        className="select-option-style"
                        key={option.id}
                        value={option.id}
                      >
                        {/* {option} */}

                        {option.title != ''
                          ? option.title
                          : '...Select an option'}
                      </option>
                    ))}
                  </select>
                </div>
                {carRentalBoxState != undefined && (
                  <div className="night-div">
                    <label className="custom-label">
                      {' '}
                      {t('booking.carRental')}
                    </label>
                    <Checkbox
                      checked={carRentalBoxState}
                      onClick={handleCarRentalBoxClick}
                      // disabled={openForEdit}
                      sx={{ ...checkboxStyle }}
                    ></Checkbox>
                  </div>
                )}
              </div>
            </FiltersContainer>

            {routeIdState && (
              <FiltersContainer
                hiddenButtons={true}
                defaultExpanded={true}
                containerName={
                  <>
                    {`${t('booking.direction')}: ${routeTitleState} `}
                    <Button
                      onClick={
                        !pastReservation.firstTicket
                          ? handleSwapDirection
                          : noAction
                      }
                      disabled={pastReservation.firstTicket}
                    >
                      <SwitchIcon className="swap-direction" />
                    </Button>
                  </>
                }
                opened={true}
              >
                <div className="directions">
                  {restrictDates && predefinedTimes && (
                    <DirectionComponent
                      reservation={reservationState}
                      changeReservationState={setReservationState}
                      allRouteStops={routeStopsState}
                      allRoutes={routes}
                      booking={bookingState}
                      changeBookingState={setBookingState}
                      resetState={directionResetState}
                      dateError={dateError}
                      changeValid={setDepartureValid}
                      isReturn={false}
                      openForEdit={openForEdit}
                      refetchStops={refetchStops}
                      routeTitle={routeTitleState}
                      //pastReservation={pastReservation.firstTicket}
                      onValidCityChange={handleValidCityChange}
                      timeValidations={timeValidations}
                      dateValidations={dateValidation}
                      creator={bookingState.creator}
                      restrictedDates={restrictDates.restrictOneWay}
                      predefinedTimes={getPredefinedTimes.predefinedOneWay}
                      defaultPaymentMethod="Bus"
                    ></DirectionComponent>
                  )}
                  {reservationState.isReturnTicket &&
                    restrictDates &&
                    predefinedTimes && (
                      <DirectionComponent
                        creator={bookingState.creator}
                        reservation={reservationState}
                        changeReservationState={setReservationState}
                        allRouteStops={routeStopsState}
                        allRoutes={routes}
                        booking={bookingReturnState}
                        changeBookingState={setBookingReturnState}
                        resetState={directionResetState}
                        dateError={dateError}
                        changeValid={setArrivalValid}
                        isReturn={true}
                        openForEdit={openForEdit}
                        refetchStops={refetchStops}
                        routeTitle={routeTitleState}
                        // pastReservation={pastReservation.secondTicket}
                        onValidCityChange={handleValidDepartureCityChange}
                        timeValidations={timeValidations}
                        dateValidations={dateValidation}
                        restrictedDates={restrictDates.restrictReturn}
                        predefinedTimes={getPredefinedTimes.predefinedReturn}
                        defaultPaymentMethod="Terra"
                      ></DirectionComponent>
                    )}
                </div>
                {/* </di> */}
                {/* </AccordionDetails> */}
              </FiltersContainer>
            )}

            {carRentalBoxState && (
              <FiltersContainer
                containerName={t('booking.carRental')}
                hiddenButtons={true}
                opened={true}
              >
                <div
                  className="car-rental-input-text-container"
                  style={{ width: '100%' }}
                >
                  <label className="custom-label">{t('main.price')} (€)</label>
                  <TextField
                    type="number"
                    // label="ex. 18000"
                    helperText={
                      reservationState.carRental !== 0
                        ? t('booking.rentalValidation')
                        : 'Dodaj cenu za najam vozila'
                    }
                    value={
                      reservationState.carRental != null
                        ? reservationState.carRental
                        : ''
                    }
                    onChange={handleCarRental}
                    fullWidth
                    // disabled={
                    //   pastReservation.firstTicket ||
                    //   pastReservation.secondTicket
                    // }
                  />
                </div>
              </FiltersContainer>
            )}

            <div className="create-reservation-button-container">
              {openForEdit ? (
                <Button
                  className="add-reservation-button"
                  variant="contained"
                  onClick={handleOpenDialog}
                  disabled={
                    !(
                      !dateError &&
                      departureValid &&
                      timeValidations.valid &&
                      // !pastReservation.firstTicket &&
                      // (reservationState.isReturnTicket
                      //   ? arrivalValid && !dateError
                      //   : true) &&
                      (reservationState.isReturnTicket
                        ? arrivalValid && !dateError && validDepartureCities
                        : true) &&
                      passengersState.length !== 0 &&
                      validCities
                    )
                  }

                  //disabled={!(reservationState.isReturnTicket ? validSubmitState : validDeparture)}
                >
                  &nbsp;{t('booking.updateReservation')}
                </Button>
              ) : (
                <Button
                  className="add-reservation-button"
                  variant="contained"
                  onClick={handleOpenDialog}
                  disabled={
                    !(
                      !dateError &&
                      departureValid &&
                      timeValidations.valid &&
                      (reservationState.isReturnTicket
                        ? arrivalValid && !dateError && validDepartureCities
                        : true) &&
                      passengersState.length !== 0 &&
                      validCities
                    )
                  }

                  //disabled={!(reservationState.isReturnTicket ? validSubmitState : validDeparture)}
                >
                  {' '}
                  &nbsp;{t('booking.saveReservation')}
                </Button>
              )}
            </div>
            {openDialog && (
              <ConfirmationDialog
                open={openDialog}
                onClose={handleCloseDialog}
                dialogContent={
                  openForEdit
                    ? t('booking.updateConfirmation')
                    : t('booking.createConfirmation')
                }
                onNo={handleCloseDialog}
                onYes={handleConfirmation}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
