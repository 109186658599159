//TODO SHOULD CHANGE FILE NAME
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SMSStatus = ({ smsData }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const date = new Date(smsData.dateTime);

  const dateFormatter = new Intl.DateTimeFormat('sr-RS', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  // Format the time
  const timeFormatter = new Intl.DateTimeFormat('sr-RS', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
  });
  //   smsData.status=="Sent" || smsData.status== "Delivered"
  const formattedDate = dateFormatter.format(date);
  const formattedTime = timeFormatter.format(date);

  const containerStyle = {
    // height: expand ? 'min-content' : '130px',
    // transition: 'height 0.3s ease',
  };

  const checkStauts = () => {
    if (smsData.status === 'Sent' || smsData.status === 'Delivered') {
      return 'ok';
    } else if (smsData.status === 'Unprocessed sms') {
      return 'warrning';
    } else {
      return 'error';
    }
  };


  const handleDoubleClcik = () => {
    console.log('double');
  };

  const handleIdClick = (reservationId) => {
    const languagePrefix = i18n.language === 'en' ? '/en' : '/rs';
    const url = `${languagePrefix}/EditBooking/${reservationId}`;
    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      navigate(url);
    }
  };
  return (
    <>
      <div
        style={containerStyle}
        className="sms-status-container"
        onDoubleClick={handleDoubleClcik}
      >
        <div className="sms-status-reservaationid-container">
          <div className="sms-status-reservation-text-container">
            <span className="status-number-date-container">
              {smsData.reservationId ? t('sms.reservation') : t('sms.OTPCode')}
            </span>
            <span className="status-number-date-container">:</span>
            {smsData.reservationId ? (
              <span
                className="sms-status-reservation-id"
                onClick={() => handleIdClick(smsData.reservationId)}
              >
                #{smsData.reservationId}
              </span>
            ) : (
              <span className="sms-status-reservation-id">
                {smsData.otpCode}
              </span>
            )}
          </div>
          <div
            className={`sms-status-status ${checkStauts() === 'ok' ? 'sms-status-status-sent' : checkStauts() === 'warrning' ? 'sms-status-status-unproccesed' : 'sms-status-status-not-sent'}`}
          >
            {t(
              `sms.${checkStauts() === 'ok' ? smsData.status : checkStauts() === 'warrning' ? 'unporccesed' : 'Failed'}`
            )}
          </div>
        </div>
        <div className="sms-status-content-container status-number-date-container">
          <div className="sms-status-number">{smsData.phoneNumber}</div>
          <span>
            {formattedDate} &nbsp; {formattedTime}
          </span>
        </div>
      </div>
    </>
  );
};

export default SMSStatus;
