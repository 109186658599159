import { Button } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsVehcileAssigned } from '../../shared/hooks/useVehicleAlreadyAssigned';
import VehicleDropwDown from '../../shared/VehiclesDropDown';
import ActionDialog from '../Dialog/ActionDialog';
import { useChangeVehicle } from './hooks/useChangeVehicle';

export default function ChangeVehicle(props) {
  const { rideId, rideDirection, routeId, departure,onClose } = props;

  const { t } = useTranslation();
  const actionDialogRef = useRef();

  const { isVehicleAssigned, generateMessage, handleIsVehicleAssigned } =
    useIsVehcileAssigned();

  const { handleChangeVehicle } = useChangeVehicle();

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSubmit = async () => {
    await handleChangeVehicle(rideId, selectedItem.id).then(() => {
      handleCloseDialog();
      onClose();
    });
  };

  const onVehicleChange = (event) => {
    if (event) {
      handleIsVehicleAssigned(routeId, rideDirection, departure, event.id);
    }
    setSelectedItem(event);
  };

  const handleCloseDialog = () => {
    if (actionDialogRef.current) {
      actionDialogRef.current.closeDialog(); // Call the child's handleOpen function
    }
  };

  const handleOpenDialog = () => {
    if (actionDialogRef.current) {
      actionDialogRef.current.openDialog(); // Call the child's handleOpen function
    }
  };

  return (
    <div className='change-vehicle-main-container'>
      <VehicleDropwDown onChange={onVehicleChange} />
      <Button
        sx={{ marginTop: '10px' }}
        type="submit"
        className="create-button"
        color="primary"
        disabled={selectedItem === null}
        onClick={handleOpenDialog}
      >
        {t("main.save")}
      </Button>

      <ActionDialog
        ref={actionDialogRef}
        onYes={handleSubmit}
        confirmationDialogContent={'chooseVehicle'}
        condition={isVehicleAssigned?.length > 0}
        warrningDialogContent={generateMessage(
          departure,
          selectedItem?.registrationPlates
        )}
      />
    </div>
  );
}
