import { Drawer } from '@mui/material';
import React from 'react';
import DrawerContent from './DrawerContent';

//TODO SHOULD CHANGE FILE NAME
const DrawerBar = ({ open, onClose }) => {
  // const [open, setOpen] = React.useState(false);
  console.log(open);

  return (
    <>
      <div>
        <Drawer
          className="sms-notification-drawer"
          sx={{
            '&.MuiDrawer-root': {
              zIndex: '100 !important',
            },
            '& .MuiDrawer-paper': {
              // Additional styles if needed
            },
          }}
          anchor="right"
          open={open}
          onClose={onClose}
        >
          <DrawerContent onClcik={onClose} />
        </Drawer>
      </div>
    </>
  );
};

export default DrawerBar;
