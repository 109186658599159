import { Box, Button } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddVehicleToBookingMutation } from '../../../features/vehicle/VehicleSlice';
import VehicleDropwDown from '../../../shared/VehiclesDropDown';
import { useIsVehcileAssigned } from '../../../shared/hooks/useVehicleAlreadyAssigned';
import ActionDialog from '../../Dialog/ActionDialog';
import { useSnackbar } from '../../../store/context/SneckBarProvider';

export default function AddVehicleForm({
  onSuccess,
  reservations,
  rideProperties,
}) {
  const { t } = useTranslation();
  const showSnackbar = useSnackbar();
  const actionDialogRef = useRef();
  const [addVehicleToBooking, { isLoading, isSuccess, isError, error }] =
    useAddVehicleToBookingMutation();

  const { isVehicleAssigned, generateMessage, handleIsVehicleAssigned } =
    useIsVehcileAssigned();

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSubmit = async () => {
    try {
      const addVehicleToBookingObj = {
        vehicle: selectedItem,
        reservations,
        rideProperties,
      };
      await addVehicleToBooking(addVehicleToBookingObj).unwrap();
      if (onSuccess) {
        onSuccess();
        showSnackbar(
          'Vehicle successfully assigned to reservations',
          'success'
        );
      }
      handleCloseDialog();
    } catch (err) {
      console.error('Failed to add vehicle to booking: ', err);
    }
  };

  const onVehicleChange = (event) => {
    if (event) {
      handleIsVehicleAssigned(
        rideProperties.routeId,
        rideProperties.rideDirection,
        rideProperties.departure,
        event.id
      );
    }
    setSelectedItem(event);
  };

  const handleCloseDialog = () => {
    if (actionDialogRef.current) {
      actionDialogRef.current.closeDialog(); // Call the child's handleOpen function
    }
  };

  const handleOpenDialog = () => {
    if (actionDialogRef.current) {
      actionDialogRef.current.openDialog(); // Call the child's handleOpen function
    }
  };

  return (
    <Box>
      <VehicleDropwDown onChange={onVehicleChange} />
      <Button
        sx={{ marginTop: '10px' }}
        type="submit"
        className="create-button"
        color="primary"
        disabled={selectedItem === null}
        onClick={handleOpenDialog}
      >
        {isLoading ? t('customDialog.adding') : t('customDialog.addVehicle')}
      </Button>

      {isError && (
        <p>
          {t('customDialog.errorAdding')}: {error.toString()}
        </p>
      )}
   
      <ActionDialog
        ref={actionDialogRef}
        onYes={handleSubmit}
        confirmationDialogContent={'chooseVehicle'}
        condition={isVehicleAssigned?.length > 0}
        warrningDialogContent={generateMessage(
          rideProperties?.departure,
          selectedItem?.registrationPlates
        )}
      />
    </Box>
  );
}
