import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../store/context/SneckBarProvider';
import { useLazyIsVehicleAssignedForDateQuery } from '../../features/rides/rides';

export const useIsVehcileAssigned = () => {
  const { t } = useTranslation();
  const showSnackbar=useSnackbar();
  const [IsVehicleAssignedForDate, { data: isVehicleAssigned }] =
    useLazyIsVehicleAssignedForDateQuery();

  const handleIsVehicleAssigned = (
    routeId,
    direction,
    departure,
    vehicleId
  ) => {
    if (
      routeId !== null &&
      direction !== null &&
      vehicleId !== null &&
      departure !== null
    )

    console.log("odje",departure)
      IsVehicleAssignedForDate({
        departure: departure,
        vehicleId: vehicleId,
        routeId: routeId,
        direction: direction,
      });
  };

  useEffect(() => {
    if (isVehicleAssigned?.length > 0) {
        showSnackbar(`vehicleAssignedRide`, 'warning');
    }
  }, [isVehicleAssigned]);

  const generateMessage = (departure, registrationPlates) => {
    if (isVehicleAssigned?.length > 0) {
      var message = '';
      message += `${t('generateMessage.forDate')} <b>${moment(departure).format('DD-MM-YYYY')}</b>, ${t('generateMessage.vehicle')} <b>${registrationPlates}</b> ${t('generateMessage.isAlreadyAssigned')}.<br />`;
      isVehicleAssigned.forEach((assignedVehicles) => {
        message += `${t('generateMessage.vehicleHasAScheduledRide')} <b>${assignedVehicles.route}</b>.<br />`;
      });
      message += '<br />';
      return message;
    }
  };

  return {
    isVehicleAssigned,
    generateMessage,
    handleIsVehicleAssigned,
  };
};

