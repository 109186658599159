import { apiSlice } from '../../app/api/apiSlice';

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => 'User/GetUsers',
    }),

    getUsersPerRole: builder.query({
      query: (role) => 'User/GetUsersPerRole?role=' + role,
    }),
    getAllDrivers: builder.query({
      query: () => 'User/GetAllDrivers',
    }),
    getUserById: builder.query({
      query: (id) => 'User/GetUserById?id=' + id,
    }),
    editUser: builder.mutation({
      query: (body) => ({
        url: 'User/EditUser',
        method: 'PATCH',
        body,
      }),
    }),
    enableUser: builder.mutation({
      query: (id) => ({
        url: `User/EnableUser?id=${id}`,
        method: 'PUT',
      }),
    }),
    disableUser: builder.mutation({
      query: (id) => ({
        url: `User/DisableUser?id=${id}`,
        method: 'PUT',
      }),
    }),
    getUsersPerPage: builder.query({
      query: (body) => ({
        url: 'User/GetUsersPerPage',
        method: 'GET',
        params: { ...body },
      }),
    }),
    addSubagentId: builder.mutation({
      query: (body) => ({
        url: 'User/AddSubagentId',
        method: 'PUT',
        body,
      }),
    }),
    deleteUser: builder.mutation({
      query: (email) => ({
        url: `User/RequestAccountDeletion?email=${email}`,
        method: 'POST',
      }),
    }),
    confirmDelete: builder.mutation({
      query: (requestBody) => ({
        url: `User/DeleteUser?userId=${requestBody.userId}&token=${requestBody.token}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useEditUserMutation,
  useGetUsersPerPageQuery,
  useLazyGetUsersPerPageQuery,
  useEnableUserMutation,
  useDisableUserMutation,
  useAddSubagentIdMutation,
  useLazyGetUsersPerRoleQuery,
  useLazyGetAllDriversQuery,
  useDeleteUserMutation,
  useConfirmDeleteMutation,
} = usersApiSlice;
