import { useChangeVehicleMutation } from '../../../features/rides/rides';
import { useSnackbar } from '../../../store/context/SneckBarProvider';

export const useChangeVehicle = () => {
  const showSnackbar = useSnackbar();
  const [changeVehicle] = useChangeVehicleMutation();

  const handleChangeVehicle = async (rideId, vehicleId) => {
    try {
      if (rideId == null || vehicleId == null) {
        return;
      }
      var response = await changeVehicle({
        vehicleId: vehicleId,
        rideId: rideId,
      }).unwrap();

      showSnackbar(response.message, 'success');
    } catch (error) {
      showSnackbar(error?.data?.Message, 'error');
    }
  };

  return {
    handleChangeVehicle,
  };
};
