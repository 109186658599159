import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useEditRestrictedDateMutation
} from '../../features/restrictedDates/restrictedDatesSlice';
import { useLazyGetDirectionsAndRestrictedDatesQuery } from '../../features/routes/routesSlice';
import DirectionFormControll from '../CustoFormControl/DirectionFormControll';
import CustomDialog from '../Dialog/CustomDialog';
import { useSnackbar } from '../../store/context/SneckBarProvider';
import "./Calendar.css";
import RestricDates from './RestrictDates';

export default function RestrictDatesDialog(props) {
  //   const fileName = "add-route-auto-complete";
  const { onClose, open, routeId } = props;
  const [getRestricteDates, { data }] =
    useLazyGetDirectionsAndRestrictedDatesQuery();
  const [editRestrictedDate] = useEditRestrictedDateMutation();
  const [restrictDates, setRestrictedDates] = useState();
  const [directions, setDirections] = useState();
  const { t } = useTranslation();
  const showSnacbar = useSnackbar();
  const [selectedDirection, setSelectedDirection] = useState('');

  console.log(routeId);
  useEffect(() => {
    getRestricteDates(routeId)
      .then((res) => {
        console.log(res); // Log the resolved value
        setRestrictedDates(res.data.restrictedDates); // Set the restricted dates
        console.log(res.data.direction);
        console.log(res.data.restrictedDates)
        const directions = res.data.directions;
        setDirections([
          {
            direction: directions.departure.direction,
            name: directions.departure.name,
          },
          {
            direction: directions.return.direction,
            name: directions.return.name,
          },
        ]);
      })
      .catch((error) => {
        console.error('Error fetching restricted dates:', error); // Handle any errors
      });
  }, [routeId]);

  useEffect(() => {
    if (directions) {
      console.log(directions);

      setSelectedDirection(directions[0].direction);
    }
  }, [directions]);

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  const hanldeEditRestrictedDate = async (date) => {
    try {
      var response = await editRestrictedDate({
        routeId,
        dateOnly: date,
        direction:selectedDirection
      }).unwrap();
      setRestrictedDates(response.restrictedDates);
      // onEdit();
      showSnacbar(response?.message, 'success');

      getRestricteDates(routeId);
    } catch (error) {
      showSnacbar(error?.data?.Message, 'error');
      console.log(error?.data?.Message);
    }
  };
  const handleChange = (event) => {
    console.log(event.target.value)
    setSelectedDirection(event.target.value);
  };
  return (
    <>
      {data && restrictDates && (
        <CustomDialog onOpen={open} onClose={onClose} customMargin="0px">
          <div className='restrit-date-header-container'>
            {t("restrictDate.header")}
            <ClearIcon style={{cursor:"pointer"}} onClick={onClose} />
          </div>
          <div className='restricted-date-dialog-main-container'>

            <div className='dropdown-restricted-data-container'>
            {selectedDirection && (
              <DirectionFormControll 
                value={selectedDirection}
                onChange={handleChange}
                directions={directions}
              />

            )}
            </div>
          <RestricDates
            restrictedDates={selectedDirection=="departure"?restrictDates.restrictedDeparture : restrictDates.restrictedReturn}
            onClose={onClose}
            routeId={routeId}
            handleEdit={hanldeEditRestrictedDate}
            
          />
          </div>

          {/* </div> */}
        </CustomDialog>
      )}
    </>
  );
}
