import React, { useEffect, useState } from 'react';
import { useDeleteUserMutation } from '../../features/users/UsersApiSlice';
import './DeleteProfile.css';
import { useSnackbar } from '../../store/context/SneckBarProvider';
import { useTranslation } from 'react-i18next';
import PageLoader from '../PageLoader/PageLoader';

const DeleteProfile = () => {
  const showSnackbar = useSnackbar();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState({ message: '', isError: false });

  const { t, i18n } = useTranslation();
  const [languageState, setLanguageState] = React.useState('srpski');

  const [deleteUser, { isLoading }] = useDeleteUserMutation();

  const changeEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value.trim() === '')
      setEmailError({ message: t('deleteProfile.emailEmpty'), isError: true });
    else setEmailError({ message: '', isError: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(email)) {
        setEmailError({
          message: t('deleteProfile.validEmail'),
          isError: true,
        });
        return;
      }
      setEmailError({ message: '', isError: false });
      await deleteUser(email).unwrap();
      setEmail('');
      showSnackbar(t('deleteProfile.success'), 'success');
    } catch (error) {
      showSnackbar(error?.data?.Message, 'error');
    }
  };

  useEffect(() => {
    document.body.classList.add('login-page');
    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  const hangleChangeLanguage = () => {
    const currentLanguage = i18n.language;
    console.log(currentLanguage);

    if (languageState === 'srpski') {
      setLanguageState('english');
    } else {
      setLanguageState('srpski');
    }

    const newLanguage = i18n.language === 'en' ? 'rs' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const content = isLoading ? (
    <PageLoader />
  ) : (
    <div className="my-container">
      <div className="my-right">
        <div className="my-header-language">
          {' '}
          <span onClick={hangleChangeLanguage}>
            {i18n.language === 'rs' ? 'SRB' : 'ENG'}
          </span>
        </div>
        <div className="my-login-form">
          <img
            src="/assets/terra-logo.jpeg"
            alt="logo"
            width="40"
            height="40"
          />
          <h2>{t('deleteProfile.deleteTitle')}</h2>
          <form className="my-form-style" onKeyDown={handleKeyDown}>
            <p>{t('deleteProfile.deleteMessage')}</p>

            <label className="my-text-label" htmlFor="username">
              {t('deleteProfile.email')}:
            </label>
            <input
              className={`my-input-text ${emailError.isError ? 'my-input-error' : ''}`}
              autoComplete="false"
              onChange={(e) => changeEmail(e)}
              value={email}
              placeholder={t('deleteProfile.enterEmail')}
              type="text"
              id="username"
              name="username"
              required
            />
            {emailError.isError && (
              <div className="my-helper-text">{emailError.message}</div>
            )}
            <p style={{ marginBottom: 40 }}>{t('deleteProfile.info')}</p>
            <button
              className={`my-log-button ${email === '' ? 'btn-disabled' : ''}`}
              onClick={handleSubmit}
              type="submit"
              disabled={email === ''}
            >
              {t('deleteProfile.send')}
            </button>
          </form>
        </div>
        <footer className="my-footer-text">
          <p>www.terratravel.rs</p>
        </footer>
      </div>
    </div>
  );
  return content;
};

export default DeleteProfile;
