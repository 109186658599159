import './PredefinedTime.css';
import PredefinedTimeBadge from './PredefinedTimeBadge';
import { useTranslation } from 'react-i18next';

export default function PredefinedTimes({
  predefinedTimes,
  onRemovePredefinedTime,
}) {
const { t } = useTranslation();
  return (
    <>
      {predefinedTimes && (
        <>
          {predefinedTimes?.length > 0 ? (
            <div className="predefined-times-main-container">
              {predefinedTimes.map((time, index) => (
                <PredefinedTimeBadge
                  key={index}
                  time={time}
                  onRemovePredefinedTime={onRemovePredefinedTime}
                />
              ))}
            </div>
          ) : (
            <div className="predefined-times-message-container">
              <span>{t("predefinedTimes.noPredefinetTimes")}</span>
            </div>
          )}
          
        </>
      )}
    </>
  );
}
