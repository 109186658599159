import * as React from "react";
import CustomDialog from "../Dialog/CustomDialog.js";
import { AddCustomLocation } from "./AddCustomLocation.jsx";

export default function AddCustomLocationDialog(props) {
  const { onClose, open,onUpdateCustomLocationTable } = props;

  return (
    <>
      <CustomDialog onOpen={open} onClose={onClose} 
        customMargin="0px"
        >
        <AddCustomLocation
          onUpdateCustomLocationTable={onUpdateCustomLocationTable}
          onClose={onClose}
        ></AddCustomLocation>
      </CustomDialog>
    </>
  );
}
