import ClearIcon from '@mui/icons-material/Clear';
import { Checkbox, IconButton, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  checkboxStyle,
  newTextFieldStyle,
} from '../../../customStyles/customStyles';
import { useLazyGetPaymentMethodsQuery } from '../../../features/paymentmethod/PaymentMethodSlice';
import { useValidation } from '../../../store/context/ValidationProvider';
import CalendarIcon from '../../../utils/icons/CalendarIcon';
import InputAutoComplete from '../../GoogleMap/InputAutocomplete';
//import AdapterDayjs from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CustomTextField from '../../CustomTextFields/CustomTextField';
import PredefinedTimesMenu from '../../PredefinedTime/PredefinedTimesMenu';

// export const getNextFullHour = () => {
//   const now = dayjs();
//   const nexthour = now.add(1, 'hour').startOf('hour');
//   console.log(nexthour);
//   return nexthour;
// };
// export const getTwoNextFullHour = () => {
//   const now = dayjs();
//   const nexthours = now.add(2, 'hour').startOf('hour');
//   console.log(nexthours);
//   return nexthours;
// };

// export const getNNextFullHour = (hour) => {
//   const now = dayjs();
//   const nexthours = now.add(hour, 'hour').startOf('hour');
//   console.log(nexthours);
//   return nexthours;
// };
// const predefinedTimes = [
//   dayjs().hour(16).minute(0),
//   dayjs().hour(17).minute(5),
//   dayjs().hour(18).minute(0),
//   // Add more predefined times here
// ];
export default function DirectionComponent({
  reservation,
  changeReservationState,
  allRouteStops,
  booking,
  changeBookingState,
  resetState,
  dateError,
  changeValid,
  isReturn,
  openForEdit,
  refetchStops,
  routeTitle,
  pastReservation,
  onValidCityChange,
  timeValidations,
  dateValidations,
  creator,
  restrictedDates,
  predefinedTimes,
  defaultPaymentMethod
}) {
  const { validateAutocompleteField } = useValidation();
  const { t } = useTranslation();
  var utc = require('dayjs/plugin/utc');
  //dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Europe/Berlin');

  const [startStopState, setStartStopState] = useState('');
  const [startCityState, setStartCityState] = useState('');
  const [startNoteState, setStartNoteState] = useState('');
  const [endStopState, setEndStopState] = useState('');
  const [endCityState, setEndCityState] = useState('');
  const [endNoteState, setEndNoteState] = useState('');
  const [eveningDepartureState, setEveningDepartureState] = useState(false);
  const [paymentMethodState, setPaymentMethodState] = useState('');
  const [noteState, setNoteState] = useState('');
  const [startOrderState, setStartOrderState] = useState('');
  const [endOrderState, setEndOrderState] = useState('');
  const [validAddres, setValidAddress] = useState(true);
  const [mapDialogOpen, setMapDialogOpen] = useState(false);
  const [manualType, setManualType] = useState(false);

  const [startLocationValid, setStartLocationValid] = useState(null);
  const [endLocationValid, setEndLocationValid] = useState(null);
  const [startLocationErrors, setStartLocationErrors] = useState(null);
  const [endLocationErrors, setEndLocationErrors] = useState(null);
  const [validTime, setValidTime] = useState({ message: '', valid: true });
  const [getPaymentsMethods, { isLoadingPaymentMethods }] =
    useLazyGetPaymentMethodsQuery();
  const [paymentMethods, setPaymentsMethod] = useState([]);

  // useEffect(()=>{
  //   console.log
  // },[booking])

  const getDefaultDate = () => {
    let currentDate = dayjs().format('YYYY-MM-DD');
    let dayjsCurrentDate = dayjs();
    console.log(booking);
    console.log(restrictedDates);
    if (openForEdit && booking !== null) {
      if(new Date(booking.departure).toLocaleDateString()==="1/1/1"){
        return null
      }
      return dayjs(new Date(booking.departure));
    }
    else if(defaultPaymentMethod==="Terra"){
      return null;
    }  
    else {
      if (restrictedDates.includes(currentDate)) {
        while (
          restrictedDates.includes(dayjsCurrentDate.format('YYYY-MM-DD'))
        ) {
          dayjsCurrentDate = dayjsCurrentDate.add(1, 'day'); // Move to the next day
          console.log('Checking next date:', dayjsCurrentDate);
        }
        console.log(Date.now());
        console.log(dayjsCurrentDate);
        return dayjs(dayjsCurrentDate);
      }
      return dayjs(Date.now());
    }
  };
  const getDefaultTime = () => {
    if (openForEdit) {
      console.log(departureTimeFromBackend);
      // if (departureTimeFromBackend && departureTimeFromBackend===null) {
        return departureTimeFromBackend;
      // }
    }
    if (predefinedTimes.length !== 0) {
      const currentTime = dayjs();
      console.log(currentTime);
      const futureTimes = predefinedTimes.map((timeObj) => ({
        original: timeObj, // Keep the original object
        parsedTime: dayjs(timeObj.time, 'HH:mm'), // Parse the time
      }));

      // Filter and find the first time after the current time
      const firstFutureTimeObj = futureTimes
        .filter(({ parsedTime }) => parsedTime.isAfter(currentTime))
        .sort((a, b) => a.parsedTime - b.parsedTime)[0];

      // const selectedTime = predefinedTimes
      // .filter((time) => time===firstTimeAfterCurrent.format("HH:mm"))
      // .sort((a, b) => a - b)[0];

      console.log(firstFutureTimeObj);
      // const time = dayjs(timeString, 'HH:mm');
      if (firstFutureTimeObj) {
        return firstFutureTimeObj.parsedTime;
      }
    }
    return null;
  };

  function datetimeReducer(state, action) {
    console.log(state);
    console.log(action);
    switch (action.type) {
      case 'departure-time':
        console.log(action.payload);
        const newDepartureTime = dayjs(action.payload);

        const formattedDepartureTime = newDepartureTime.format('HH:mm:ss');

        console.log(formattedDepartureTime);

        changeBookingState({
          ...booking,
          departureTime: formattedDepartureTime, // Set the formatted time string
        });

        return {
          ...state,
          departureTime: newDepartureTime, //newDepartureTime // Keep dayjs object in state for other purposes
        };

      case 'arrival-time':
        const newArrivalTime = dayjs(action.payload);

        const formattedArrivalTime = newArrivalTime.format('HH:mm:ss');

        changeBookingState({
          ...booking,
          arrivalTime: formattedArrivalTime, // Set the formatted time string
        });

        return {
          ...state,
          arrivalTime: newArrivalTime, // Keep dayjs object in state for other purposes
        };

      case 'clear-departure-time':
        changeBookingState({
          ...booking,
          departureTime: null,
        });

        return {
          ...state,
          departureTime: null,
        };

      case 'clear-arrival-time':
        changeBookingState({
          ...booking,
          arrivalTime: null,
        });

        return {
          ...state,
          arrivalTime: null,
        };
      case 'date':
        console.log(action.payload);
        const newDate = dayjs(action.payload); // datum iz action.payload
        const departureTime = dayjs(new Date(booking?.departure));
        const arrivalTime = dayjs(new Date(booking?.arrival));
        console.log(newDate);
        const updatedDepartureWithDate = newDate;
        console.log(updatedDepartureWithDate);

        /*.hour(departureTime.hour())
        .minute(departureTime.minute())
        .second(departureTime.second());*/

        const updatedArrivalWithDate = newDate;
        console.log(updatedArrivalWithDate);

        /*.hour(arrivalTime.hour())
        .minute(arrivalTime.minute())
        .second(arrivalTime.second());*/

        changeBookingState({
          ...booking,
          departure: newDate,
          arrival: updatedArrivalWithDate,
        });

        return {
          ...state,
          departureDate: updatedDepartureWithDate,
          arrivalDate: updatedArrivalWithDate,
        };

      case 'evening-departure':
        changeBookingState({
          ...booking,
          arrival: dayjs(state.departureDate).add(1, 'd'),
        });
        console.log(booking);

        return {
          ...state,
          arrivalDate: dayjs(state.departureDate).add(1, 'd'),
        };

      case 'set-datetime':
        // changeBookingState({
        //   ...booking,
        //   arrival: dayjs(state.departureDate).add(1, 'd'),
        // });
        console.log(action.payload.date);
        const newDepartureTime1 =action.payload.time ?  dayjs(action.payload.time):null;
        const newDate1 = dayjs(action.payload.date);

        const formattedDepartureTime1 = newDepartureTime1;
        changeBookingState({
          ...booking,
          departure: newDate1,
          departureTime:newDepartureTime1? formattedDepartureTime1.format('HH:mm:ss'):null,
          arrival: newDate1,
        });

        return {
          ...state,
          departureDate: newDate1,
          departureTime: formattedDepartureTime1,
          // arrivalTime:newDate1
        };

      default:
        return state;
    }
  }

  const departureTimeFromBackend =
    booking?.departureTime != null
      ? dayjs(`2024-08-05T${booking?.departureTime}`, 'YYYY-MM-DDTHH:mm:ss')
      : null;
  const arrivalTimeFromBackend =
    booking?.arrivalTime != null
      ? dayjs(`2024-08-05T${booking?.arrivalTime}`, 'YYYY-MM-DDTHH:mm:ss')
      : null;

  const [datetimeState, datetimeUpdate] = useReducer(datetimeReducer, {
    departureDate: getDefaultDate(),
    departureTime: getDefaultTime(), //dayjs(null), //null, // dayjs(null),//dayjs(Date.now()),
    arrivalTime: openForEdit ? arrivalTimeFromBackend : null,
  });

  // useEffect(() => {
  //   if (!openForEdit) {
  //     changeBookingState({
  //       ...booking,
  //       departureTime: !isReturn
  //         ? getNextFullHour().format('HH:mm:ss')
  //         : getNNextFullHour(3).format('HH:mm:ss'),
  //       arrivalTime: null,
  //     });
  //   }
  // }, []);
  useEffect(() => {
    console.log('switch change reservation: ', datetimeState);

    const fetchData = async () => {
      if (openForEdit && reservation && booking) {
        const getStartCity = async () => {
          return allRouteStops.find((rs) =>
            isReturn
              ? rs.googleLocation.city ===
                reservation.startStopReturn?.location?.city
              : rs.googleLocation.city === reservation.startStop?.location?.city
          );
        };

        const getEndCity = async () => {
          return allRouteStops.find((rs) =>
            isReturn
              ? rs.googleLocation.city ===
                reservation.endStopReturn?.location?.city
              : rs.googleLocation.city === reservation.endStop?.location?.city
          );
        };

        const startStopCity = await getStartCity();
        const endStopCity = await getEndCity();

        setStartStopState(
          isReturn
            ? reservation.startStopReturn?.location
            : reservation.startStop?.location || ''
        );
        setEndStopState(
          isReturn
            ? reservation.endStopReturn?.location
            : reservation.endStop?.location || ''
        );
        setStartCityState(
          isReturn
            ? reservation.startStopReturn?.location?.city
            : reservation.startStop?.location?.city || ''
        );
        setEndCityState(
          isReturn
            ? reservation.endStopReturn?.location?.city
            : reservation.endStop?.location?.city || ''
        );
        setStartNoteState(
          isReturn
            ? reservation.startAddressNoteReturn
            : reservation.startAddressNote || ''
        );
        setEndNoteState(
          isReturn
            ? reservation.endAddressNoteReturn
            : reservation.endAddressNote || ''
        );
        setEveningDepartureState(booking?.isEveningDeparture || false);
        setPaymentMethodState(
          isReturn
            ? reservation.paymentMethodIdReturn
            : reservation.paymentMethodId || ''
        );
        setNoteState(
          isReturn ? reservation.noteReturn : reservation.note || ''
        );
        setStartOrderState(startStopCity ? startStopCity.mapId : '');
        setEndOrderState(endStopCity ? endStopCity.mapId : '');
      }
    };
    console.log('arrrr', datetimeState.arrivalTime);
    fetchData();
  }, [openForEdit, allRouteStops, isReturn]);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const response = await getPaymentsMethods();
      if (response.data) setPaymentsMethod(response.data);
    };
    fetchPaymentMethods();
  }, [getPaymentsMethods]);

  useEffect(() => {
    if (resetState) {
      setStartStopState('');
      setStartCityState('');
      setStartNoteState('');
      setEndStopState('');
      setEndCityState('');
      setEndNoteState('');
      setEveningDepartureState(false);
      setPaymentMethodState('');
      setNoteState('');
    }
  }, [resetState]);

  useEffect(() => {
    if (isReturn) {
      if (reservation.startStop) {
        setEndStopState(reservation.startStop || '');
        if (reservation.startStop?.city && reservation.startStop?.country)
          setEndCityState(
            reservation.startStop?.city +
              ', ' +
              reservation.startStop?.country || ''
          );
      }
      if (reservation.endStop) {
        setStartStopState(reservation.endStop || '');
        if (reservation.endStop?.city && reservation.endStop?.country) {
          setStartCityState(
            reservation.endStop?.city + ', ' + reservation.endStop?.country ||
              ''
          );
        }
      }
    }
    console.log('ARRRRETUEMN', datetimeState.departureTime);
  }, [reservation.startStop, reservation.endStop, isReturn]);

  const handleNoteChange = (event) => {
    console.log('reservationSTATE', reservation);
    setNoteState(event.target.value);
    changeReservationState((prevState) => ({
      ...prevState,
      ...(isReturn
        ? { noteReturn: event.target.value }
        : { note: event.target.value }),
    }));
  };

  const handleStartStopChange = (event) => {
    console.log(event);
    if (event !== null) {
      console.log('STARTSTOP');
      setStartStopState(event);
      setStartCityState(
        event.city != 'Undefined city'
          ? event.city + ', ' + event.country
          : event.country
      );
      if (!openForEdit || manualType) {
        changeReservationState((prevState) => ({
          ...prevState,
          ...(isReturn
            ? { startStopReturn: event }
            : { startStop: event, endStopReturn: event }),
        }));
      }
      getStartLocationValidation(event);
    }
  };

  const handleEndStopChange = (event) => {
    console.log('rewrwe');
    if (event !== null) {
      console.log('STARTSTOP', event);
      setEndStopState(event);
      setEndCityState(
        event.city != 'Undefined city'
          ? event.city + ', ' + event.country
          : event.country
      );
      if (!openForEdit || manualType) {
        changeReservationState((prevState) => ({
          ...prevState,
          ...(isReturn
            ? { endStopReturn: event }
            : { endStop: event, startStopReturn: event }),
        }));
      }

      getEndLocationValidation(event);
    }
  };

  const handleStartNoteChange = (event) => {
    setStartNoteState(event.target.value);
    changeReservationState((prevState) => ({
      ...prevState,
      ...(isReturn
        ? { startAddressNoteReturn: event.target.value }
        : { startAddressNote: event.target.value }),
    }));
  };

  const handleEndNoteChange = (event) => {
    setEndNoteState(event.target.value);
    changeReservationState((prevState) => ({
      ...prevState,
      ...(isReturn
        ? { endAddressNoteReturn: event.target.value }
        : { endAddressNote: event.target.value }),
    }));
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethodState(event.target.value);
    console.log(event.target.value);
    changeReservationState((prevState) => ({
      ...prevState,
      ...(isReturn
        ? { paymentMethodIdReturn: event.target.value }
        : { paymentMethodId: event.target.value }),
    }));
  };

  const findPaymentMethod = (name) => {
    return paymentMethods.find((method) => method.title === name);
};

useEffect(() => {
  if (!openForEdit) {
    if (paymentMethods.length > 0) {
      const paymentMethod=findPaymentMethod(defaultPaymentMethod)
      const paymentMethodId=paymentMethod ? paymentMethod.id:paymentMethods[0].id
      setPaymentMethodState(paymentMethodId);
      changeReservationState((prevState) => ({
        ...prevState,
        ...(isReturn
          ? { paymentMethodIdReturn: paymentMethodId }
          : { paymentMethodId: paymentMethodId }),
      }));
    }
  }
}, [paymentMethods]);

  const handleEveningDepartureChange = () => {
    setEveningDepartureState(!eveningDepartureState);
    //datetimeUpdate({type: 'evening-departure'})
    changeBookingState({
      ...booking,
      isEveningDeparture: !eveningDepartureState,
    });
  };

  const handleAddRouteStop = () => {
    refetchStops();
  };

  function areDifferentStops() {
    return startCityState !== endCityState;
  }

  function areValidTimes() {
    const dayjs = require('dayjs');

    // Function to normalize time to a common date
    const normalizeTime = (time) =>
      dayjs()
        .set('hour', time.hour())
        .set('minute', time.minute())
        .set('second', 0)
        .set('millisecond', 0);

    console.log('datetimeState.departureTime', datetimeState.departureTime);
    console.log('datetimeState.arrivalTime', datetimeState?.arrivalTime);

    const normalizedDepartureTime = datetimeState.departureTime
      ? normalizeTime(datetimeState.departureTime)
      : null;
    const normalizedArrivalTime = datetimeState.arrivalTime
      ? normalizeTime(datetimeState.arrivalTime)
      : null;

    console.log('normalizedDepartureTime', normalizedDepartureTime);
    console.log('normalizedArrivalTime', normalizedArrivalTime);

    const isBefore =
      normalizedDepartureTime && normalizedArrivalTime
        ? normalizedDepartureTime.isBefore(normalizedArrivalTime)
        : null;

    return isBefore;
  }

  function isPastDate(time) {
    if (time == null) return false;
    if (!openForEdit) {
      console.log('timetochekc', datetimeState.departureDate);
      const departureDate = dayjs(datetimeState?.departureDate).startOf('day');
      const timeToCheck = departureDate
        .set('hour', time.hour())
        .set('minute', time.minute());
      console.log('timetochekc', departureDate);
      return timeToCheck.isBefore(dayjs());
    } else return false;
  }

  function areOrderedStops() {
    if (startCityState !== '' && endCityState !== '') {
      if (startOrderState < endOrderState === booking.direction) return true;
      else return false;
    }
    return true;
  }

  function areValidStops() {
    return (
      areDifferentStops() &&
      startCityState !== '' &&
      endCityState !== '' &&
      areOrderedStops()
    );
  }

  useEffect(() => {
    console.log(startStopState);

    if (startStopState != '') {
      getStartLocationValidation(startStopState);
    }
  }, [startStopState]);

  useEffect(() => {
    console.log(endStopState);
    if (endStopState != '') {
      getEndLocationValidation(endStopState);
    }
  }, [endStopState]);

  const isValidForm = () => {
    if (
      datetimeState?.departureTime != null &&
      datetimeState?.arrivalTime != null
    ) {
      let valid = areValidTimes() && !isPastDate(datetimeState?.departureTime);
      changeValid(valid);

      if (!areValidTimes())
        setValidTime({ message: 'booking.dateValidation', valid: false });
      else if (isPastDate(datetimeState?.departureTime))
        setValidTime({ message: 'booking.pastValidation', valid: false });
      else setValidTime({ message: '', valid: true });
    } else if (isPastDate(datetimeState?.departureTime)) {
      changeValid(false);
      setValidTime({ message: 'booking.pastValidation', valid: false });
    } else {
      changeValid(true);
      setValidTime({ message: '', valid: true });
    }
  };

  useEffect(() => {
    isValidForm();
  }, [
    startCityState,
    endCityState,
    booking?.direction,
    datetimeState,
    booking?.isEveningDeparture,
  ]);

  useEffect(() => {
    setStartStopState('');
    setStartCityState('');
    setStartOrderState('');
    setEndStopState('');
    setEndCityState('');
    setEndOrderState('');
  }, [allRouteStops]);

  const openMapDialog = () => {
    setMapDialogOpen(true);
  };

  const closeMapDialog = () => {
    setMapDialogOpen(false);
  };

  const getStartLocationValidation = (location) => {
    var validation;
    validation = validateAutocompleteField(location);
    setStartLocationValid(validation.isValid);
    setEndLocationErrors(validation.errors);
  };
  const getEndLocationValidation = (location) => {
    var validation;
    validation = validateAutocompleteField(location);
    setEndLocationValid(validation.isValid);
    setStartLocationErrors(validation.errors);
  };

  useEffect(() => {
    console.log(startLocationValid);
    console.log(endLocationValid);

    if (startLocationValid && endLocationValid) {
      onValidCityChange(true);
    } else {
      onValidCityChange(false);
    }
  }, [startLocationValid, endLocationValid]);

  const onChangeStartCity = (value) => {
    console.log(value)
    
    if (value == '') {
      setStartCityState('')
      setStartStopState(null);
    };
  };

  const onManualType = (value) => {
    if (value) setManualType(true);
  };

  const onChangeEndCity = (value) => {
    if (value == ''){ 
      setEndCityState('');
      setEndStopState(null);

    }
  };
  const shouldDisableDate = useCallback(
    (date) => {
      return restrictedDates?.includes(dayjs(date).format('YYYY-MM-DD'));
    },
    [restrictedDates]
  );

  useEffect(() => {
    let defultDate = getDefaultDate();
    let defaultTime = getDefaultTime();

    console.log(restrictedDates);
    // datetimeUpdate({ type: 'date', payload: defultDate });
    datetimeUpdate({
      type: 'set-datetime',
      payload: {
        date: defultDate,
        time: defaultTime,
      },
    });
    console.log(defultDate);
  }, [restrictedDates]);
  // useEffect(() => {
  //   console.log(predefinedTimes);
  //   let defauktTime = getDefaultTime();

  //   datetimeUpdate({ type: 'departure-time', payload: defauktTime });
  // }, [predefinedTimes]);

  const [selectedTime, setSelectedTime] = React.useState(null);

  // Helper function to check if the selected time is within the predefined set

  const [open, setOpen] = useState(false);
  const timePickerRef = React.useRef(null);

  const handleClickOutside = (event) => {
    if (
      timePickerRef.current &&
      !timePickerRef.current.contains(event.target)
    ) {
      setOpen(false); // Close the TimePicker when clicking outside
    }
  };
  const handlePredefinedTimeClcik = (time) => {
    console.log(time);
    datetimeUpdate({ type: 'departure-time', payload: time });
    setOpen(false);
  };
  React.useEffect(() => {
    if (predefinedTimes.length !== 0) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);
  return (
    <div className="direction-info">
      <div className="times-info-div">
        <div
          className="booking-filter-date-time reservation-filters-rop-down-container"
          style={{ justifyContent: 'normal !important' }}
        >
          <label className="custom-label">{t('booking.departureDate')}</label>
          {datetimeState?.departureDate && (
            <DatePicker
              defaultValue={datetimeState?.departureDate}
              value={datetimeState?.departureDate}
              onChange={(newValue) => {
                datetimeUpdate({ type: 'date', payload: dayjs(newValue) });
                console.log(newValue);
              }}
              slots={{
                openPickerIcon: CalendarIcon,
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: dateError,
                  helperText: dateError ? t(dateValidations) : '',
                },
              }}
              shouldDisableDate={shouldDisableDate}
              disablePast
              format="DD.MM.YYYY"
              disabled={pastReservation}
            ></DatePicker>
          )}
        </div>
        <div className="night-div">
          {/* <center> */}
          <label className="custom-label">{t('booking.nightStart')}</label>
          <Checkbox
            color="error"
            onClick={handleEveningDepartureChange}
            checked={eveningDepartureState}
            disabled={pastReservation}
            style={checkboxStyle}
          ></Checkbox>
          {/* </center> */}
        </div>
      </div>
      <div className="times-info-div">
        <div
          className="booking-filter-date-time reservation-filters-rop-down-container"
          style={{
            justifyContent: 'normal !important',
            position: 'relative',
            marginBottom: '14px',
          }}
        >
          <label className="custom-label">{t('booking.departureTime')}</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ position: 'relative' }} ref={timePickerRef}>
                <TimePicker
                  /*defaultValue={
                 openForEdit
                   ? datetimeState.departureTime // dayjs(new Date(booking?.arrival))
                   : getNextFullHour() //dayjs() // You can use dayjs() to set the current time or any other default value
                }*/
                  ampm={false}
                  fullWidth
                  value={datetimeState.departureTime}
                  onChange={(newValue) => {
                    datetimeUpdate({
                      type: 'departure-time',
                      payload: newValue,
                    });
                  }}
                  open={predefinedTimes.length === 0 && open}
                  // shouldDisableTime={shouldDisableTime}
                  renderInput={(params) => <TextField {...params} />}
                  disabled={pastReservation}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                />
                {open && predefinedTimes.length !== 0 && (
                  <PredefinedTimesMenu
                    predefinedTimes={predefinedTimes}
                    value={datetimeState.departureTime}
                    onClick={handlePredefinedTimeClcik}
                  />
                )}
              </div>
              <IconButton
                disabled={pastReservation}
                onClick={() => {
                  datetimeUpdate({
                    type: 'clear-departure-time',
                  });
                }}
                style={{ marginLeft: 8 }}
              >
                <ClearIcon />
              </IconButton>
            </div>

            <small
              style={{ color: '#d32f2f' }}
              className="direcation-component-small-container"
            >
              {!validTime.valid
                ? t(validTime.message)
                : !timeValidations.valid &&
                    isReturn &&
                    timeValidations.position === 'departureTimeReturn'
                  ? t(timeValidations.message)
                  : ''}
            </small>
          </LocalizationProvider>
        </div>
        <div
          className="booking-filter-date-time reservation-filters-rop-down-container"
          style={{ justifyContent: 'normal !important', marginBottom: '14px' }}
        >
          <label className="custom-label"> {t('booking.arrivalTime')}</label>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TimePicker
                /*defaultValue={
                  openForEdit
                    ? datetimeState.arrivalTime // dayjs(new Date(booking?.arrival))
                    : getTwoNextFullHour()//null//dayjs() // You can use dayjs() to set the current time or any other default value
                }*/
                ampm={false}
                fullWidth
                value={datetimeState.arrivalTime}
                disabled={pastReservation}
                onChange={(newValue) => {
                  datetimeUpdate({
                    type: 'arrival-time',
                    payload: newValue,
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />

              <IconButton
                disabled={pastReservation}
                onClick={() => {
                  datetimeUpdate({
                    type: 'clear-arrival-time',
                  });
                }}
                style={{ marginLeft: 8 }}
              >
                <ClearIcon /> {/* or use any other icon for the 'x' */}
              </IconButton>
            </div>
            <small
              style={{ color: '#d32f2f' }}
              className="direcation-component-small-container"
            >
              {!timeValidations.valid &&
              isReturn &&
              timeValidations.position === 'arrivalTimeReturn'
                ? t(timeValidations.message)
                : ''}
            </small>
          </LocalizationProvider>
        </div>
      </div>
      <div className="times-info-div">
        <div className="direction-component-input-overide">
          <label className="custom-label">{t('booking.paymentMethod')}</label>
          <select
            id="payment-method-select"
            value={paymentMethodState}
            onChange={handlePaymentMethodChange}
            style={{ ...newTextFieldStyle }}
            disabled={pastReservation}
          >
            {paymentMethods.map((pm) => (
              <option className="select-option-style" key={pm.id} value={pm.id}>
                {pm.title}
              </option>
            ))}
          </select>
        </div>
        <div className="direction-component-input-overide">
          <CustomTextField
            type="text"
            name="paymentNote"
            value={noteState}
            disabled={pastReservation}
            label={t('main.note')}
            sx={newTextFieldStyle}
            placeholder={t('main.note')}
            onChange={handleNoteChange}
          />
        </div>
      </div>

      <div className="address-info-div">
        <div className="direction-component-input-overide">
          <div>
            <InputAutoComplete
              hasIcon="true"
              placeholder={t('booking.departureAddress')}
              onLocationChange={handleStartStopChange}
              onValidLocatinChange={setValidAddress}
              reesetChange={setValidAddress}
              label={true}
              locationForUpdate={startStopState || undefined}
              labelText={t('booking.departureAddress')}
              disabled={pastReservation}
              customStyle={newTextFieldStyle}
              fillAutoCompleteFromMap={startStopState || undefined}
              onChangeLocation={onChangeStartCity}
              onManualType={onManualType}
              openForEdit={openForEdit}
              isForReservationCreateOrEdit={true}
              // error={validCities}
              isCreatorNull={
                !openForEdit ? false : creator == null ? true : false
              }
            ></InputAutoComplete>
          </div>
        </div>
        <div className="direction-component-input-overide">
          <CustomTextField
            type="text"
            name="startCityState"
            value={startCityState}
            disabled={true}
            label={t('booking.departureCity')}
            sx={newTextFieldStyle}
            placeholder={t('booking.departureCity')}
          />
        </div>
        <div className="direction-component-input-overide">
          <CustomTextField
            type="text"
            name="startCityNote"
            value={startNoteState}
            disabled={pastReservation}
            label={t('main.note')}
            sx={newTextFieldStyle}
            placeholder={t('main.notesDeparture')}
            onChange={handleStartNoteChange}
          />
        </div>
      </div>
      <div className="address-info-div">
        <div className="direction-component-input-overide">
          <div>
            <InputAutoComplete
              hasIcon="true"
              placeholder={t('booking.arrivalAddress')}
              onLocationChange={handleEndStopChange}
              onValidLocatinChange={setValidAddress}
              reesetChange={setValidAddress}
              locationForUpdate={endStopState || undefined}
              labelText={t('booking.arrivalAddress')}
              label={true}
              disabled={pastReservation}
              customStyle={newTextFieldStyle}
              fillAutoCompleteFromMap={endStopState || undefined}
              onChangeLocation={onChangeEndCity}
              onManualType={onManualType}
              pastReservation={pastReservation}
              isForReservationCreateOrEdit={true}
              isCreatorNull={
                !openForEdit ? false : creator == null ? true : false
              }
              openForEdit={openForEdit}
            ></InputAutoComplete>
          </div>
        </div>
        <div className="direction-component-input-overide">
          <CustomTextField
            type="text"
            name="creatorName"
            value={endCityState}
            disabled={true}
            label={t('booking.arrivalCity')}
            sx={newTextFieldStyle}
            placeholder={t('booking.arrivalCity')}
          />
        </div>
        <div className="direction-component-input-overide">
          <CustomTextField
            type="text"
            name="endNote"
            value={endNoteState}
            disabled={pastReservation}
            label={t('main.note')}
            sx={newTextFieldStyle}
            placeholder={t('main.notesArrival')}
            onChange={handleEndNoteChange}
          />
        </div>
      </div>
    </div>
  );
}
