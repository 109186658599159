import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as Profile } from '../../../Icons/user-01.svg';
import { viewUserById } from '../../../services/UserService';
import ChangePasswordForm from '../ChangePasswordComponent/ChangePasswordForm.jsx';
import EditUserForm from '../UpdateUser/EditUserForm.js';
import './UserProfile.css';

// import {profileBg} from "../../../../public/profileBg.jpg"

export default function UserProfile(props) {
  const { userId } = useParams();
  const [user, setUser] = useState();
  const [openForm, setForm] = useState('none');
  const { t } = useTranslation();
  const id = localStorage.getItem('id');
  const CheckUserAuth = () => {
    if (id === userId) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    ViewUserById(userId);
  }, [userId]);

  async function ViewUserById(id) {
    try {
      const user = (await viewUserById(id)).data;
      console.log('ovdeee', user);
      setUser(user);
      // setOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function hendleUpdate() {
    await ViewUserById(userId);
    // hendleCloseDilaog();
  }
  console.log(userId);
  const handleOpenForm = (formName) => {
    console.log(formName);
    if (openForm === formName) {
      setForm('none');
    } else {
      setForm(formName);
    }
  };
  return (
    <>
      {user && (
        <div className="user-profile-page-container">
          <div className="user-profile-main-container">
            <div className="user-profile-top-cotainer"></div>
            <div className="user-profile-mask-cotainer">
              {/* <div style={{height:"100%",width:"100%",position:"relative"}}> */}
              <div className="user-profile-inittials-container">
                <span>
                {(user.firstName && user.lastName) ? `${user.firstName[0]}${user.lastName[0]}` : user.userName[0]}

                </span>
              </div>

              {/* </div> */}
            </div>
            <div className="user-profile-blank-container"></div>
            <div className="user-profile-content-container">
              <div className="user-profile-name-mail-container">
                <span className="user-profile-name-container">
                  {user.firstName} {user.lastName}
                </span>
                <span className="user-profile-email-container">
                  {user.userName}
                </span>
              </div>
              <div className="user-profile-role-phone-actions-container">
                <span
                  className="user-profile-span-container"
                  style={{
                    gap: '4px',
                  }}
                >
                  <Profile /> {t(`user-pfoile.${user.role}`)}
                </span>

                <span className="user-profile-span-container">
                  Tel: {user.phoneNumber}
                </span>
                {CheckUserAuth() && (
                  <span
                    className="cursor-pointer user-profile-span-container"
                    style={{
                      borderBottom:
                        openForm === 'changePassword'
                          ? '3px solid #883c40'
                          : '',
                    }}
                    onClick={() => handleOpenForm('changePassword')}
                  >
                    {t('main.changePassword')}
                  </span>
                )}
                <span
                  className="cursor-pointer user-profile-span-container"
                  style={{
                    borderBottom:
                      openForm === 'changePersonalInfo'
                        ? '3px solid #883c40'
                        : '',
                  }}
                  onClick={() => handleOpenForm('changePersonalInfo')}
                >
                  {t('main.changePersonalInfo')}
                </span>
              </div>
            </div>
            {openForm !== 'none' && (
              <div className="user-profile-forms-container">
                <div className="user-profile-form-header-container">
                  <span className="user-profie-form-header">
                    {openForm === 'changePassword'
                      ? t('main.changePassword')
                      : t('main.changePersonalInfo')}
                  </span>
                  {openForm === 'changePassword' ? (
                    <ChangePasswordForm id={userId} />
                  ) : (
                    <EditUserForm
                      id={user.id}
                      firstName={user.firstName}
                      lastName={user.lastName}
                      phoneNumber={user.phoneNumber}
                      email={user.email}
                      userName={user.userName}
                      role={user.role}
                      onUpdate={hendleUpdate}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
