import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './ConfirmationDialog.css';
import CustomDialog from './CustomDialog';
import WarningDialogContent from './WarningDialogContent';

export default function WarningDialog(props) {
  const { onClose, open, dialogContent, onNo, onYes } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };
  const handleOnYesClick = () => {
    onYes();
  };
  const handleOnNoClick = () => {
    onNo();
  };

  return (
    <>
      <CustomDialog
        onOpen={open}
        onClose={handleClose}
        customMargin= {"20px"}
      >
        <WarningDialogContent  handleCloseClick={handleOnNoClick} handleContinueClcik={handleOnYesClick} content={dialogContent} />
      </CustomDialog>
    </>
  );
}
