import { Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../Icons/editIcon.svg';
import { ReactComponent as TrashIcon } from '../../Icons/trash-01.svg';
export default function CustomLocationsTable(props) {
  const { customLocations, onCustomLocationClick, onDeleteCustomLocation } =
    props;
  // console.log(customLocations)
  const { t } = useTranslation();
  return (
    <TableContainer className="table-style" component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('route-list.title')}</TableCell>
            <TableCell>{t('route-list.City')}</TableCell>
            <TableCell>{t('route-list.Country')}</TableCell>
            <TableCell>{t('route-list.Street')}</TableCell>
            <TableCell>{t('route-list.Number')}</TableCell>
            <TableCell>{t('route-list.actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customLocations.map((customLocation) => (
            <TableRow
              key={customLocation.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{customLocation.title}</TableCell>
              <TableCell>{customLocation.location.city}</TableCell>
              <TableCell>{customLocation.location.country}</TableCell>
              <TableCell>
                {customLocation.location.street === 'Unnamed Road'
                  ? t('Location.unnamedRoad')
                  : customLocation.location.street}
              </TableCell>
              <TableCell>
              {customLocation.location.number === 'NN'
                  ? t('Location.NN')
                  : customLocation.location.number}
              </TableCell>

              <TableCell
              sx={{display:"flex",gap:"16px",boxSizing:"border-box"}}>
                <Tooltip title={t('main.edit')}>
                  <EditIcon
                  
                    onClick={() => onCustomLocationClick(customLocation)}
                  />
                </Tooltip>
                <Tooltip title={t('main.delete')}>
                  <TrashIcon
                    onClick={() => onDeleteCustomLocation(customLocation.id)}
                  ></TrashIcon>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
