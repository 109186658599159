import UsePagination from '../Pagginator/Paginator';
import HeaderButtonComponnent from '../ui/HeaderButtonContainer';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetRidesPerPageQuery } from '../../features/rides/rides';
import { resetFilters, setFilters } from '../../store/Redux/ridesFiltersSlice';
import PageLoader from '../PageLoader/PageLoader';
import { NoResultFound } from './NoResultFound';
import RidesFilters from './RidesFilters';
import RidesTable from './RidesTable';
import './style/Rides.css';

export default function Rides() {
  const [resetState, setResetState] = useState(false);
  const { filters } = useSelector((state) => state.ridesFilters);

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [
    getRidesPerPage,
    { data: rides, isLoading, isSuccess, isError, error },
  ] = useLazyGetRidesPerPageQuery();

  const handlePageChange = (page) => {
    handleChange(page, 'pageNumber');
  };
  const handleChange = (nevVlue, name) => {
    dispatch(setFilters({ [name]: nevVlue }));
  };
  const handleFilterRides = () => {
    getRidesPerPage(filters);
  };
  const handleReset = () => {
    dispatch(resetFilters());
    setResetState(!resetState);
  };
  useEffect(() => {
    handleFilterRides();
  }, [filters.pageNumber,resetState]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, [dispatch]);
  
  if(isLoading){
    return <PageLoader />
  }

  
  return (
    <>
      {!isLoading && (
        <div className="rides-main-container">
          <div className="rides-content-container">
            <HeaderButtonComponnent header="rides" />
            <RidesFilters
              onReset={handleReset}
              onFilterClcick={handleFilterRides}
            />
            <div className="show-routes-table-container">
              {rides && (
                <>
                  <RidesTable rides={rides.rides} />
                  <div style={{display:"flex",justifyContent:"center"}}>
                    
                  <NoResultFound count={rides.totalRides} />
                  </div>
                  <UsePagination
                    reset={resetState}
                    onPageChange={handlePageChange}
                    totalPages={rides.totalPages}
                  ></UsePagination>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
