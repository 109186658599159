export const mainLinks = [

  {
    key: 'Reservations',
    to: '/CreateBooking',
    title: 'appbar.booking',
    roles: ['Admin', 'Operater'],
  },
  {
    key: 'ReservationList',
    to: '/Reservations',
    title: 'appbar.bookingList',
    roles: ['Admin', 'Operater'],
  },
  {
    key: 'Rides',
    to: '/Rides',
    title: 'appbar.Rides',
    roles: ['Admin', 'Operater'],
  },
  {
    key: 'Configuration',
    to: '/RoutesPage',
    title: 'route-list.routes',
    roles: ['Admin'],
  },
  {
    key: 'UserManagement',
    to: '/Users',
    title: 'appbar.userManagement',
    roles: ['Admin'],
  },
];
