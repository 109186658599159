import React from "react";

import { useTranslation } from "react-i18next";
import { newTextFieldStyle } from "../../customStyles/customStyles";
import "./CustomTextFields.css";
const CustomDropDown = (props) => {
  const { onChange, value, name, label, sx, id, options } = props;
  const {t}=useTranslation();
  return (
    <>
      <div style={{minWidth:"150px"}}>
        <label className="custom-label">{label}</label>
        <select
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          style={{ ...newTextFieldStyle }}

        >
          {options.map((option) => (
            <option
              key={option}
              value={option}
            >
              {t(`routeStatus.${option}`)}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default CustomDropDown;
