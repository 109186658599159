import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyGetPaymentMethodsPerPageQuery } from '../../../features/paymentmethod/PaymentMethodSlice';
import { ReactComponent as EditIcon } from '../../../Icons/edit-01.svg';
import { ReactComponent as PlusIcon } from '../../../Icons/PlusIcon.svg';
import { ReactComponent as TrashIcon } from '../../../Icons/trash-01.svg';
import FilledButton from '../../Button/FilledButton';
import CustomDialog from '../../Dialog/CustomDialog';
import UsePagination from '../../Pagginator/Paginator';
import { useSnackbar } from '../../../store/context/SneckBarProvider';
import DeletePaymentMethodForm from '../PaymentMethodDeleteForm/PaymentMethodDeleteForm';
import EditPaymentMethodForm from '../PaymentMethodEditForm/PaymentMethodEditForm';
import AddPaymentMethodForm from '../PaymentMethodForm/PaymentMethodForm';
import './PaymentMethodTable.css';

export default function PaymentMethodTable() {
  const { t, i18n } = useTranslation();
  const [paymentMethodState, setPaymentMethodState] = React.useState([]);
  const [editPaymentMethodState, setEditPaymentMethodState] = React.useState(false);
  const [editNameState, setEditNameState] = React.useState('');
  const [addOpen, setAddOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [paymentMethodId, setPaymentMethodId] = React.useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState('');
  const showSnackbar = useSnackbar();
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(5);
  const [resetState, setResetPaginator] = useState(false);
  const [getPaymentMethodsPerPage] = useLazyGetPaymentMethodsPerPageQuery();

  const handleAddOpen = () => {
    setAddOpen(true);
  };
  const handleAddClose = () => {
    setAddOpen(false);
  };

  const fetchPaymentMethods = async () => {
    const params = {
      currentPage: pageNumber,
      pageSize: pageSize,
    };
    const result = await getPaymentMethodsPerPage(params);
    console.log('Res', result);
    setPaymentMethodState(result.data);
  };

  React.useEffect(() => {
    fetchPaymentMethods();
  }, [pageNumber]);

  const handleEditName = (event) => {
    setEditNameState(event.target.value);
  };

  const getPaymentMethods = () => {
    fetchPaymentMethods();
    showSnackbar(t('paymentMethod.createdSuccessfully'), 'success');
  };
  const handleEditOpen = (id, name) => {
    setPaymentMethodId(id);
    setSelectedPaymentMethod(name);
    setEditOpen(true);
  };
  const handleDeleteOpen = (id) => {
    setPaymentMethodId(id);
    setDeleteOpen(true);
  };
  const handleEditClose = (id) => {
    setEditOpen(false);
  };
  const handleDeleteClose = (id) => {
    setDeleteOpen(false);
  };

  const getPaymentMethodAfterDelete = (isSuccess) => {
    fetchPaymentMethods();
    if (isSuccess) {
      showSnackbar(t('paymentMethod.deletedSuccessfully'), 'success');      
    }else{
      showSnackbar(t('paymentMethod.unsuccessfullyDeleted'), 'error');      
    }
  };

  const getPaymentMethodAfterEdit = () => {
    fetchPaymentMethods();
    showSnackbar(t('paymentMethod.updatedSuccessfully'), 'success');
  };

  return (
    <>
      <div className="payment-methode-main-cotainer">
        {addOpen && (
          <CustomDialog
            onOpen={addOpen}
            onClose={handleAddClose}
            contentTitle={'addNewPaymentMethod'}
          >
            <AddPaymentMethodForm
              onSuccess={handleAddClose}
              getSubagents={getPaymentMethods}
            ></AddPaymentMethodForm>
          </CustomDialog>
        )}
        {deleteOpen && (
          <CustomDialog
            onOpen={deleteOpen}
            onClose={handleDeleteClose}
            contentTitle={'deletePaymentMethod'}
          >
            <DeletePaymentMethodForm
              onSuccess={handleDeleteClose}
              getPaymentMethodAfterDelete={getPaymentMethodAfterDelete}
              paymentMethodId={paymentMethodId}
            ></DeletePaymentMethodForm>
          </CustomDialog>
        )}
        {editOpen && (
          <CustomDialog
            onOpen={editOpen}
            onClose={handleEditClose}
            contentTitle={t('paymentMethod.editPaymentMethod')}
          >
            <EditPaymentMethodForm
              onSuccess={handleEditClose}
              getPaymentMethodAfterEdit={getPaymentMethodAfterEdit}
              paymentMethodId={paymentMethodId}
              selectedPaymentMethod={selectedPaymentMethod}
            ></EditPaymentMethodForm>
          </CustomDialog>
        )}
        <div className="payment-header">
          <span className="payment-title-card">
            {t('paymentMethod.paymentmethods')}
          </span>
          <FilledButton isValid={true} onClick={handleAddOpen}>
            <PlusIcon /> {t('paymentMethod.addNewPaymentMethod')}
          </FilledButton>
        </div>
        <br />
        <div className="payament-methode-table">
          <TableContainer style={{ borderRadius: '1vh' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: 'ButtonShadow' }}>
                    <strong>{t('paymentMethod.name')}</strong>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: 'ButtonShadow',
                      textAlign: 'end',
                    }}
                  >
                    <strong>{t('paymentMethod.actions')}</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="body">
                {paymentMethodState
                  ? paymentMethodState?.paymentMethods?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {editPaymentMethodState === index ? (
                            <TextField
                              variant="standard"
                              value={editNameState}
                              onChange={(event) => handleEditName(event)}
                            />
                          ) : (
                            row.title
                          )}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'end' }}>
                          <Tooltip title={t('main.edit')}>
                            <IconButton
                              onClick={() => handleEditOpen(row.id, row.title)}
                              aria-label="edit"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('main.delete')}>
                            <IconButton
                              onClick={() => handleDeleteOpen(row.id)}
                              aria-label="delete"
                            >
                              <TrashIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="pagination-bottom">
            <UsePagination
              onPageChange={setPageNumber}
              totalPages={paymentMethodState?.totalPages}
              page={pageNumber}
              reset={resetState}
            ></UsePagination>
          </div>
        </div>
      </div>
    </>
  );
}
