import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRemovePassengersFromRideMutation } from '../../features/rides/rides';
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import { useSnackbar } from '../../store/context/SneckBarProvider';

export default function PassengerRow(props) {
  const { passenger, rideId } = props;
  const { t, i18n } = useTranslation();
  const showSnacbar = useSnackbar();
  const [openComf,setOpenComf]=useState(false)
  const navigate = useNavigate();
  const [removePassengersFromRide] = useRemovePassengersFromRideMutation();

  const bookingNavigate = (reservationId) => {
    const languagePrefix = i18n.language === 'en' ? '/en' : '/rs';
    const url = `${languagePrefix}/EditBooking/${reservationId}`;
    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      navigate(url);
    }
  };

  const onRemovePassengerFromRideClick = async () => {
    try {
      const response = await removePassengersFromRide({
        reservationIds: [passenger.reservationId],
        rideIds: [rideId],
      }).unwrap();
      showSnacbar('Successfully removed passengers!', 'success');
      handleCloseComfDialog();
      //   removeKeyFromAll("type")
      //   onClose()
    } catch (error) {
      showSnacbar(error?.data?.Message, 'error');
    }
  };

  const formatAddress = (location) => {
    if (location) {

      var street = location.street !== 'Unnamed Road' ? location.street : ' ';
      var number = location.number !== 'NN' ? ' ' + location.number : ' ';
      var city =
        location.city !== 'Undefied City' ? location.city : location.country;

      var adress = city;
      if (number != ' ') {
        adress = `${number} (${city})`;
      }
      if (street != ' ') {
        adress = `${street} ${adress}`;
      }
    }
    return adress;
  };
  const handleOpenConfDialog = () => {
    setOpenComf(true)
  };
  const handleCloseComfDialog = () =>{
    setOpenComf(false);
  }
  return (
    <>
      <TableRow key={passenger.id}>
        <TableCell
          className={`passangersBorderColor-${passenger.fleg} booking-padding-${passenger.tip}`}
          onClick={() => bookingNavigate(passenger.reservationId)}
        >
          <span className="passengers-table-booking-id">
            #{passenger.reservationId}
          </span>
        </TableCell>
        <TableCell>{passenger.name}</TableCell>
        <TableCell>{passenger.phoneNumber}</TableCell>
        <TableCell align="right">
          {formatAddress(passenger.departureLocation)}
        </TableCell>
        <TableCell align="right">
          {formatAddress(passenger.arrivalLocation)}
        </TableCell>
        <TableCell align="right" className="pointer">
          {passenger.tip === 'main' && (
            <Tooltip title={t('rides.removePassengers')}>
              <GroupRemoveOutlinedIcon onClick={handleOpenConfDialog} />
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      {openComf && (
        <ConfirmationDialog
          open={openComf}
          onClose={handleCloseComfDialog}
          onNo={handleCloseComfDialog}
          onYes={onRemovePassengerFromRideClick}
          dialogContent={'removePassengers'}
        />
      )}
    </>
  );
}
