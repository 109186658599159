import {
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { roles } from '../../../config';
import { textFieldStyles } from '../../../customStyles/customStyles';
import { useInviteUserMutation } from '../../../features/auth/authApiSlice';
import CustomButton from '../../Button/Button';
import CustomDropDown from '../../CustomTextFields/CustomDropDown';
import ConfirmationDialog from '../../Dialog/ConfirmationDialog';
import PageLoader from '../../PageLoader/PageLoader';
import './RegisterForm.css';
import { useSnackbar } from '../../../store/context/SneckBarProvider';

const SendRegistrationInvitationForm = (props) => {
  const { t } = useTranslation();
  const { onClose } = props;
  const [open, setOpen] = useState(false);
  const [inviteUser, { isLoading }] = useInviteUserMutation();

  console.log('dasdasdasdasds');
  const showSnackbar = useSnackbar();

  const [formData, setFormData] = useState({
    email: '',
    role: roles[0],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(formData);
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleInviteUser = async () => {
    try {
      console.log(isLoading);

      const response = await inviteUser(formData).unwrap();
      showSnackbar(response?.message, 'success');
      await delay(300);
      onClose();
    } catch (err) {
      showSnackbar(err?.data?.Message, 'error');
    }
  };

  const handleOpenDialg = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleYesClick = () => {
    handleInviteUser();
    setOpen(false);
  };
  console.log(isLoading);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <div>
        <label className="custom-label">Email</label>
        <TextField
          sx={textFieldStyles}
          required
          fullWidth
          name="email"
          placeholder="Email"
          type="email"
          id="email"
          value={formData.email}
          onChange={handleChange}
        />
        {/* <CustomTextField sx>

      </CustomTextField> */}
      </div>
      <div>
        <CustomDropDown
          name="role"
          id="role"
          value={formData.role}
          onChange={handleChange}
          label={t('users.filterRole')}
          options={roles}
        />
      </div>
      {/* <RoleFormControl value={formData.role} onChange={handleChange} /> */}
      <CustomButton
        onClick={handleOpenDialg}
        //   varient="filled"
        type="add"
      >
        {t(`users.invite`)}
      </CustomButton>
      {open && (
        <ConfirmationDialog
          open={open}
          onClose={handleCloseDialog}
          dialogContent={'Are you sure you want to invite new user?'}
          onNo={handleCloseDialog}
          onYes={handleYesClick}
        />
      )}
    </div>
  );
};

export default SendRegistrationInvitationForm;
