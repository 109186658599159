import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ThankYouContent from '../components/ThankYou/ThankYouContent';
import { useGenerateReservationQRCodePDFMutation } from "../features/export/exportApiSlice";
import { useConfirmReservationMutation } from '../features/sms/smsSlice';
import { handleCreateDownloadLink } from "../utils/download-blob/handleCreateDownloadLink";

const ThankYouPage = () => {
  const { paramName } = useParams();
  const [confirmReservation] = useConfirmReservationMutation();
  const [downloadPdfFile] = useGenerateReservationQRCodePDFMutation();
  const [response, setResponse] = useState();



  const handleConfirmReservation = useCallback(async () => {
    if (paramName) {
      try {
        const response = await confirmReservation(paramName).unwrap();
        setResponse(response);
        const { data: pdfBlob } = await downloadPdfFile({token: paramName});
        handleCreateDownloadLink(pdfBlob, 'pdf', 'Potvrda rezervacije');
        console.log('Reservation confirmed successfully', response);
      } catch (error) {
        console.error('Failed to confirm reservation', error);
        setResponse(error)
      }
    }
  }, [paramName, confirmReservation]);

  useEffect(() => {
    handleConfirmReservation();
  }, [handleConfirmReservation]);

  console.log(paramName);
  return (
    <>
      {response && (
        <div>
          <ThankYouContent response={response} />
        </div>
      )}
    </>
  );
};

export default ThankYouPage;
