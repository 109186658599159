import DirectionsBusFilledOutlinedIcon from '@mui/icons-material/DirectionsBusFilledOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import moment from 'moment/moment';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangeVehicleDialog from './ChangeVehicleDialog';
import ManageDriversDialog from './ManageDriversDialog';
import { PassengersTable } from './PassengersTable';

export function RideRow(props) {
  const { ride } = props;
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState(null);

  const { t } = useTranslation();
  const handleClose = () => {
    setDialogType(null);
    setOpenDialog(false);
  };

  const handleOpenDriverManagerDialog = () => {
    setDialogType('drivers');
    setOpenDialog(true);
  };
  const handleOpenChangeVehicleDialog=()=>{
    setDialogType('vehicles');
    setOpenDialog(true)
  }

  const showDrivers = () => {
    var driversString = '';
    if (ride.drivers.length > 0) {
      ride.drivers.forEach((driver) => {
        var driverName = driver.name == ' ' ? driver.userName : driver.name;
        driversString =
          driversString !== ''
            ? driversString + ' / ' + driverName
            : driversString + driverName;
      });
    }
    return driversString;
  };
  const checkPassengersLength = () => {
    return ride.passengers.length > 0 ? true : false;
  };

  return (
    <>
      <Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            {checkPassengersLength() && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
          <TableCell>{ride.id}</TableCell>
          <TableCell>{ride.vehicle.registrationPlates}</TableCell>
          {/* <TableCell>{ride.direction}</TableCell> */}
          <TableCell>{ride.directinString}</TableCell>

          <TableCell>{showDrivers()}</TableCell>
          <TableCell>{moment(ride.departure).format('DD/MM/YYYY')}</TableCell>
          <TableCell>
            {moment(ride.departureTime, 'HH:mm:ss').format('HH:mm')}
          </TableCell>
          <TableCell className="pointer">
            <Tooltip title={t('rides.menageDrivers')}>
              <ManageAccountsOutlinedIcon
                onClick={() => handleOpenDriverManagerDialog()}
              />
            </Tooltip>
            <Tooltip title={t('rides.changeVehicle')}>
              <DirectionsBusFilledOutlinedIcon
                onClick={() => handleOpenChangeVehicleDialog()}
              />
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={8}>
            {checkPassengersLength() && (
              <Collapse in={open} timeout="auto" unmountOnExit>
                {/* <p style={{textAlign:"left",padding:"10px",margin:"0px"}}>Passengers</p> */}

                <PassengersTable
                  passengers={ride.passengers}
                  rideId={ride.id}
                />
              </Collapse>
            )}
          </TableCell>
        </TableRow>
      </Fragment>
      {openDialog && (
        <>
          {dialogType === 'drivers' && (
            <ManageDriversDialog
              drivers={ride.drivers}
              rideId={ride.id}
              departure={ride.departure}
              open={openDialog}
              onClose={handleClose}
            />
          )}
          {dialogType === 'vehicles' && (
            <ChangeVehicleDialog
              rideId={ride.id}
              rideDirection={ride.direction}
              routeId={ride.routeId}
              departure={ride.departure}
              open={openDialog}
              onClose={handleClose}
            />
          )}
        </>
      )}
    </>
  );
}
