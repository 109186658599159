import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useGetPaymentMethodsQuery } from '../../features/paymentmethod/PaymentMethodSlice';
import { useGetChangesHistoryQuery } from '../../features/reservation/reservationSlice';
import './PassangersDetails.css';

const PassengersDetails = () => {
  const { reservationId } = useParams();
  const { data: paymentMethods, errorPayment } = useGetPaymentMethodsQuery();
  const [editReservation, setEditReservation] = useState(null);
  const {
    data: histories,
    isLoading,
    error,
  } = useGetChangesHistoryQuery(reservationId);
  const { t, i18n } = useTranslation();

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const getTranslation = (key, defaultValue) => {
    if (
      typeof defaultValue === 'string' &&
      defaultValue.includes('neodređeno')
    ) {
      defaultValue = defaultValue.replaceAll('neodređeno', 'undefined');
      //defaultValue = defaultValue.replace(/neodređeno/g, 'undefined');
    }

    if (
      defaultValue?.includes('Route') &&
      defaultValue?.includes('Time') &&
      defaultValue?.includes('Return Ticket')
    ) {
      let translatedWord = t('history.originalValue.RouteTimeTicket');
      translatedWord = translatedWord.split(',');
      defaultValue = defaultValue.replace('Route', translatedWord[0]);
      defaultValue = defaultValue.replace('Time', translatedWord[1]);
      defaultValue = defaultValue.replace('Return Ticket', translatedWord[2]);
      defaultValue = defaultValue.replace('False', translatedWord[3]);
      defaultValue = defaultValue.replace('True', translatedWord[4]);
      defaultValue = defaultValue.replace('Note', translatedWord[5]);
      defaultValue = defaultValue.replace('undefined', translatedWord[6]);
      defaultValue = defaultValue.replace('undefined', translatedWord[6]);

      return defaultValue;
    }
    if (
      defaultValue?.includes('Name') ||
      defaultValue?.includes('Phone') ||
      defaultValue?.includes('Note')
    ) {
      let translatedWord = t('history.originalValue.NamePhoneNote');
      translatedWord = translatedWord.split(',');
      defaultValue = defaultValue.replace('Name', translatedWord[0]);
      defaultValue = defaultValue.replace('Phone', translatedWord[1]);
      defaultValue = defaultValue.replace('Note', translatedWord[2]);
      return defaultValue;
    }
    if (
      defaultValue?.includes('Unnamed Road') ||
      defaultValue?.includes('NN')
    ) {
      let translatedWord = t('history.originalValue.UnnamedRoadNN');
      translatedWord = translatedWord.split(',');
      defaultValue = defaultValue.replace('Unnamed Road', translatedWord[0]);
      defaultValue = defaultValue.replace('NN', translatedWord[1]);
      return defaultValue;
    }
    if (
      defaultValue?.includes('Paid') ||
      defaultValue?.includes('SMSConfirmed') ||
      defaultValue?.includes('Cancelled') ||
      defaultValue?.includes('Pending')
    ) {
      let translatedWord = t('history.originalValue.Confirmed');
      translatedWord = translatedWord.split(',');
      defaultValue = defaultValue.replace('Paid', translatedWord[0]);
      defaultValue = defaultValue.replace('SMSConfirmed', translatedWord[1]);
      defaultValue = defaultValue.replace('Cancelled', translatedWord[2]);
      defaultValue = defaultValue.replace('Pending', translatedWord[3]);
      return defaultValue;
    }
    if (i18n.exists(key)) {
      return t(key);
    }
    return defaultValue;
  };

  return (
    <>
      <h3 className="card-title">{t('passenger_details.bookingHistory')}</h3>
      <Card className="card-table">
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: 'ButtonShadow' }}>
              <TableRow>
                <TableCell>{t('passenger_details.changeTime')}</TableCell>
                <TableCell>{t('passenger_details.changedBy')}</TableCell>
                <TableCell>{t('passenger_details.actions')}</TableCell>
                <TableCell>{t('passenger_details.fieldChanged')}</TableCell>
                <TableCell>{t('passenger_details.originalValue')}</TableCell>
                <TableCell>{t('passenger_details.newValue')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {histories.map((change, index) => (
                <TableRow>
                  <TableCell>
                    {moment(change?.whenChangeMade).format('DD/MM/yyyy HH:mm')}
                  </TableCell>
                  <TableCell>
                    {change?.user?.firstName && change?.user?.lastName
                      ? `${change?.user?.firstName} ${change?.user?.lastName}`
                      : change?.user?.userName}
                  </TableCell>
                  <TableCell>{t(`history.action.${change?.action}`)}</TableCell>
                  <TableCell>
                    {t(`history.fieldChanged.${change?.fieldChanged}`)}
                  </TableCell>
                  <TableCell>
                    {getTranslation(
                      `history.originalValue.${change?.originalValue}`,
                      change?.originalValue
                    )}
                  </TableCell>
                  <TableCell>
                    {getTranslation(
                      `history.originalValue.${change?.newValue}`,
                      change?.newValue
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default PassengersDetails;
