import React, { useEffect, useState } from 'react';
import { invalidTextFieldStyle, textFieldStyles, validTextField } from '../../customStyles/customStyles';

import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useValidation } from '../../store/context/ValidationProvider';
import ValidationErrors from '../Error/ValidationErrors';
import '../Route/AddRouteComponent/AddRouteByAutocomplete.css';

const EmailTextField = (props) => {
  const { onEmailChange, email, name, label, onValidityChange } = props;
  const { validateEmailField } = useValidation();

  // const [password,setPassword]=useState("");
  const [emailValid, setEmailValid] = useState(null);
  const [mailErrors, setMailErrors] = useState(null);
  const { t } = useTranslation();

  //   let translatedLabel;
  //   if (label === "First Name") {
  //     translatedLabel = t("main.firstName");
  //   } else if (label === "Last Name") {
  //     translatedLabel = t("main.lastName");
  //   } else {
  //     translatedLabel = label;
  //   }

  useEffect(() => {
    if (email !== '' && email !== undefined) {
      getNameValidation(email);
      console.log('ovamo beeee');
    }
  }, []);

  const getNameValidation = (email) => {
    var validation;
    validation = validateEmailField(email);
    setEmailValid(validation.isValid);
    setMailErrors(validation.errors);
    onValidityChange(name, validation.isValid);

    console.log(validation);
  };
  const handleNameChange = (e) => {
    if (e.target.value !== '') {
      getNameValidation(e.target.value);
    } else {
      resetField();
    }
    onEmailChange(e);
  };
  const resetField = () => {
    setMailErrors(null);
    setEmailValid(null);
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <label className='custom-label'>{label}</label>
        <TextField
          sx={{
            ...textFieldStyles, // Apply default styles by default
            ...(emailValid && validTextField), // Apply valid styles if valid state
            ...(emailValid === false && invalidTextFieldStyle),
      
          }}
          required
          fullWidth
          name={name}
          placeholder={label}
          type="text"
          id={name}
          value={email}
          onChange={handleNameChange}
        />
        <div style={{ position: 'absolute', lineHeight: '0.5rem' }}>
          <ValidationErrors errors={mailErrors} />
        </div>
      </div>
    </>
  );
};

export default EmailTextField;
