import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useResendMessagesMutation } from '../../features/sms/smsSlice';
import { useSignalRConection } from '../../store/context/SignalRContext';
import FilledButton from '../Button/FilledButton';
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import { useSnackbar } from '../../store/context/SneckBarProvider';
import './SMS.css';
import SMSStatus from './SmsStauts';

const SMSStatuses = ({ responses, onCloseDialog }) => {
  const { isButtonEnabled } = useSignalRConection();
  const [notSent, setNotSent] = useState([]);
  const [resesndSms] = useResendMessagesMutation();
  const showSnacbar = useSnackbar();
  const [openComfDialog, setOpenComfDialog] = useState();

  const { t } = useTranslation();

  const handleClick = () => {
    console.log('CloseIcon clicked');
    onCloseDialog();
  };

  const handleResendSMSs = async () => {
    setOpenComfDialog(false);
    onCloseDialog();
    try {
      if (notSent.length > 0) {
        const response = await resesndSms(notSent);
        // showSnacbar("SMS resending started.","info");
        showSnacbar(t('errors.SMS resending started.'),"info");
        if (!response.error) {
        } else {
          showSnacbar(response?.error.data?.Message, 'error');
        }
      } else {
        showSnacbar('Error', 'error');
      }
    } catch (error) {
      showSnacbar(error?.data?.Message, 'error');
    }
  };

  const getNotSentSms = () => {
    const filteredResponses = responses.filter(
      (response) =>
        response.status !== 'Sent' && response.status !== 'delivered'
    );
    console.log(filteredResponses);
    setNotSent(filteredResponses);
  };

  useEffect(() => {
    getNotSentSms();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responses]);

  return (
    <>
      <div className="sms-statuses-top-container">
        <span className="sms-status-top-header">{t('sms.statuses')}</span>
        <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClick} />
      </div>

      <div className="sms-statuses-main-container">
        {responses && responses.length > 0 ? (
          responses
            .slice() // Create a copy of the array to avoid mutating the original array
            .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
            .map((smsData, index) => (
              <SMSStatus key={index} smsData={smsData} />
            ))
        ) : (
          <div className="statuses-sms-message-container">
            <p>{t('sms.noSmsAvailable')}</p>
          </div>
        )}
      </div>
      <div className="statuses-sms-resend-container">
        {notSent && (
          <>
            <FilledButton
              onClick={()=>setOpenComfDialog(true)}
              isValid={isButtonEnabled && notSent.length > 0}
            >
              {t('sms.Resend')}
            </FilledButton>
            {openComfDialog && (
              <ConfirmationDialog
                open={openComfDialog}
                onClose={() => setOpenComfDialog(false)}
                dialogContent={t('sendSMSs')}
                onNo={() => setOpenComfDialog(false)}
                onYes={handleResendSMSs}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SMSStatuses;
