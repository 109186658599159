import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const PageLoader = () => {
  const { t } = useTranslation();

    return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          flexDirection="column"
          position="fixed"
          width="100%"
          top="0"
          left="0"
          backgroundColor="white"
        >
          <CircularProgress />
          <Typography variant="h6" marginTop={2}>
            {t('main.loading')}...
          </Typography>
        </Box>
      );
};

export default PageLoader;
