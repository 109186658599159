import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import LinearProgress from './CustomLinearProgress';

//TODO SHOULD CHANGE FILE NAME
const SMSRecord = ({ record, onClick, onRemoveClick }) => {
  const { t } = useTranslation();
  const [failedCount, setFailedCount] = useState(0);
  const [proccesed, setUnproccesed] = useState(0);

  const date = new Date(record.sesionSterted);

  const dateFormatter = new Intl.DateTimeFormat('sr-RS', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  // Format the time
  const timeFormatter = new Intl.DateTimeFormat('sr-RS', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
  });
  //   smsData.status=="Sent" || smsData.status== "Delivered"
  const formattedDate = dateFormatter.format(date);
  const formattedTime = timeFormatter.format(date);

  useEffect(() => {
    if (record) {
      const counts = record.smSs.reduce(
        (acc, response) => {
          if (response.status !== 'Unprocessed sms') {
            acc.proccesed++;
            if (response.status !== 'Sent') {
              acc.failures++;
            }
          }
          return acc;
        },
        { failures: 0, proccesed: 0 }
      );

      const { failures, proccesed } = counts;

      setUnproccesed(proccesed);
      setFailedCount(failures);
    }
  }, [record]);

  return (
    <>
      <div className="sms-record-main-container">
        <div className="sms-record-title-exit">
          <span className="sms-record-title">{record.smSs[0]?.reservationId ? t('sms.SMS for reservation confirmation') : t('sms.SMS for OTP code')}</span>
          <CloseIcon onClick={onRemoveClick} />
        </div>
        <div className="sms-record-conntent-container" onClick={onClick}>
          <div className="date-time-sms-record-container sms-record-date-time">
            <span>{formattedDate}</span>
            <span>{formattedTime}</span>
          </div>
          <span className="date-time-sms-record-container">
            {proccesed === 0 ? (
              <span
                style={{ color: '#bd7900' }}
                className="sms-record-number-of-sent-messages failed-sms-record-status"
              >
                 {record.numberOfSMSs-proccesed} {t('sms.unporccesed')}
              </span>
            ) : failedCount === 0 ? (
              <span
                style={{ color: 'green' }}
                className="sms-record-number-of-sent-messages failed-sms-record-status"
              >
                {t('sms.success')}
              </span>
            ) : (
              <div className="date-time-sms-record-container failed-sms-record-status">
                <span>
                  {failedCount} {t('sms.neuspesne')}
                </span>
              </div>
            )}
            <span className="record-done-sms-sending">
              {proccesed}/{record.numberOfSMSs}
            </span>
          </span>
        </div>
        <LinearProgress
          progressValue={(proccesed / record.numberOfSMSs) * 100}
        />
      </div>
    </>
  );
};

export default SMSRecord;
