import { useEffect, useState } from "react";
// import { getDirections } from "../../../services/DirectionService";
// import { sortRouteStops } from "../../../utils/googleMapsHelper/sortRouteStops";
// import { getRouteLength } from "../../../utils/googleMapsHelper/getRouteLength";
import "./AddCustomLocation.css";
// import Button from "@mui/material";
import { useAddCustomLocationMutation } from "../../features/customlocations/customLocationSlice";
import { useSnackbar } from "../../store/context/SneckBarProvider";
import { CustomLocationsEditor } from "./CustomLocationEditor";

export const AddCustomLocation = (props) => {
  const {

    onUpdateCustomLocationTable,
    onClose
  } = props;

  const showSnackbar = useSnackbar();
  const [addCustomLocation] = useAddCustomLocationMutation();
  const [customLocation, setCustomLocation] = useState({
    locationName: "",
    location: {
      lat: "",
      long: "",
      country: "",
      city: "",
      street: "",
      number: "",
    },
  });

  const handleLocationChange = (newLocation) => {
    
    setCustomLocation((prevState) => ({
      ...prevState,
      location: {
        ...prevState.location,
        ...newLocation,
      },
    }));
  };
  const handleLocationNameChange = (event) => {
    const newName = event.target.value;
    setCustomLocation((prevState) => ({
      ...prevState,
      locationName: newName,
    }));
  };

  const handleReset = () => {
    setCustomLocation({
      locationName: "",
      location: {
        lat: "",
        long: "",
        country: "",
        city: "",
        street: "",
        number: "",
      },
    });
  };

  useEffect(() => {
    console.log(customLocation);
  }, [customLocation]);

  const handleAddCustomLocation = async () => {
    
    try {
      if (customLocation.locationName != "") {
        const response = await addCustomLocation(customLocation).unwrap();

        showSnackbar(response?.message, "success");
        onUpdateCustomLocationTable();
      } else {
        showSnackbar("You must choose the name!", "error");
      }
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };


  const handleAdd=()=>{
    console.log("rewrwerwerew")
  }
  // const handleClose = () => {
  //   setOpen(false);
  // };
  return (
    <CustomLocationsEditor
      onResetClcik={handleReset}
      // editMarkerPosition={markerPosition}
      onLocationChange={handleLocationChange}
      onNameChange={handleLocationNameChange}
      customLocation={customLocation}
      onUpdateCustomLocationTable={onUpdateCustomLocationTable}
      onSubmitClick={handleAddCustomLocation}
      onClose={onClose}

    />
  );
};
