import React from 'react';
import './Error404.css';
import { useTranslation } from 'react-i18next';

const Error404Component = () => {
  const { t } = useTranslation();
  return (
    <div className="eror404-main-container">
      <div className="eror404-content-container">
        <div className="eror404-photo-container"></div>
        <div></div>
        <div className="error404-header-button-conteiner">
          <h2>{t('error404.errorMessage')}</h2>
          {/* <CustomButton>Vrati se</CustomButton> */}
        </div>
      </div>
    </div>
  );
};

export default Error404Component;
