import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteCustomLocationMutation, useLazyGetCustomLocationsPerPageQuery } from "../../features/customlocations/customLocationSlice";
import { ReactComponent as PlusIcon } from "../../Icons/PlusIcon.svg";
import FilledButton from "../Button/FilledButton";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import UsePagination from "../Pagginator/Paginator";
import { useSnackbar } from "../../store/context/SneckBarProvider";
import AddCustomLocationDialog from "./AddCustomLocationDialog";
import "./CustomLocations.css";
import CustomLocationsTable from "./CustomLocationsTable";
import UpdateCustomLocationDialog from "./UpdateCustomLocationDialog";
export const CustomLocations = () => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [customLocationForUpdate, setCustomLocationForUpdate] = useState(null);
  const [dialog, setDialog] = useState(null);
  const [resetState, setResetPaginator] = useState(false);
  const [id,setDeleteId]=useState(null)
  const [deleteCustomLocation] = useDeleteCustomLocationMutation();
  const [pagingData, setPagingData] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const [
    getCustomLocationsPerPage,
    { data: customLocationsInfo, isLoading, refetch },
  ] = useLazyGetCustomLocationsPerPageQuery(pagingData);

  useEffect(() => {
    getCustomLocationsPerPage(pagingData);
    console.log("Sfadasdf");
  }, []);

  const handlePageChange = (value) => {
    console.log(value);
    getCustomLocationsPerPage({
      pageSize: pagingData.pageSize,
      pageNumber: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleOpenAddDialog = () => {
    setDialog("add");
    handleOpenDialog();
  };
  const onAddCustomLocation = () => {
    console.log("qrewrwqerqwerqwerqwerqw");
    getCustomLocationsPerPage({ pageSize: pagingData.pageSize, pageNumber: 1 });
    setResetPaginator(!resetState);
    setOpen(false);
  };

  const onUpdateCustomLocation = () => {
    console.log("qrewrwqerqwerqwerqwerqw");
    getCustomLocationsPerPage({
      pageSize: pagingData.pageSize,
      pageNumber: customLocationsInfo.pageNumber,
    });
    setOpen(false);
  };
  const handleCustomLocationClcik = (customLocation) => {
    console.log(customLocation);
    setCustomLocationForUpdate(customLocation);
    setDialog("update");
    handleOpenDialog();
  };

  const handleDeleteCustomLocation = async () => {
    try {
      const response = await deleteCustomLocation(id).unwrap();
      setDeleteId(null)
      onUpdateCustomLocation();
      showSnackbar(response?.message, "success");
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };

  const handleOpenDeleteDialog=(id)=>{
    handleOpenDialog();
    setDialog("delete");
    setDeleteId(id);
  }


  return (
    <div className="custom-locations-main-container">
      <div className="custom-locations-content-container">
        <div className="custom-location-button-header-container">
          <span className="page-header-container">
            {t("custom-location.customLocations")}
          </span>
          <FilledButton isValid={true} onClick={handleOpenAddDialog}>
            <PlusIcon />
            {t("custom-location.newCustomLocation")}
          </FilledButton>
        </div>

        <div className="custom-location-table-cotainer">
          {!isLoading && customLocationsInfo ? (
            <>
              <CustomLocationsTable
                onCustomLocationClick={handleCustomLocationClcik}
                customLocations={customLocationsInfo.customLocations}
                onDeleteCustomLocation={handleOpenDeleteDialog}
              />
              <UsePagination
                onPageChange={handlePageChange}
                totalPages={customLocationsInfo.totalPages}
                page={pagingData.pageNumber}
                reset={resetState}
              />
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        {open && dialog == "add" && (
          <AddCustomLocationDialog
            locationForUpdate={customLocationForUpdate}
            onUpdateCustomLocationTable={onAddCustomLocation}
            // location={customLocationsInfo.customLocations[0].location}
            open={open}
            onClose={handleClose}
          />
        )}
        {open && customLocationForUpdate != null && dialog === "update" && (
          <UpdateCustomLocationDialog
            locationForUpdate={customLocationForUpdate}
            onUpdateCustomLocationTable={onUpdateCustomLocation}
            location={customLocationsInfo.customLocations[0].location}
            open={open}
            onClose={handleClose}
          />
        )}
        {open && dialog==="delete" &&
        <ConfirmationDialog open={open} onClose={()=>setOpen(false)}
        dialogContent={
           "deleteCustomLoc"
        }
        onNo={()=>setOpen(false)}
        onYes={handleDeleteCustomLocation}
         />
        
        }
      </div>
    </div>
  );
};
